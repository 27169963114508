import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PostalCountryInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

export default function PostalCountryInput({ value, onChange, disabled }: PostalCountryInputProps) {
  const { t } = useTranslation();

  return (
    <TextField
      id='postal-country-input'
      label={t('formInputs.postalCountryInputLabel')}
      variant='outlined'
      fullWidth
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}
