// import React from 'react';
// import TextField from '@mui/material/TextField';
// import { useTranslation } from 'react-i18next';

// export default function PhoneInput() {
//   const { t } = useTranslation();

//   return (
//     <TextField
//       id='phone-input'
//       label={t('formInputs.phoneInputLabel')}
//       variant='outlined'
//       fullWidth
//     />
//   );
// }

import React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

interface PhoneInputProps {
  value?: string | null;
  onChange: (value: string) => void;
  disabled: boolean;
}

export default function PhoneInput({ value, onChange, disabled }: PhoneInputProps) {
  const { t } = useTranslation();

  return (
    <TextField
      id='phone-input'
      label={t('formInputs.phoneInputLabel')}
      variant='outlined'
      fullWidth
      type='tel'
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}
