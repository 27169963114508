import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import {
  AIDocumentContractModel,
  AIDocumentContractData,
  AIDocumentContractDeleteData,
  AIDocumentContractParams,
  AIDocumentContractModelWithId,
} from '../../models/ai.document.contract.model';
import { RootState } from '../../store';

export const fetchDocumentContracts = createAsyncThunk(
  'documentContracts/fetchDocumentContracts',
  async ({ data }: AIDocumentContractParams) => {
    const response = await NWClient.list('document-contract', data);
    return response;
  }
);

export const addDocumentContract = createAsyncThunk(
  'documentContracts/addDocumentContract',
  async ({ data }: AIDocumentContractData) => {
    const response = await NWClient.post('document-contract', data, true);
    return response;
  }
);

export const deleteDocumentContract = createAsyncThunk(
  'documentContracts/deleteDocumentContract',
  async ({ id, userId }: AIDocumentContractDeleteData) => {
    const response = await NWClient.delete('document-contract', id, null, { user: userId });
    return response;
  }
);

export interface DocumentContractsState {
  documentContractsList: AIDocumentContractModelWithId[];
  status: string;
  error: string;
}

const documentContractsArray: AIDocumentContractModelWithId[] = [];
const initialState: DocumentContractsState = {
  documentContractsList: documentContractsArray,
  status: 'idle',
  error: '',
};
const documentContractsSlice = createSlice({
  name: 'documentContracts',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDocumentContracts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDocumentContracts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (Array.isArray(action.payload)) {
          state.documentContractsList = [...action.payload];
        }
      })
      .addCase(fetchDocumentContracts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addDocumentContract.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addDocumentContract.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documentContractsList.push(action.payload as AIDocumentContractModelWithId);
      })
      .addCase(addDocumentContract.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteDocumentContract.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteDocumentContract.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documentContractsList = [...state.documentContractsList].filter(
          (el) => el.id !== action.meta.arg.id
        );
      })
      .addCase(deleteDocumentContract.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default documentContractsSlice.reducer;

export const selectAllDocumentContracts = (state: RootState) =>
  state.documentContracts.documentContractsList;
