import React, { useState } from 'react';
import { Autocomplete, Popper, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PrefferredContactMethodInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  autocompleteRef: React.RefObject<HTMLDivElement>;
  popperRef: React.RefObject<HTMLDivElement>;
}

const predefinedPositions = ['Phone', 'Email', 'Social Media'];

export default function PrefferredContactMethodInput({
  value,
  onChange,
  disabled,
  autocompleteRef,
  popperRef,
}: PrefferredContactMethodInputProps) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(value);

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={predefinedPositions}
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      onChange={(_, newValue) => onChange(newValue)}
      PopperComponent={(props) => <Popper {...props} ref={popperRef} />} // Asignar ref al menú desplegable
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('formInputs.preferredContactMethodInputLabel')}
          variant='outlined'
          fullWidth
          disabled={disabled}
          ref={autocompleteRef}
        />
      )}
    />
  );
}
