import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { selectUser } from '../../../slices/user/userSlice';
import { DOCUMENT_CATEGORIES } from '../types';
import { LibraryItem } from '../components/library-list/types';
import { useTranslation } from 'react-i18next';
import { selectAllCompanies } from '../../../slices/companies/companiesSlice';
import { NWClient } from '../../../client/NWClient';
import { fetchDocuments } from '../../../slices/documents/documentsSlice';
import { fetchDocumentTemplates } from '../../../slices/document-templates/documentTemplatesSlice';
import { Helpers } from '../../../common/helpers/helpers';
import { toast } from 'react-toastify';
import { fetchDisclaimerInfo } from '../../../slices/disclaimer/disclaimerSlice';

export const useLibrary = () => {
  const currentUser = useAppSelector(selectUser);
  const company = useAppSelector(selectAllCompanies)[0];
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [documentLibraryItems, setDocumentLibraryItems] = useState<LibraryItem[]>([]);
  const [activeTab, setActiveTab] = useState<DOCUMENT_CATEGORIES>('All');
  const [libraryType, setLibraryType] = useState<'businessKitz' | 'own'>('businessKitz');
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [region, setRegion] = useState<'uk' | 'au' | 'global'>('au');
  const [disclaimerMessage, setDisclaimerMessage] = useState<boolean>(false);
  const libraryItems = [...documentLibraryItems];
  const handleTabChange = (tab: DOCUMENT_CATEGORIES) => {
    setActiveTab(tab);
  };

  const sortByDate = (a: any, b: any) => {
    return Number(new Date(b.created)) - Number(new Date(a.created));
  };

  useEffect(() => {
    dispatch(
      fetchDocuments({
        data: {
          user: currentUser.id,
        },
      })
    );
    dispatch(fetchDisclaimerInfo());
  }, [currentUser]);

  useEffect(() => {
    setPendingStatus(true);
    if (libraryType === 'businessKitz') {
      NWClient.list(
        'document-library',
        activeTab === 'All' ? { region } : { category: activeTab, region }
      )
        .then((res: LibraryItem[]) => {
          const templatesSortedByName = [...res].sort((a, b) => a.name.localeCompare(b.name));
          setDocumentLibraryItems(templatesSortedByName);
        })
        .finally(() => {
          setPendingStatus(false);
        });
    } else {
      dispatch(fetchDocumentTemplates({ data: { user: currentUser.id } }))
        .unwrap()
        .finally(() => {
          setPendingStatus(false);
        });
    }
  }, [activeTab, libraryType, region]);

  return {
    libraryItems,
    activeTab,
    handleTabChange,
    libraryType,
    setLibraryType,
    pendingStatus,
    region,
    setRegion,
    disclaimerMessage,
    setDisclaimerMessage,
  };
};
