import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AuthorizedSignCheckboxProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled: boolean;
}

export default function AuthorizedSignCheckbox({
  value,
  onChange,
  disabled,
}: AuthorizedSignCheckboxProps) {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Checkbox
          id='authorized-sign-checkbox'
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
      }
      label={t('formInputs.authorizedSignInputLabel')}
    />
  );
}
