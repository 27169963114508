import React, { useRef } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CurrencySelectorProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  setPopperRef: (node: HTMLDivElement | null) => void;
}

const currencies = [
  { code: 'USD', name: 'US Dollar' },
  { code: 'EUR', name: 'Euro' },
  { code: 'GBP', name: 'British Pound' },
  { code: 'CAD', name: 'Canadian Dollar' },
  { code: 'AUD', name: 'Australian Dollar' },
  { code: 'CHF', name: 'Swiss Franc' },
];

export default function CurrencySelector({
  value,
  onChange,
  disabled,
  setPopperRef,
}: CurrencySelectorProps) {
  const { t } = useTranslation();

  return (
    <TextField
      select
      fullWidth
      variant='outlined'
      label={t('formInputs.currencyInputLabel')}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      SelectProps={{
        MenuProps: {
          ref: setPopperRef, // Callback ref para manejar la referencia del menú desplegable
        },
      }}
    >
      {currencies.map((currency) => (
        <MenuItem key={currency.code} value={currency.code}>
          {currency.name} ({currency.code})
        </MenuItem>
      ))}
    </TextField>
  );
}
