export const ROUTES = {
  INDEX: '/',
  PROFILE: '/profile',
  EMAIL: '/email',
  FIRM_CREATE: '/firm/create',
  FIRM: '/firm/:id',
  COMPANY_CREATE: '/company-create',
  COMPANY: '/company/:id',
  CATEGORY_LIST: '/document-library',
  CATEGORY: '/document-library/:name',
  SUBCATEGORY: '/document-library/:basic_category/:name',
  SUBCATEGORY_LEVEL_2: '/document-library/:basic_category/:name/:name',
  TEMPLATE_ITEM: `/document-library-sections/:name/:id`,
  TEMPLATE_ITEMS: `/document-library-sections/:name`,
  PAYMENT: '/payment',
  PAYMENT_STATUS: '/payment-status',
  SIGN_UP: '/signup',
  EMAIL_SENT: '/email-sent',
  PROCESS_SUCCESS: '/process-success',
  CONFIRMATION: '/confirmation',
  SETTINGS: '/settings',
  PASSWORD_RESET: '/password-reset',
  LOGIN: '/login',
  PRICING: '/pricing',
  PRODUCT_PLAN_CONFIRMATION: '/product-plan-confirmation',
  STRIPE_PAYMENT_LINK: 'https://pay.businesskitz.com.au/b/test_7sI15vg6egkd7XG9AB',
  STRIPE_PAYMENT_LINK_PROD: 'https://pay.businesskitz.com.au/b/00g5kUcjvfPR4jC8wx',
  SUBSCRIPTIONS: '/subscriptions',
  ACCEPTED_INVITE: '/accept_invite',
  JOIN_COMPANY: '/join_company',
  EMPLOYEE_PROFILES: '/employee-profiles',
  EMPLOYEE_PROFILES_V2: '/employee-profiles-v2',
  EMPLOYEE_PROFILE: '/employee-profiles/:userId',
  EMPLOYEE_PROFILE_V2: '/employee-profiles-v2/:employeeId',
  EMPLOYEE_PROFILE_CREATE: '/employee-profiles/create',
  EMPLOYEE_PROFILE_SETTINGS: '/employee-profile-settings',
  SUPPLIER_PROFILES: '/supplier-profiles',
  SUPPLIER_PROFILE_CREATE: '/supplier-profile',
  SUPPLIER_PROFILE: '/supplier-profiles/:supplierId',
  DOCUMENT_VAULT: '/document-vault',
  DOCUMENT_VAULT_CATEGORY: '/document-vault/:category',
  DOCUMENT_VAULT_CATEGORY_DOCUMENT: '/document-vault/:category/:documentId',
  SIGNING: '/signing',
  SIGNING_DOCUMENT: '/documents/:documentId',
  DOCUMENTS: '/documents',
  CUSTOMER_PROFILES: '/customer-profiles',
  CUSTOMER_PROFILE_CREATE: '/customer-profile',
  CUSTOMER_PROFILE: '/customer-profiles/:customerId',
  DOCUMENT_LIBRARY: '/document-library-sections',
  DEMO_LOGIN: '/demo-login',
  DEMO_SIGNUP: '/demo-signup',
  BUSINESS_PROFILE: '/business-profile',
  DEMO_DOCUMENT_VAULT: '/demo-document-vault',
  DEMO_DOCUMENT_VIEW: '/demo-document-view',
};
