import React, { useState, useEffect } from 'react';
import {
  Stack,
  Paper,
  SelectChangeEvent,
  Button,
  createTheme,
  ThemeProvider,
  Grid,
} from '@mui/material';
import ButtonDropDown from '../button-drop-down/ButtonDropDown';
import FileUploadForm from '../file-upload-form/FileUploadForm';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { uploadDocument, updateDocumentProfileItems } from '../../slices/documents/documentsSlice';
import { DocumentVaultItemModel } from '../../models/document.vault.item.model';
import { NWClient } from '../../client/NWClient';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ProgressOverlay } from '../progress-overlay/ProgressOverlay';
import { useAppSelector } from '../hooks/useAppSelector';
import { selectUser } from '../../slices/user/userSlice';
import { Employee } from '../../models/employee.model';
import { Supplier } from '../../models/supplier.model';
import { Customer } from '../../models/customer.model';
import { BusinessProfileContacts } from '../../slices/business-profile/businessProfileSlice';

interface FormUploadProps {
  categoryValue: 'business' | 'suppliers' | 'customers' | 'employment' | ''; //should be used for specific profile pages or tabs: Business, Customers, Suppliers, Employment
  categoriesAsOption?: boolean;
  onCancel: () => void;
  employees?: Employee[];
  suppliers?: Supplier[];
  customers?: Customer[];
  businessContacts?: BusinessProfileContacts[];
}

const FormUpload: React.FC<FormUploadProps> = ({
  categoriesAsOption,
  onCancel,
  categoryValue,
  employees,
  suppliers,
  customers,
  businessContacts,
}) => {
  const [assigned, setAssigned] = useState('');
  const [pendingStatus, setPendingStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [category, setCategory] = useState<string>(categoryValue || '');
  const dispatch = useAppDispatch();
  const localTheme = createTheme({});
  const currentUser = useAppSelector(selectUser);

  const { t } = useTranslation();

  const employeesOptions = employees?.map((employee) => ({
    value: employee.id.toString(),
    label: `${employee.first_name} ${employee.last_name}`,
  }));

  const suppliersOptions = suppliers?.map((supplier) => ({
    value: supplier.id.toString(),
    label: `${supplier.name}`,
  }));

  const customersOptions = customers?.map((customer) => ({
    value: customer.id.toString(),
    label: `${customer.customer_name}`,
  }));

  const businessContactsOptions = businessContacts?.map((contact) => ({
    value: contact.id.toString(),
    label: `${contact.contact_name}`,
  }));

  const categoryOptions: {
    value: 'business' | 'suppliers' | 'customers' | 'employment';
    label: string;
  }[] = [
    { value: 'business', label: 'Business' },
    { value: 'customers', label: 'Customers' },
    { value: 'employment', label: 'Employment' },
    { value: 'suppliers', label: 'Suppliers' },
  ];

  const getProfileNames = () => {
    switch (category) {
      case 'employment':
        return employeesOptions;
      case 'customers':
        return customersOptions;
      case 'suppliers':
        return suppliersOptions;
      case 'business':
        return businessContactsOptions;
      default:
        return [];
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleAssignedChange = (event: SelectChangeEvent) => {
    setAssigned(event.target.value);
  };

  const getProfileLabelsProp = () => {
    if (category === 'employment') {
      return 'employees';
    } else if (category === 'business') {
      return 'business_profile_contacts';
    } else {
      return category;
    }
  };

  useEffect(() => {
    if (employees?.length && category === 'employment') {
      setAssigned(employees[0].id.toString());
    }
  }, [employees, category]);

  const createDocumentHTML = (documentId: number) => {
    NWClient.post('document-upload-html', { document_upload: documentId })
      .then(() => {
        toast.success(t('messages.documentUploaded'));
      })
      .catch((error) => {
        toast.error(error.message || t('messages.errorOccurred'));
      })
      .finally(() => {
        onCancel();
        setPendingStatus(false);
      });
  };

  const handleSave = () => {
    if (!selectedFile) return;
    setPendingStatus(true);
    const data = new FormData();
    data.append('document_file', selectedFile);
    data.append('category', categoriesAsOption ? category.toLowerCase() : categoryValue);
    data.append('user', String(currentUser.id));

    dispatch(uploadDocument({ data }))
      .unwrap()
      .then((res: DocumentVaultItemModel) => {
        setSelectedFile(null);
        if (assigned) {
          const data = {
            [getProfileLabelsProp()]: [Number(assigned)],
          };
          dispatch(updateDocumentProfileItems({ id: res.id, data }))
            .unwrap()
            .then(() => {
              createDocumentHTML(res.id);
            })
            .catch(() => {
              setPendingStatus(false);
            });
        } else {
          createDocumentHTML(res.id);
        }
      })
      .catch((error) => {
        onCancel();
        setPendingStatus(false);
        toast.error(error.message || t('messages.errorOccurred'));
      });
  };

  return (
    <div>
      {pendingStatus ? (
        <ProgressOverlay />
      ) : (
        <ThemeProvider theme={localTheme}>
          <Paper
            onClick={onCancel}
            sx={{
              backgroundColor: 'transparent',
              position: 'fixed',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 1000,
              boxShadow: 'none',
            }}
          />
          <Paper
            sx={{ borderColor: '#6414db', position: 'relative', zIndex: 1005 }}
            elevation={0}
            className='form-upload__paper'
          >
            <Stack spacing={3}>
              <FileUploadForm onFileSelect={(file) => setSelectedFile(file)} />
              <Stack direction='row' gap={'16px'} flexWrap={'wrap'}>
                {categoriesAsOption && (
                  <Grid item flexGrow={1} width={'100%'} maxWidth={'calc(50% - 8px)'}>
                    <ButtonDropDown
                      label={t('labels.chooseProfile')}
                      value={category}
                      onChange={handleCategoryChange}
                      options={categoryOptions}
                      defaultValue={categoryValue}
                      required
                      placeholder={t('labels.profile')}
                    />
                  </Grid>
                )}
                <Grid item flexGrow={1} width={'100%'} maxWidth={'calc(50% - 8px)'}>
                  <ButtonDropDown
                    label={t('labels.assignTo')}
                    value={assigned}
                    onChange={handleAssignedChange}
                    options={getProfileNames()}
                    placeholder={t('labels.chooseAssignee')}
                    disabled={!category}
                  />
                </Grid>
              </Stack>
              <Stack
                direction='row'
                gap={'8px'}
                justifyContent='flex-end'
                className='form-upload__button-group'
              >
                <Button
                  variant='contained'
                  className='btn-default btn-action-basic btn-cancel'
                  onClick={onCancel}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button
                  variant='contained'
                  className='btn-default btn-action-basic'
                  onClick={handleSave}
                  disabled={categoriesAsOption ? !category || !selectedFile : !selectedFile}
                >
                  {t('buttons.save')}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </ThemeProvider>
      )}
    </div>
  );
};

export default FormUpload;
