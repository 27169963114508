import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { RootState } from '../../store';
import { UserSigningInfoModel } from '../../models/user.signing.info.model';

export const fetchUserSigningInfo = createAsyncThunk('user/fetchUserSigningInfo', async () => {
  const response: UserSigningInfoModel[] = await NWClient.list('user/documenso_user_identity');
  return response;
});

export interface UserSigningInfoState {
  info: UserSigningInfoModel[];
  status: string;
  error: string;
}

const initialState: UserSigningInfoState = {
  info: [],
  status: '',
  error: '',
};

const userSigningInfoSlice = createSlice({
  name: 'userSigningInfo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUserSigningInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserSigningInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.info = action.payload;
      })
      .addCase(fetchUserSigningInfo.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default userSigningInfoSlice.reducer;

export const selectUserSigningInfo = (state: RootState) => state.userSigningInfo.info;
export const selectUserFetchStatus = (state: RootState) => state.userSigningInfo.status;
export const selectUserFetchError = (state: RootState) => state.userSigningInfo.error;
