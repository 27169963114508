import { useState } from 'react';
import { LibraryItem } from '../../../pages/library/components/library-list/types';
import { NWClient } from '../../../client/NWClient';
import { toast } from 'react-toastify';
import { ROUTES } from '../../../common/constants/routes';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const useContractsGenerator = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const [generatorOpened, setGeneratorOpened] = useState<boolean>(false);
  const [libraryItem, setLibraryItem] = useState<LibraryItem>(null);
  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);
  const [planUpgradingMessage, setPlanUpgradingMessage] = useState<boolean>(false);
  const [secondPartyCategory, setSecondPartyCategory] = useState<string>('employment');
  const [secondParty, setSecondParty] = useState<string>('noSecondParty');

  const handleGenerateTemplate = (
    libraryItem: LibraryItem,
    data: {
      user: number;
      document_library?: number;
      document_template?: number;
      company_id: number;
      secondary_id?: number;
      category: string;
    }
  ) => {
    setAnalysisLoading(true);
    NWClient.post('ai-contract', data)
      .then(
        (res: {
          id: number;
          user: number;
          document_library?: number;
          document_template?: number;
          placeholder_matches: { [key: string]: string };
          category: string;
        }) => {
          toast.success(t('messages.contractGenerated'), { theme: 'colored' });
          navigate(
            `${
              'region' in libraryItem
                ? `${ROUTES.DOCUMENT_LIBRARY}/${libraryItem.region.toLowerCase()}/`
                : `${ROUTES.DOCUMENT_LIBRARY}/company-templates/`
            }${res.id}`
          );
        }
      )
      .catch((error) => {
        setAnalysisLoading(false);
        if (
          error.response?.data?.errors?.length > 0 &&
          error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
        ) {
          setPlanUpgradingMessage(true);
        } else if (
          error.response?.data?.errors?.length > 0 &&
          error.response?.data?.errors[0] === t('messages.userNotAssociatedWithCompany')
        ) {
          navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${path}`);
        } else {
          toast.error(error.message ? error.message : t('messages.errorOccurred'));
        }
      });
  };

  return {
    generatorOpened,
    setGeneratorOpened,
    libraryItem,
    setLibraryItem,
    analysisLoading,
    setAnalysisLoading,
    handleGenerateTemplate,
    planUpgradingMessage,
    setPlanUpgradingMessage,
    secondParty,
    secondPartyCategory,
    setSecondParty,
    setSecondPartyCategory,
  };
};
