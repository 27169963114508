import { Box, Card, CardContent, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import styles from './AddCustomerCard.module.scss';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';

const AddCustomerCard = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(ROUTES.CUSTOMER_PROFILE_CREATE);
  };
  return (
    <Grid item xs={2}>
      <Card className={styles.card_min_height}>
        <CardContent className={styles.full_height}>
          <Grid container direction='column' className={styles.card_min_height}>
            <Grid item>
              <Box textAlign='center'>Add customer</Box>
            </Grid>
            <Grid
              item
              sx={{ flexGrow: 1 }}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <IconButton onClick={handleOnClick} className={styles.add_button}>
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AddCustomerCard;
