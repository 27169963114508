import React from 'react';
import { MenuBlockProps } from './types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import AddIcon from '../../../static/assets/svg/add-icon.svg';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

const MenuBlock = ({ options, moreIcon }: MenuBlockProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label={t('labels.more')}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        className={!moreIcon ? 'add-icon-wrapper' : ''}
      >
        {moreIcon ? moreIcon : <AddIcon />}
      </IconButton>
      <Menu
        sx={{ marginTop: '0.5rem' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((el) => (
          <MenuItem
            key={el.name}
            onClick={() => {
              handleClose();
              el.onClick();
            }}
          >
            {el.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MenuBlock;
