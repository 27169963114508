import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import BankIcon from '../../../static/assets/svg/account_balance.svg';

import { useTranslation } from 'react-i18next';
import AccountNameHolder from '../form-inputs/account-name-holder-input/AccountNameHolderInput';
import SwiftInput from '../form-inputs/swift-input/SwiftInput';
import AccountNumberInput from '../form-inputs/account-number-input/AccountNumberInput';
import BankCountry from '../form-inputs/bank-country-input/BankCountryInput';
import BrushIcon from '../../../static/assets/svg/brush.svg';
import { useAppSelector } from '../hooks/useAppSelector';
import {
  BusinessProfileBankDetails,
  selectBusinessProfileBankDetails,
  selectBusinessProfileStatus,
  updateBusinessProfileBankDetails,
} from '../../slices/business-profile/businessProfileSlice';
import ConfirmModal from '../confirm-modal/ConfirmModal';
import styles from './BankDetailsCard.module.scss';
import CurrencySelector from '../currency-selector/CurrencySelector';
import { useAppDispatch } from '../hooks/useAppDispatch';
import CompanyContactItem from '../../pages/settings/components/CompanyContactItem/CompanyContactItem';

interface BankDetailsCardProps {
  bank: BusinessProfileBankDetails;
}

export default function BankDetailsCard({ bank }: BankDetailsCardProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isEdited, setIsEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef<HTMLDivElement | null>(null);
  const [popperNode, setPopperNode] = useState<HTMLDivElement | null>(null);

  const businessProfileStatus = useAppSelector(selectBusinessProfileStatus);

  const isLoading = businessProfileStatus === 'loading';

  const toggleEditing = () => {
    setIsEdited((prev) => !prev);
  };

  const [formData, setFormData] = useState<BusinessProfileBankDetails>({
    id: bank?.id,
    company: bank?.company,
    account_number: bank?.account_number,
    account_name: bank?.account_name,
    swift_code: bank?.swift_code,
    country: bank?.country,
    currency: bank?.currency || 'AUD',
  });

  const saveChanges = () => {
    if (!bank?.id) return;

    dispatch(
      updateBusinessProfileBankDetails({
        companyId: bank.company,
        id: bank.id,
        data: formData,
      })
    );
    setIsEdited(false);
    setShowModal(false);
  };
  const confirmExit = () => {
    setIsEdited(false);
    setShowModal(false);
  };

  const handleChange =
    <K extends keyof BusinessProfileBankDetails>(field: K) =>
    (value: BusinessProfileBankDetails[K]) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isEdited &&
        formRef.current &&
        !formRef.current.contains(event.target as Node) &&
        (!popperNode || !popperNode.contains(event.target as Node)) // Ahora ignora correctamente los clics en el menú
      ) {
        setTimeout(() => {
          setShowModal(true);
        }, 0);
      }
    };

    if (isEdited) document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEdited, popperNode]);

  return (
    <Grid container className='inputs-editable'>
      <Grid item xs={12}>
        <Card className={`${styles.card} ${isEdited ? styles.card_border : ''}`} ref={formRef}>
          <CardContent
            className={isLoading ? styles.card_content_min_height : ''}
            sx={{ paddingTop: 0, paddingBottom: '0 !important' }}
          >
            <Grid container alignItems='center' flexWrap='nowrap' p={2}>
              <Grid container alignItems='center' gap={1}>
                <Grid sx={{ marginBottom: '0.2rem' }} item>
                  <BankIcon />
                </Grid>
                <Grid item>
                  <Typography variant='h6' className='semibold-variant'>
                    {t('businessProfile.bankCardHeader')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={1} container justifyContent='flex-end'>
                {!isEdited ? (
                  <Tooltip className='flex-shrink-0' title={t('buttons.edit')} placement='top'>
                    <IconButton onClick={toggleEditing}>
                      <BrushIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Button
                    type='submit'
                    className='btn-default btn-action-basic'
                    role='button'
                    disabled={!isEdited}
                    variant='contained'
                    size='large'
                    onClick={saveChanges}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AccountNameHolder
                  value={formData.account_name}
                  onChange={handleChange('account_name')}
                  disabled={!isEdited}
                />
              </Grid>
              <Grid item xs={6}>
                <SwiftInput
                  value={formData.swift_code}
                  onChange={handleChange('swift_code')}
                  disabled={!isEdited}
                />
              </Grid>
              <Grid item xs={6}>
                <AccountNumberInput
                  value={formData.account_number}
                  onChange={handleChange('account_number')}
                  disabled={!isEdited}
                />
              </Grid>
              <Grid item xs={6}>
                <CurrencySelector
                  value={formData.currency}
                  onChange={handleChange('currency')}
                  disabled={!isEdited}
                  setPopperRef={setPopperNode}
                />
              </Grid>
              <Grid item xs={6}>
                <BankCountry
                  value={formData.country}
                  onChange={handleChange('country')}
                  disabled={!isEdited}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <ConfirmModal open={showModal} onClose={saveChanges} onConfirm={confirmExit} />
    </Grid>
  );
}
