import React, { useState } from 'react';
import { LibraryItem } from '../components/library-list/types';
import { deleteDocumentTemplate } from '../../../slices/document-templates/documentTemplatesSlice';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { selectUser } from '../../../slices/user/userSlice';
import { useTranslation } from 'react-i18next';

export const useDeleteLibraryItemMessageModal = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [libraryItemToDelete, setLibraryItemToDelete] = useState<LibraryItem | null>(null);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);

  const handleDeleteDocumentTemplate = () => {
    setPendingStatus(true);
    setModalOpen(false);
    dispatch(deleteDocumentTemplate({ id: libraryItemToDelete.id, userId: currentUser.id }))
      .unwrap()
      .catch((error) => {
        toast.error(error.message || t('messages.errorOccurred'));
      })
      .finally(() => {
        setLibraryItemToDelete(null);
        setModalOpen(false);
        setPendingStatus(false);
      });
  };

  return {
    modalOpen,
    setModalOpen,
    libraryItemToDelete,
    setLibraryItemToDelete,
    pendingStatus,
    handleDeleteDocumentTemplate,
  };
};
