import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Template } from '../../../models/template.model';
import { NWClient } from '../../../client/NWClient';
import useDebounce from '../../../common/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { Helpers } from '../../../common/helpers/helpers';
//import { Category } from '../../../models/category.model';
import { ROUTES } from '../../../common/constants/routes';
import { useNavigate, useLocation } from 'react-router';

interface UseSearchBarProps {
  //items: Category[];
  items: { name: string; id: number; className: string }[];
  filtered: boolean;
}

export const useSearchBar = (props: UseSearchBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { items, filtered } = props;
  const [searchCategory, setSearchCategory] = useState<string>('');
  const [searchTemplateInput, setSearchTemplateInput] = useState<string>('');
  const [options, setOptions] = useState<readonly Template[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState('');
  const [errorState, setErrorState] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);

  const debouncedSearchTemplateInput = useDebounce<string>(searchTemplateInput, 500);
  const { t } = useTranslation();

  const fetchTemplates = useCallback(async () => {
    if (debouncedSearchTemplateInput) {
      const templates: Template[] = await NWClient.list('template', {
        name: debouncedSearchTemplateInput,
        category_name: searchCategory === 'All' || !filtered ? undefined : searchCategory,
      });

      if (templates) {
        setOptions(templates);
      }
    } else {
      setOptions([]);
    }
  }, [filtered, searchCategory, debouncedSearchTemplateInput]);

  useEffect(() => {
    fetchTemplates();
  }, [debouncedSearchTemplateInput, fetchTemplates]);

  useMemo(() => {
    if (!isOpen) {
      setOptions([]);
    }
  }, [isOpen]);

  const handleSearchSelect = (value: string) => {
    setSearchCategory(value);
  };

  const setError = (errorMessage: string | null) => {
    setErrorState(true);
    setErrorText(errorMessage);
  };
  const handleOptionClick = (
    event: React.MouseEvent<HTMLInputElement>,
    option: { id?: number; name?: string }
  ) => {
    if (option?.id) {
      setIsDownloading(true);
      NWClient.downloadTemplate(option?.id, option?.name)
        .catch((error) => {
          console.log(error);
          const status = error.response && error.response.status;
          setErrorStatus(status);
          const errorMessage = Helpers.returnErrorText(error);
          status === 405
            ? navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${location.pathname}`)
            : status !== 403
            ? setError(errorMessage.toString())
            : setError(null);
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newInputValue: string
  ) => {
    setSearchTemplateInput(newInputValue);
  };

  const onOpenAutocomplete = () => {
    if (searchTemplateInput) {
      fetchTemplates();
    }
    setIsOpen(true);
  };

  const onCloseAutocomplete = () => {
    setIsOpen(false);
  };

  return {
    options,
    selectOptions: filtered && [
      { label: t('select.defaultValueLabel'), value: 'All', className: 'level-1' },
      ...items.map(({ name, className }) => ({
        label: name,
        value: name,
        className: className,
      })),
    ],
    handleSearchInputChange,
    handleSearchSelect,
    onOpenAutocomplete,
    onCloseAutocomplete,
    handleOptionClick,
    searchTemplateInput,
    isDownloading,
    errorText,
    errorStatus,
    errorState,
    setErrorState,
  };
};
