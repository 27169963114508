// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ux_JyoTgzY4wfecEKWlf{border-radius:10px !important}.DC_VUn1lwcWRym9SwEa2{margin-bottom:14px}`, "",{"version":3,"sources":["webpack://./src/app/common/basic-tabs/BasicTabs.module.scss"],"names":[],"mappings":"AAAA,sBACE,6BAAA,CAGF,sBACE,kBAAA","sourcesContent":[".tab_border_radius {\n  border-radius: 10px !important;\n}\n\n.tab_margin {\n  margin-bottom: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab_border_radius": `ux_JyoTgzY4wfecEKWlf`,
	"tab_margin": `DC_VUn1lwcWRym9SwEa2`
};
export default ___CSS_LOADER_EXPORT___;
