import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import AddressInput from '../form-inputs/address-input/AddressInput';
import AuthorizedSignCheckbox from '../authorized-sign-checkbox/AuthorizedSignCheckbox';
import EmailAddressInput from '../form-inputs/email-address-input/EmailAddresInput';
import HiringManagerCheckbox from '../hiring-manager-checkbox/HiringManagerCheckBox';
import PositionInput from '../form-inputs/position-input/PositionInput';
import LegalRepresentationCheckbox from '../legal-representation-checkbox/LegalRepresentationCheckbox';
import PhoneInput from '../form-inputs/phone-input/PhoneInput';
import ShareholderCheckbox from '../share-holder-checkbox/ShareHolderCheckbox';
import BadgeIcon from '../../../static/assets/svg/badge.svg';
import styles from './ContactCard.module.scss';

import {
  BusinessProfileContacts,
  deleteBusinessProfileContact,
  selectBusinessProfileStatus,
  updateBusinessProfileContact,
} from '../../slices/business-profile/businessProfileSlice';
import { useAppSelector } from '../hooks/useAppSelector';
import ContactDropdown from './ContactDropDown';
import ConfirmModal from '../confirm-modal/ConfirmModal';
import ContactNameInput from '../form-inputs/contact-name-input/ContactNameInput';

interface ContactCardProps {
  contact: BusinessProfileContacts;
  companyId: number;
}

export default function ContactCard({ contact, companyId }: ContactCardProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const businessProfileStatus = useAppSelector(selectBusinessProfileStatus);
  const isLoading = businessProfileStatus === 'loading';

  const [isEdited, setIsEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef<HTMLDivElement | null>(null);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const toggleEditing = () => {
    setIsEdited((prev) => !prev);
  };

  const saveChanges = () => {
    if (!contact?.id) return;

    dispatch(
      updateBusinessProfileContact({
        companyId: companyId,
        id: contact.id,
        data: formData,
      })
    );

    setIsEdited(false);
    setShowModal(false);
  };

  const handleDelete = () => {
    if (!contact?.id) return;

    dispatch(deleteBusinessProfileContact({ companyId: companyId, id: contact.id }));
    setIsEdited(false);
  };

  const confirmExit = () => {
    setIsEdited(false);
    setShowModal(false);
  };

  const [formData, setFormData] = useState<BusinessProfileContacts>({
    id: contact.id,
    company: companyId,
    contact_name: contact.contact_name,
    contact_email: contact.contact_email,
    contact_phone_number: contact.contact_phone_number ?? null,
    contact_position: contact.contact_position,
    contact_address: contact.contact_address ?? null,
    is_authorized_signatory: contact.is_authorized_signatory,
    is_shareholder: contact.is_shareholder,
    is_hiring_manager: contact.is_hiring_manager,
    is_legal_representative: contact.is_legal_representative,
  });

  const handleChange =
    <K extends keyof BusinessProfileContacts>(field: K) =>
    (value: BusinessProfileContacts[K]) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isEdited &&
        formRef.current &&
        !formRef.current.contains(event.target as Node) &&
        (!autocompleteRef.current || !autocompleteRef.current.contains(event.target as Node)) &&
        (!popperRef.current || !popperRef.current.contains(event.target as Node)) // Evita cierre si se hace clic dentro del menú de opciones
      ) {
        if (event.target instanceof HTMLElement) {
          const ignoredElements = ['BUTTON', 'INPUT', 'TEXTAREA', 'SELECT', 'LABEL'];
          if (ignoredElements.includes(event.target.tagName)) return;
        }

        setTimeout(() => {
          setShowModal(true);
        }, 0);
      }
    };

    if (isEdited) document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEdited]);

  return (
    <Card
      className={`inputs-editable ${styles.card} ${isEdited ? styles.card_border : ''}`}
      ref={formRef}
    >
      <CardContent
        className={isLoading ? styles.card_content_min_height : ''}
        sx={{ paddingTop: 0, paddingBottom: '16px !important' }}
      >
        <Grid container>
          <Grid container alignItems='center' p={2}>
            <Grid item xs={11} alignItems='center' gap={1}>
              <Grid container alignItems='center' gap={1}>
                <Grid item>
                  <BadgeIcon />
                </Grid>
                <Grid item>
                  <Typography variant='subtitle1'>{contact.contact_name}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} container justifyContent='flex-end'>
              {!isEdited ? (
                <ContactDropdown onEdit={toggleEditing} onDelete={handleDelete} />
              ) : (
                <Button
                  type='submit'
                  className='btn-default btn-action-basic'
                  role='button'
                  disabled={!isEdited}
                  variant='contained'
                  size='large'
                  onClick={saveChanges}
                >
                  {t('buttons.save')}
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={10} container spacing={2}>
              {isEdited && (
                <Grid item xs={12} sm={3}>
                  <ContactNameInput
                    value={formData.contact_name}
                    onChange={handleChange('contact_name')}
                    disabled={!isEdited}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={3}>
                <EmailAddressInput
                  value={formData.contact_email}
                  onChange={handleChange('contact_email')}
                  disabled={!isEdited}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <PhoneInput
                  value={formData.contact_phone_number}
                  onChange={handleChange('contact_phone_number')}
                  disabled={!isEdited}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <AddressInput
                  value={formData.contact_address}
                  onChange={handleChange('contact_address')}
                  disabled={!isEdited}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <PositionInput
                  value={formData.contact_position}
                  onChange={handleChange('contact_position')}
                  disabled={!isEdited}
                  autocompleteRef={autocompleteRef}
                  popperRef={popperRef}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={styles.checkbox_font}>
            <Grid item xs={6} sm={3}>
              <AuthorizedSignCheckbox
                value={formData.is_authorized_signatory}
                onChange={handleChange('is_authorized_signatory')}
                disabled={!isEdited}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <ShareholderCheckbox
                value={formData.is_shareholder}
                onChange={handleChange('is_shareholder')}
                disabled={!isEdited}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <HiringManagerCheckbox
                value={formData.is_hiring_manager}
                onChange={handleChange('is_hiring_manager')}
                disabled={!isEdited}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <LegalRepresentationCheckbox
                value={formData.is_legal_representative}
                onChange={handleChange('is_legal_representative')}
                disabled={!isEdited}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      <ConfirmModal open={showModal} onClose={saveChanges} onConfirm={confirmExit} />
    </Card>
  );
}
