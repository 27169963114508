import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { NWClient } from '../../client/NWClient';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../constants/routes';

export const NoCompanyRedirect = ({
  children,
}: {
  children?: ReactNode | undefined;
}): ReactElement => {
  const [company, setCompany] = useState(null);
  const token = localStorage.getItem('access_token');
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const path = location.pathname;

  useEffect(() => {
    if (token) {
      NWClient.getCurrentCompany()
        .then((res) => {
          if (Array.isArray(res) ? res.length > 0 : Boolean(res)) {
            setCompany(Array.isArray(res) ? res[0] : res);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  if (loading) {
    return null;
  }

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  if (!company?.id && token) {
    return <Navigate to={`${ROUTES.COMPANY_CREATE}?backUrl=${path}`} replace />;
  }
  return <>{children}</>;
};
