import React, { useEffect, useState } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { CircularProgress, Divider, Grid } from '@mui/material';
//import SVG from 'react-inlinesvg';
//import { BRAND_COLOR } from '../../../../common/constants/colors';
import { TabProps } from './types';
import { NWClient } from '../../../../client/NWClient';
//import { getResourceLink } from '../../../../common/utils';
import SubscriptionItem from './SubscriptionItem/SubscriptionItem';
import { Helpers } from '../../../../common/helpers/helpers';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants/routes';
import PricingIcon from '../../../../../static/assets/svg/menu/pricing.svg';
import { useTranslation } from 'react-i18next';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';

export const SubscriptionDetailsTab = ({ currentTab }: TabProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState(null);
  const [plan, setPlan] = useState(null);
  const [errorTextArray, setErrorTextArray] = useState([]);
  const { t } = useTranslation();
  const setChargeAmount = () => {
    if (subscription && plan) {
      if (!subscription.discount) {
        return plan.amount_decimal.length > 0
          ? (parseFloat(plan.amount_decimal) / 100).toFixed(2)
          : (plan.amount_decimal / 100).toFixed(2);
      } else {
        if (subscription.discount.coupon.amount_off) {
          return plan.amount_decimal.length > 0
            ? (
                parseFloat(plan.amount_decimal) / 100 -
                subscription.discount.coupon.amount_off / 100
              ).toFixed(2)
            : (plan.amount_decimal / 100 - subscription.discount.coupon.amount_off / 100).toFixed(
                2
              );
        }
        if (subscription.discount.coupon.percent_off) {
          const amount =
            plan.amount_decimal.length > 0
              ? parseFloat(plan.amount_decimal) / 100
              : plan.amount_decimal / 100;
          return (amount - amount * (subscription.discount.coupon.percent_off / 100)).toFixed(2);
        }
      }
    } else {
      return '0';
    }
  };
  const chargeAmount = setChargeAmount();
  const chargeCurrency = subscription && plan ? plan.currency : '';
  const DefaultMessage = () => {
    return (
      <p className='text-center'>
        {t('messages.noActiveSubscription')}&nbsp;
        <Link className='link-brand-color' to={ROUTES.PRICING}>
          {t('links.pricingOptions')}
        </Link>
        .
      </p>
    );
  };

  useEffect(() => {
    setLoading(true);
    NWClient.getCurrentCompany()
      .then((res) => {
        if (Array.isArray(res) && res.length > 0 && res[0].stripe_subscription) {
          setSubscription(res[0].stripe_subscription);
          NWClient.get('plan', String(res[0].stripe_subscription.plan))
            .then((res) => {
              setPlan(res);
            })
            .catch((error) => {
              Helpers.handleRequestError(error.response, setErrorTextArray, setLoading);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        Helpers.handleRequestError(error.response, setErrorTextArray, setLoading);
      });
  }, []);

  return (
    <TabPanel value={currentTab} index={2} prefix={'vertical'}>
      {loading ? (
        <Grid container justifyContent='center'>
          <ProgressOverlay className='position-relative' />
          {/*<CircularProgress/>*/}
        </Grid>
      ) : (
        <Grid>
          <Grid container item className='setting-panel-header' alignItems='center'>
            <PricingIcon />
            <h3>{t('settings.titles.plan')}</h3>
          </Grid>
          <Divider />
          <div className='setting-panel-body'>
            {subscription && subscription.status !== 'canceled' ? (
              <SubscriptionItem
                currentPeriodEnd={Helpers.formatDBDateToUI(subscription.current_period_end)}
                currentPeriodStart={Helpers.formatDBDateToUI(subscription.current_period_start)}
                status={subscription.status}
                startDate={Helpers.formatDBDateToUI(subscription.start_date)}
                errorTextArray={errorTextArray}
                discount={subscription.discount}
                chargeDate={Helpers.formatDBDateToUI(subscription.current_period_end)}
                chargeAmount={String(chargeAmount)}
                chargeCurrency={chargeCurrency}
              />
            ) : (
              <DefaultMessage />
            )}
          </div>
        </Grid>
      )}
    </TabPanel>
  );
};
