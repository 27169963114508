import { useState, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import {
  fetchBusinessProfileById,
  selectBusinessProfileDetails,
  updateBusinessProfileEntry,
  BusinessProfileDetails,
} from '../../slices/business-profile/businessProfileSlice';
import { useAppSelector } from '../hooks/useAppSelector';
import { NWClient } from '../../client/NWClient';
import { Employee } from '../../models/employee.model';
import { t } from 'i18next';
import { toast } from 'react-toastify';

export function useEmergencyContactCardForm(employee: Partial<Employee>) {
  const [isEdited, setIsEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [showAddSocialModal, setAddSocialShowModal] = useState(false);
  const formRef = useRef<HTMLDivElement | null>(null);

  const [formData, setFormData] = useState<any>({
    first_name: employee.emergency_contact?.first_name || '',
    last_name: employee.emergency_contact?.last_name || '',
    email_address: employee.emergency_contact?.email_address || '',
    postal_address: employee.emergency_contact?.postal_address || '',
    relationship: employee.emergency_contact?.relationship || '',
    phone_number: employee.emergency_contact?.phone_number || '',
  });

  useEffect(() => {
    if (employee?.emergency_contact) {
      setFormData({
        first_name: employee.emergency_contact.first_name || '',
        last_name: employee.emergency_contact.last_name || '',
        email_address: employee.emergency_contact.email_address || '',
        postal_address: employee.emergency_contact.postal_address || '',
        relationship: employee.emergency_contact.relationship || '',
        phone_number: employee.emergency_contact.phone_number || '',
      });
    } else {
      // Reset to default values if emergency_contact is null
      setFormData({
        first_name: '',
        last_name: '',
        email_address: '',
        postal_address: '',
        relationship: '',
        phone_number: '',
      });
    }
  }, [employee]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isEdited && formRef.current && !formRef.current.contains(event.target as Node)) {
        const targetElement = event.target as HTMLElement;
        if (event.target instanceof HTMLElement) {
          const ignoredElements = ['BUTTON', 'INPUT', 'TEXTAREA', 'SELECT', 'LABEL', 'MENU-ITEM'];
          // if (ignoredElements.includes(event.target.tagName)) return;
        }

        if (
          targetElement.closest('.MuiMenuItem-root') || // Ignore clicks on MenuItem
          targetElement.closest('.MuiSelect-root') // Ignore clicks on Select
        ) {
          return;
        }

        setTimeout(() => {
          setShowModal(true);
        }, 0);
      }
    };
    if (isEdited) document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEdited]);

  const handleChange =
    <K extends keyof any>(field: K) =>
    (value: any[K]) => {
      setFormData((prev: any) => ({
        ...prev,
        [field]: value,
      }));
    };

  const saveChanges = () => {
    if (!employee?.id) return;
    if (!employee.emergency_contact) {
      NWClient.post(`employee/${employee.id}/emergency_contact`, formData, true);
    } else {
      NWClient.put(
        `employee/${employee.id}/emergency_contact`,
        employee.emergency_contact.id,
        formData,
        true,
        true
      );
    }
    setIsEdited(false);
    setShowModal(false);
  };

  const toggleEditing = () => {
    setIsEdited((prev) => !prev);
  };

  const toogleEditingSocialModal = () => {
    setAddSocialShowModal((prev) => !prev);
  };

  const confirmExit = () => {
    setIsEdited(false);
    setShowModal(false);
  };

  return {
    formData,
    handleChange,
    saveChanges,
    isEdited,
    setIsEdited,
    toggleEditing,
    formRef,
    showModal,
    setShowModal,
    confirmExit,
    showAddSocialModal,
    setAddSocialShowModal,
    toogleEditingSocialModal,
  };
}
