import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLinkItem } from '../templates/components/DashboardLinks/DashboardLinkItem';
import { ROUTES } from '../../common/constants/routes';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import DocumentVaultV2 from './DocumentVaultv2';

const DocumentVault = () => {
  const { t } = useTranslation();
  //const designUpdated = useAppSelector((state) => state.designUpdated.designUpdated);
  return (
    <>
      <DocumentVaultV2 />

      {/*  <div>
          <h1 className='text-center mb-2 fs-2'>Document vault</h1>
          <p className='text-center mb-4 fs-5'>{t('messages.storeDocuments')}</p>
          <div className='d-flex flex-wrap justify-content-center align-items-start'>
            <div className='d-flex flex-wrap justify-content-center'>
              <ul className='dashboard-links document-vault-links list-unstyled d-flex flex-wrap justify-content-center'>
                <DashboardLinkItem
                  route={ROUTES.DOCUMENT_VAULT + '/employment'}
                  imageSrc='assets/svg/menu/employment-icon.svg'
                  linkText={t('Employment')}
                  svgClassName='vault-icon'
                />
                <DashboardLinkItem
                  route={ROUTES.DOCUMENT_VAULT + '/customers'}
                  imageSrc='assets/svg/customers.svg'
                  linkText={t('Customers')}
                  svgClassName='vault-icon'
                />
                <DashboardLinkItem
                  route={ROUTES.DOCUMENT_VAULT + '/suppliers'}
                  imageSrc='assets/svg/menu/business-icon.svg'
                  linkText={t('documentVault.suppliers')}
                  svgClassName='vault-icon'
                />
                <DashboardLinkItem
                  route={ROUTES.DOCUMENT_VAULT + '/business'}
                  imageSrc='assets/svg/menu/category.svg'
                  linkText={t('documentVault.business')}
                  svgClassName='vault-icon'
                />
              </ul>
            </div>
            <p className='text-center mt-4 fst-italic fs-5'>
              {t('messages.documentVaultComingSoon')}
            </p>
          </div>
        </div>
      */}
    </>
  );
};

export default DocumentVault;
