import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import OrganizationIcon from '../../../static/assets/svg/organization.svg';
import BrushIcon from '../../../static/assets/svg/brush.svg';
import AccountCircleIcon from '../../../static/assets/svg/account_circle.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../hooks/useAppSelector';
import {
  fetchBusinessProfileContactById,
  selectBusinessProfileContacts,
  selectBusinessProfileStatus,
} from '../../slices/business-profile/businessProfileSlice';
import styles from './OrganizationInfoCard.module.scss';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { fetchCompanies, selectAllCompanies } from '../../slices/companies/companiesSlice';

export default function OrganizationInfoCard() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectAllCompanies)[0];

  const businessProfileStatus = useAppSelector(selectBusinessProfileStatus);
  const businessProfileContacts = useAppSelector(selectBusinessProfileContacts);

  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchBusinessProfileContactById({ id: company.id }));
  }, [dispatch]);

  const isLoading = businessProfileStatus === 'loading';

  const directors = businessProfileContacts.filter(
    (contact) => contact.contact_position === 'Director'
  );
  const accountants = businessProfileContacts.filter(
    (contact) => contact.contact_position === 'Accountant'
  );
  const secretaries = businessProfileContacts.filter(
    (contact) => contact.contact_position === 'Secretary'
  );

  return (
    <Grid container className={styles.card_content_height}>
      <Grid item xs={12}>
        <Card className={`inputs-editable ${styles.card} ${styles.card_content_height}`}>
          <CardContent
            className={styles.card_content_height}
            sx={{ paddingTop: 0, paddingBottom: '16px !important' }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Grid
                  sx={{ marginLeft: '-0.5rem' }}
                  container
                  alignItems='left'
                  flexWrap='nowrap'
                  p={1}
                >
                  <Grid item xs={11} container alignItems='center'>
                    <IconButton sx={{ marginBottom: '0.2rem' }}>
                      <AccountCircleIcon />
                    </IconButton>
                    <Typography variant='h6' className='semibold-variant'>
                      {t('businessProfile.organizationCardHeader')}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container className={styles.grid_left_margin}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant='subtitle1'>{t('businessProfile.director')}</Typography>
                    {directors.length > 0 ? (
                      directors.map((contact) => (
                        <Typography key={contact.id}>{contact.contact_name}</Typography>
                      ))
                    ) : (
                      <Typography color='textSecondary'>
                        {t('businessProfile.noDirector')}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant='subtitle1'>{t('businessProfile.accountant')}</Typography>
                    {accountants.length > 0 ? (
                      accountants.map((contact) => (
                        <Typography key={contact.id}>{contact.contact_name}</Typography>
                      ))
                    ) : (
                      <Typography color='textSecondary'>
                        {t('businessProfile.noAccountant')}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant='subtitle1'>{t('businessProfile.secretary')}</Typography>
                    {secretaries.length > 0 ? (
                      secretaries.map((contact) => (
                        <Typography key={contact.id}>{contact.contact_name}</Typography>
                      ))
                    ) : (
                      <Typography color='textSecondary'>
                        {t('businessProfile.noSecretary')}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
