import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  createTheme,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

interface FormSelectProps {
  label?: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  options: { value: string; label: string }[];
  required?: boolean;
  placeholder?: string;
  defaultValue?: string | null;
  disabled?: boolean;
}

const ButtonDropDown: React.FC<FormSelectProps> = ({
  label,
  value,
  onChange,
  options,
  required = false,
  placeholder,
  defaultValue,
  disabled,
}) => {
  const labelId = label ? `${label.toLowerCase().replace(/\s+/g, '-')}-label` : null;
  const localTheme = createTheme({});

  return (
    <ThemeProvider theme={localTheme}>
      <FormControl fullWidth className='button-drop-down'>
        <InputLabel disabled={disabled} shrink id={labelId}>
          {label}
          {required && <span className='required'>*</span>}
        </InputLabel>
        <Select
          variant='outlined'
          labelId={labelId}
          value={value}
          onChange={onChange}
          displayEmpty
          disabled={disabled}
          defaultValue={defaultValue}
          label={required ? `${label}*` : label}
          renderValue={value !== '' ? undefined : () => placeholder || label}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default ButtonDropDown;
