import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DemoAuthFooter = () => {
  const { t } = useTranslation();

  const LINKS = {
    website: 'https://businesskitz.com/',
    contact: 'https://businesskitz.com/contact-us/',
    privaceProlicy: 'https://businesskitz.com/privacy-policy/',
    termsAndConditions: 'https://businesskitz.com.au/business-kitz-platform-terms-and-conditions/',
  };
  return (
    <Grid
      container
      className='demo-auth-footer position-relative bottom-0 start-0 w-100 py-2 text-center mt-4 justify-content-center align-items-center d-flex flex-column'
    >
      <Grid container item justifyContent='center' spacing={4}>
        <Grid item>
          <Link href={LINKS.contact} target='_blank' className='text-inherit text-decoration-none'>
            {t('footerLinks.support')}
          </Link>
        </Grid>
        <Grid item>
          <Link
            href={LINKS.privaceProlicy}
            target='_blank'
            className='text-inherit text-decoration-none'
          >
            {t('footerLinks.privacyPolicy')}
          </Link>
        </Grid>
        <Grid item>
          <Link
            href={LINKS.termsAndConditions}
            target='_blank'
            className='text-inherit text-decoration-none'
          >
            {t('footerLinks.termsAndConditions')}
          </Link>
        </Grid>
      </Grid>
      <Grid item sx={{ mt: 2 }}>
        <Link href={LINKS.website} target='_blank'>
          {t('footerLinks.businessKitz')}
        </Link>
      </Grid>
    </Grid>
  );
};

export default DemoAuthFooter;
