import React, { useState, useRef } from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Backdrop,
  Fade,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import {
  BusinessProfileContacts,
  BusinessProfileNotes,
  createBusinessProfileContact,
  createBusinessProfileNotes,
} from '../../slices/business-profile/businessProfileSlice';
import ContactNameInput from '../form-inputs/contact-name-input/ContactNameInput';
import EmailAddressInput from '../form-inputs/email-address-input/EmailAddresInput';
import PhoneInput from '../form-inputs/phone-input/PhoneInput';
import AddressInput from '../form-inputs/address-input/AddressInput';
import PositionInput from '../form-inputs/position-input/PositionInput';
import AuthorizedSignCheckbox from '../authorized-sign-checkbox/AuthorizedSignCheckbox';
import ShareholderCheckbox from '../share-holder-checkbox/ShareHolderCheckbox';
import HiringManagerCheckbox from '../hiring-manager-checkbox/HiringManagerCheckBox';
import LegalRepresentationCheckbox from '../legal-representation-checkbox/LegalRepresentationCheckbox';
import styles from './CreateNoteModal.module.scss';
import NoteInput from '../form-inputs/note-input/NoteInput';
import { useAppSelector } from '../hooks/useAppSelector';
import { selectUser } from '../../slices/user/userSlice';
import { NWClient } from '../../client/NWClient';
import { fetchEmployeeById, fetchEmployeeNotes } from '../../slices/employees/employeesSlice';

interface CreateNoteModalProps {
  companyId?: number;
  employeeId?: number;
  open: boolean;
  onClose: () => void;
}

export default function CreateNoteModal({
  companyId,
  employeeId,
  open,
  onClose,
}: CreateNoteModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLDivElement | null>(null);
  const currentUser = useAppSelector(selectUser);

  const [formData, setFormData] = useState({
    company: companyId,
    user: currentUser.id,
    note: '',
  });

  const [empFormData, setEmpFormData] = useState({
    employee_id: employeeId,
    note: '',
  });

  const handleChange =
    <K extends keyof typeof formData>(field: K) =>
    (value: (typeof formData)[K]) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

  const handleEmpChange =
    <K extends keyof typeof empFormData>(field: K) =>
    (value: (typeof empFormData)[K]) => {
      setEmpFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

  const handleCreate = () => {
    console.log(companyId);
    if (companyId) {
      dispatch(
        createBusinessProfileNotes({
          companyId: companyId,
          data: formData as Omit<BusinessProfileNotes, 'id'>,
        })
      )
        .unwrap()
        .finally(() => onClose());
    }

    if (employeeId) {
      NWClient.post(`employee/${employeeId}/notes`, empFormData, true).then((response) => {
        dispatch(fetchEmployeeNotes(employeeId));
      });
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Grid container className={styles.modal_container}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card ref={formRef}>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
              <Typography variant='h6' gutterBottom>
                {t('businessProfile.createNote')}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {companyId && (
                    <NoteInput
                      value={formData.note}
                      onChange={handleChange('note')}
                      disabled={false}
                    />
                  )}
                  {employeeId && (
                    <NoteInput
                      value={empFormData.note}
                      onChange={handleEmpChange('note')}
                      disabled={false}
                    />
                  )}
                </Grid>
              </Grid>

              <Stack
                direction='row'
                gap={'8px'}
                justifyContent='flex-end'
                sx={{ marginTop: '16px' }}
              >
                <Button
                  type='button'
                  variant='contained'
                  className='btn-default btn-action-basic btn-cancel'
                  onClick={onClose}
                >
                  {t('buttons.close')}
                </Button>
                <Button
                  type='button'
                  variant='contained'
                  className='btn-default btn-action-basic'
                  onClick={() => handleCreate()}
                >
                  {t('buttons.create')}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
}
