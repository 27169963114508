import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { HTMLTemplateModel } from '../../models/html.template.model';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import parse from 'html-react-parser';
import { NWClient } from '../../client/NWClient';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectUser } from '../../slices/user/userSlice';
import { AIContractModel } from '../../models/ai.contract.model';
import { ROUTES } from '../../common/constants/routes';
import { addDocumentContract } from '../../slices/document-contracts/documentContractsSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { DocumentTemplateModel } from '../../models/document.template.model';
import JoditEditor from 'jodit-react';
import { fetchDocuments, selectAllDocuments } from '../../slices/documents/documentsSlice';
import ContractGeneratorDialog from '../category/components/TemplatesTable/components/ContractGeneratorDialog';
import { PacmanLoaderBlock } from '../../common/pacman-loader';
import PlanUpgradingMessage from '../../common/plan-upgrading-message/PlanUpgradingMessage';

const TemplateItem = () => {
  const { id } = useParams();
  const { name } = useParams();
  const [params] = useSearchParams();
  const templateViewEditMode = params.get('templateViewEditMode');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectUser);
  const documents = useAppSelector(selectAllDocuments).filter((el) => el.user === currentUser.id);
  const dispatch = useAppDispatch();
  const [placeholders, setPlaceholders] = useState<{
    [key: string]: string;
  }>({});
  const [contractItem, setContractItem] = useState<AIContractModel>(null);
  const [templateItem, setTemplateItem] = useState<HTMLTemplateModel | DocumentTemplateModel>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPlaceholders, setLoadingPlaceholders] = useState<boolean>(true);
  const [loadingHTML, setLoadingHTML] = useState<boolean>(true);
  const [documentContent, setDocumentContent] = useState<string | null>(null);
  const [editable, setEditable] = useState<boolean>(false);
  const [generatorOpened, setGeneratorOpened] = useState<boolean>(false);
  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);
  const [dlHTML, setDLHTML] = useState<string>('');
  const [planUpgradingMessage, setPlanUpgradingMessage] = useState<boolean>(false);
  const editor = useRef(null);
  const editor2 = useRef(null);
  const templateTitle = templateItem
    ? templateItem.name
      ? templateItem.name
      : 'document_upload' in templateItem && 'updated' in templateItem && documents.length > 0
      ? documents.find((el) => el.id === templateItem.document_upload).name +
        '_' +
        templateItem.updated
      : ''
    : t('documentPage.headerDefault');
  const getTemplateHTML = () => {
    NWClient.get(
      `${name === 'company-templates' ? 'document-template' : 'document-library'}`,
      Number(id)
    )
      .then((res: HTMLTemplateModel) => {
        setTemplateItem(res);
        if (name !== 'company-templates') {
          NWClient.get('document-library-html', Number(id))
            .then((res: { id: number; html: string }) => {
              setDLHTML(res.html);
            })
            .catch((error) => {
              toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                theme: 'colored',
              });
            })
            .finally(() => {
              setLoadingHTML(false);
            });
        }
      })
      .catch((error) => {
        toast.error(error.message ? error.message : t('messages.errorOccurred'), {
          theme: 'colored',
        });
      })
      .finally(() => {
        name === 'company-templates' && setLoadingHTML(false);
      });
  };

  const handleGenerateTemplate = (data: {
    user: number;
    document_library?: number;
    document_template?: number;
    company_id: number;
    secondary_id: number;
    category: string;
  }) => {
    setAnalysisLoading(true);
    NWClient.post('ai-contract', data)
      .then(
        (res: {
          id: number;
          user: number;
          document_library?: number;
          document_template?: number;
          placeholder_matches: { [key: string]: string };
          category: string;
        }) => {
          toast.success(t('messages.contractGenerated'), { theme: 'colored' });
          window.location.href = `${ROUTES.DOCUMENT_LIBRARY}/${name}/${res.id}`;
        }
      )
      .catch((error) => {
        setAnalysisLoading(false);
        toast.error(error.message ? error.message : t('messages.errorOccurred'), {
          theme: 'colored',
        });
      });
  };

  const handleSave = () => {
    setLoading(true);
    const data = { ...templateItem };
    data.user = currentUser.id;
    name === 'company-templates'
      ? 'content' in data && (data.content = documentContent)
      : 'html' in data && (data.html = documentContent);
    NWClient.put(
      `${name === 'company-templates' ? 'document-template' : 'document-library'}`,
      Number(id),
      data,
      true
    )
      .then(() => {
        toast.success(t('messages.templateUpdated'), { theme: 'colored' });
        setEditable(true);
      })
      .catch((error) => {
        toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => setLoading(false));
  };

  const createDocumentFromTemplate = () => {
    setAnalysisLoading(true);
    const data = {
      user: currentUser.id,
      contract_generation: Number(id),
      name: templateItem
        ? templateItem.name
          ? templateItem.name + '_' + new Date().toISOString()
          : 'document_upload' in templateItem && documents.length > 0
          ? documents.find((el) => el.id === templateItem.document_upload).name +
            '_' +
            new Date().toISOString()
          : ''
        : t('documentPage.headerDefault'),
      category: contractItem?.category,
      /*placeholders: placeholders,*/
      /*html: templateItem?.html,*/
    };
    const contractData = { ...contractItem };
    delete contractData.id;
    contractData.placeholder_matches = placeholders;
    NWClient.put('ai-contract', Number(id), contractData, true)
      .then(() => {
        dispatch(addDocumentContract({ data }))
          .unwrap()
          .then(() => {
            toast.success(t('messages.contractCreated'), {
              theme: 'colored',
            });
            navigate(ROUTES.DOCUMENT_VAULT);
          })
          .catch((error) => {
            if (
              error.response?.data?.errors?.length > 0 &&
              t('messages.exceededPlanLimits').includes(error.response?.data?.errors[0])
            ) {
              setPlanUpgradingMessage(true);
            } else {
              toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                theme: 'colored',
              });
            }
          })
          .finally(() => setAnalysisLoading(false));
      })
      .catch((error) => {
        setAnalysisLoading(false);
        toast.error(error.message ? error.message : t('messages.errorOccurred'), {
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    if (documents.length === 0 && name === 'company-templates') {
      dispatch(fetchDocuments({ data: { user: currentUser.id } }));
    }
  }, []);

  useEffect(() => {
    if (templateViewEditMode) {
      getTemplateHTML();
    }
  }, []);

  useEffect(() => {
    if (id && !templateViewEditMode) {
      NWClient.get('ai-contract', Number(id))
        .then((res: AIContractModel) => {
          setPlaceholders(res.placeholder_matches);
          setContractItem(res);
          NWClient.get(
            `${name === 'company-templates' ? 'document-template' : 'document-library'}`,
            name === 'company-templates'
              ? Number(res.document_template)
              : Number(res.document_library)
          )
            .then((res: HTMLTemplateModel) => {
              setTemplateItem(res);
              if (name !== 'company-templates') {
                NWClient.get('document-library-html', Number(res.id))
                  .then((res: { id: number; html: string }) => {
                    setDLHTML(res.html);
                  })
                  .catch((error) => {
                    toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                      theme: 'colored',
                    });
                  })
                  .finally(() => {
                    setLoadingHTML(false);
                  });
              }
            })
            .catch((error) => {
              toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                theme: 'colored',
              });
            })
            .finally(() => {
              setLoadingHTML(false);
            });
        })
        .catch((error) => {
          toast.error(error.message ? error.message : t('messages.errorOccurred'), {
            theme: 'colored',
          });
        })
        .finally(() => {
          setLoadingPlaceholders(false);
        });
    }
  }, []);
  return (
    <>
      <h1 className='mb-4'>{templateTitle}</h1>
      <div>
        <div className='ms-0 me-0 buttons-row content-buttons align-items-center bg-white position-sticky p-4 d-flex flex-wrap justify-content-end'>
          {templateViewEditMode ? (
            <>
              <Button
                onClick={() => {
                  setGeneratorOpened(true);
                }}
                style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                type='button'
                role='button'
                variant='contained'
                size='medium'
              >
                {t('buttons.createContract')}
              </Button>
              {name !== 'uk' && name !== 'au' && (
                <Button
                  onClick={() => {
                    editable ? handleSave() : setEditable(true);
                  }}
                  style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                  type='button'
                  role='button'
                  variant='contained'
                  size='medium'
                >
                  {!editable ? t('buttons.edit') : t('buttons.save')}
                </Button>
              )}
            </>
          ) : (
            <Button
              onClick={() => createDocumentFromTemplate()}
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              type='button'
              role='button'
              variant='contained'
              size='medium'
            >
              {t('buttons.create')}
            </Button>
          )}
        </div>
        <div
          className='d-flex position-relative w-100'
          style={{
            border: '1px solid #C7C6C6',
            borderTop: 'none',
            borderRadius: '0 0 1.25rem 1.25rem',
          }}
        >
          {templateViewEditMode ? (
            <div
              className={`w-100 p-4 pt-0 bg-grey`}
              style={{ borderRadius: '0 0 1.25rem 1.25rem' }}
            >
              <JoditEditor
                ref={editor}
                config={{
                  readonly: !editable,
                  buttons:
                    'bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,copy,source,left,right,center,image,paste,selectall,hr,table,link,symbols,indent,print,outdent',
                }}
                value={
                  dlHTML
                    ? dlHTML
                    : templateItem
                    ? 'html' in templateItem
                      ? templateItem.html
                      : templateItem.content
                    : null
                }
                onBlur={(newContent) => setDocumentContent(newContent)} // preferred to use only this option to update the content for performance reasons
              />
            </div>
          ) : (
            <>
              <div
                className='p-2 w-50 overflow-auto flex-shrink-0 position-relative'
                style={{ height: 'calc(100vh - 280px)' }}
              >
                <table className='w-100 template-placeholders-table'>
                  <thead>
                    <tr>
                      <th>{t('labels.placeholder')}</th>
                      <th>{t('labels.value')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(placeholders).map((el, i) => (
                      <tr key={Object.keys(placeholders)[i]}>
                        <td>
                          <input type='text' disabled value={Object.keys(placeholders)[i]} />
                        </td>
                        <td>
                          <input
                            type='text'
                            onChange={(e) => {
                              const placeholdersCopy = { ...placeholders };
                              placeholdersCopy[el] = e.target.value;
                              setPlaceholders(placeholdersCopy);
                            }}
                            value={placeholders[el]}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {loadingPlaceholders ? <ProgressOverlay className='position-absolute' /> : null}
              </div>
              <div
                className='p-2 w-50 overflow-auto flex-shrink-0 position-relative no-editor-buttons'
                style={{ borderLeft: '1px solid #C7C6C6', height: 'calc(100vh - 280px)' }}
              >
                {templateItem ? (
                  <div>
                    <JoditEditor
                      ref={editor2}
                      config={{
                        readonly: true,
                        buttons: '',
                      }}
                      value={
                        dlHTML
                          ? dlHTML
                          : templateItem
                          ? 'html' in templateItem
                            ? templateItem.html
                            : templateItem.content
                          : null
                      }
                    />
                  </div>
                ) : null}
                {loadingHTML ? <ProgressOverlay className='position-absolute' /> : null}
              </div>
            </>
          )}
        </div>
      </div>
      {generatorOpened && (
        <ContractGeneratorDialog
          templateId={Number(id)}
          open={generatorOpened}
          handleClose={() => setGeneratorOpened(false)}
          templateTitle={templateTitle}
          handleGenerateTemplate={handleGenerateTemplate}
        />
      )}
      {analysisLoading ? <PacmanLoaderBlock loading={analysisLoading} /> : null}
      {loading ? <ProgressOverlay /> : null}
      {planUpgradingMessage && (
        <PlanUpgradingMessage
          open={planUpgradingMessage}
          text={t('messages.monthLimitReached')}
          handleClose={() => setPlanUpgradingMessage(false)}
        />
      )}
    </>
  );
};

export default TemplateItem;
