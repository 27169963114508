import React, { useState } from 'react';
import BusinessKitzLogo from '../../../static/assets/svg/business-kitz-logo.svg';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import LinkItem from './components/LinkItem/LInkItem';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ClarifyIcon from '../../../static/assets/svg/menu/clarify.svg';
import PackageIcon from '../../../static/assets/svg/menu/package-2.svg';
import ContractEditIcon from '../../../static/assets/svg/menu/contract-edit.svg';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import BusinessCenterIcon from '../../../static/assets/svg/menu/business-center.svg';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutIcon from '../../../static/assets/svg/menu/logout.svg';
import { ROUTES } from '../constants/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { NWClient } from '../../client/NWClient';
import { logout } from '../../slices/user/userSlice';
import { ProgressOverlay } from '../progress-overlay/ProgressOverlay';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { switchDesign } from '../design-switch/designSwitchSlice';

const MainNavigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pendingStatus, setPendingStatus] = useState(false);
  const handleLogOut = () => {
    NWClient.logOut().finally(() => {
      setPendingStatus(true);
      localStorage.removeItem('access_token');
      navigate(ROUTES.LOGIN);
      dispatch(logout());
      dispatch(switchDesign(false));
    });
  };
  const path = location.pathname;
  const [expandedView, setExpandedView] = useState<boolean>(false);
  return (
    <>
      {expandedView && (
        <div
          tabIndex={0}
          role='button'
          className='navigation-under-layer'
          onClick={() => setExpandedView(false)}
          onKeyDown={() => {
            return;
          }}
        />
      )}
      <nav className={'main-navigation' + (expandedView ? ' expanded-view' : '')}>
        {expandedView ? (
          <div className='logo-wrapper'>
            <BusinessKitzLogo />
          </div>
        ) : (
          <div
            role='button'
            tabIndex={0}
            className='menu-control-wrapper'
            onClick={() => setExpandedView(true)}
            onKeyDown={() => {
              return;
            }}
          >
            <MenuOutlinedIcon fontSize='large' />
          </div>
        )}
        <ul className='list-unstyled'>
          <li>
            <LinkItem
              url={ROUTES.INDEX}
              active={path === ROUTES.INDEX}
              expandedView={expandedView}
              linkText={t('menu.home')}
              onClick={() => setExpandedView(false)}
            >
              <CottageOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
        </ul>
        <div className='section-label'>{t('menu.documents')}</div>
        <ul className='list-unstyled with-border'>
          <li>
            <LinkItem
              url={ROUTES.DOCUMENT_LIBRARY}
              active={path.includes(ROUTES.DOCUMENT_LIBRARY)}
              expandedView={expandedView}
              linkText={t('menu.library')}
              onClick={() => setExpandedView(false)}
            >
              <AccountBalanceOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
          {process.env.ENV_TYPE !== 'PROD' && (
            <li>
              <LinkItem
                active={false}
                onClick={() => setExpandedView(false)}
                expandedView={expandedView}
                linkText={t('menu.workspace')}
              >
                <ClarifyIcon />
              </LinkItem>
            </li>
          )}
          <li>
            <LinkItem
              url={ROUTES.DOCUMENT_VAULT}
              active={path.includes(ROUTES.DOCUMENT_VAULT)}
              expandedView={expandedView}
              linkText={t('menu.vault')}
              onClick={() => setExpandedView(false)}
            >
              <PackageIcon />
            </LinkItem>
          </li>
          <li>
            <LinkItem
              url={ROUTES.DOCUMENTS}
              active={path.includes(ROUTES.DOCUMENTS)}
              expandedView={expandedView}
              linkText={t('menu.sign')}
              onClick={() => setExpandedView(false)}
            >
              <ContractEditIcon />
            </LinkItem>
          </li>
        </ul>
        <div className='section-label'>{t('menu.profiles')}</div>
        <ul className='list-unstyled'>
          <li>
            <LinkItem
              url={ROUTES.BUSINESS_PROFILE}
              active={path.includes(ROUTES.BUSINESS_PROFILE)}
              expandedView={expandedView}
              linkText={t('menu.business')}
              onClick={() => setExpandedView(false)}
            >
              <ApartmentOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
          <li>
            <LinkItem
              url={ROUTES.EMPLOYEE_PROFILES}
              active={path.includes(ROUTES.EMPLOYEE_PROFILES)}
              expandedView={expandedView}
              linkText={t('menu.employees')}
              onClick={() => setExpandedView(false)}
            >
              <BadgeOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
          <li>
            <LinkItem
              url={ROUTES.CUSTOMER_PROFILES}
              active={path.includes(ROUTES.CUSTOMER_PROFILES)}
              expandedView={expandedView}
              linkText={t('menu.customers')}
              onClick={() => setExpandedView(false)}
            >
              <SupportAgentOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
          <li>
            <LinkItem
              url={ROUTES.SUPPLIER_PROFILES}
              active={path.includes(ROUTES.SUPPLIER_PROFILES)}
              expandedView={expandedView}
              linkText={t('menu.suppliers')}
              onClick={() => setExpandedView(false)}
            >
              <BusinessCenterIcon />
            </LinkItem>
          </li>
        </ul>
        <ul className='list-unstyled mt-auto with-border'>
          <li>
            <LinkItem
              url={ROUTES.PRICING}
              active={path.includes(ROUTES.PRICING)}
              expandedView={expandedView}
              linkText={t('menu.plans')}
              onClick={() => setExpandedView(false)}
            >
              <SellOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
        </ul>
        <ul className='list-unstyled'>
          <li>
            <LinkItem
              url={ROUTES.SETTINGS}
              active={path.includes(ROUTES.SETTINGS)}
              expandedView={expandedView}
              linkText={t('menu.settings')}
              onClick={() => setExpandedView(false)}
            >
              <SettingsOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
          <li>
            {expandedView ? (
              <span
                className='main-nav-link'
                role='button'
                tabIndex={-1}
                onKeyUp={() => {
                  return;
                }}
                onClick={() => () => handleLogOut()}
              >
                <LogoutIcon />
                <span className='main-nav-link-text'>{t('menu.logout')}</span>
              </span>
            ) : (
              <Tooltip title={t('menu.logout')} placement='top' arrow>
                <span
                  className='main-nav-link'
                  role='button'
                  tabIndex={-1}
                  onKeyUp={() => {
                    return;
                  }}
                  onClick={() => handleLogOut()}
                >
                  <LogoutIcon />
                </span>
              </Tooltip>
            )}
          </li>
        </ul>
      </nav>
      {pendingStatus && <ProgressOverlay />}
    </>
  );
};

export default MainNavigation;
