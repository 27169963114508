import React, { useState, useEffect } from 'react';
import { NWClient } from '../../client/NWClient';
import FormErrorText from '../../common/form-error-text/form-error-text';
import { Helpers } from '../../common/helpers/helpers';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import DialogItem from '../../common/dialog-item/DialogItem';
import Button from '@mui/material/Button';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { selectAllCompanies, fetchCompanies } from '../../slices/companies/companiesSlice';
import { PRICE_IDS } from '../../common/constants/priceId';
import { EMAILS } from '../../common/constants/emails';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { selectUser } from '../../slices/user/userSlice';
import PlanPricingTable from './PlanPricingTable';

const PricingPage = () => {
  const currentUser = useAppSelector(selectUser);
  const { t } = useTranslation();
  const [pendingStatus, setPendingStatus] = useState(true);
  const [stripeProducts, setStripeProducts] = useState([]);
  const [errorTextArray, setErrorTextArray] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [redirectToCompanyDetails, setRedirectToCompanyDetails] = useState(false);
  const paymentLinkParams = `?prefilled_email=${currentUser?.email}&client_reference_id=${currentUser?.user_uuid}`;
  const dialogTitle = t('contactUs.header');
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectAllCompanies)[0];
  const priceIdEnv =
    process.env.ENV_TYPE && process.env.ENV_TYPE === 'PROD'
      ? PRICE_IDS.PRICE_ID_PROD
      : PRICE_IDS.PRICE_ID_TEST;

  const DialogContent = () => (
    <p>
      {t('messages.getInTouch')} <a href={'mailto:' + EMAILS.SALES_EMAIL}>{EMAILS.SALES_EMAIL}</a>.
    </p>
  );

  /*const getStripeProducts = () => {
    NWClient.list(token, 'stripe/product')
      .then((res) => {
        setStripeProducts(res);
      })
      .catch((error) => {
        Helpers.handleRequestError(error.response, setErrorTextArray, setPendingStatus);
      })
      .finally(() => {
        setPendingStatus(false);
      });
  };*/

  useEffect(() => {
    if (!company) {
      dispatch(fetchCompanies())
        .unwrap()
        .then((res) => {
          if (Array.isArray(res) && res.length === 0) {
            setRedirectToCompanyDetails(true);
          }
          setPendingStatus(false);
          //getStripeProducts();
        })
        .catch((error) => {
          Helpers.handleRequestError(error.response, setErrorTextArray, setPendingStatus);
        });
    } else {
      //getStripeProducts();
      setPendingStatus(false);
    }
  }, [dispatch, company]);

  return (
    <React.Fragment>
      <h1 className='mw-1100 text-center header-medium'>{t('pricing.header')}</h1>
      <div className='price-options-container d-flex align-items-start'>
        <PlanPricingTable
          redirectToCompanyDetails={redirectToCompanyDetails}
          paymentLinkParams={paymentLinkParams}
        />
        <div className='aside-block-wrapper flex-shrink-0 position-sticky'>
          <div className='aside-block-main-info'>
            <h2 className='aside-block-header'>{t('pricingOptionNames.enterprise')}</h2>
            <div className='aside-block-feature'>
              {t('pricingOptionsDescription.enterprise.description.tailoredToYourNeeds')}
            </div>
            <div className='aside-block-feature'>
              {t('pricingOptionsDescription.enterprise.description.assessmentFromTopTierLawyers')}
            </div>
            <div className='aside-block-feature'>
              {t('pricingOptionsDescription.enterprise.description.customIntegration')}
            </div>
            <div className='aside-block-feature'>
              {t('pricingOptionsDescription.enterprise.description.sso')}
            </div>
            <div className='mt-3 d-flex justify-content-center'>
              <a
                href={`mailto:${EMAILS.SALES_EMAIL}`}
                className='d-inline-flex justify-content-center align-items-center plan-link'
              >
                {t('buttons.letsTalk')}
              </a>
            </div>
          </div>
          <div className='mt-3 contact-message'>
            {t('contactUs.message')}{' '}
            <a href={'mailto:' + EMAILS.SUPPORT_EMAIL}>{EMAILS.SUPPORT_EMAIL}</a>.
          </div>
          <div className='mt-3 mb-2 d-flex justify-content-end'>
            <a
              className='d-inline-flex align-items-center justify-content-center plan-100-percent-link'
              href={
                redirectToCompanyDetails
                  ? ROUTES.COMPANY_CREATE + `?fromPricingPage=true&priceId=${priceIdEnv}`
                  : ROUTES.PRODUCT_PLAN_CONFIRMATION + `?priceId=${priceIdEnv}`
              }
            >
              {t('buttons.percents100')}
            </a>
          </div>
        </div>
      </div>

      <FormErrorText textCentered={true} errorTextArray={errorTextArray} />
      {showDialog && (
        <DialogItem
          title={dialogTitle}
          isErrorMessage={false}
          open={showDialog}
          handleClose={() => setShowDialog(false)}
        >
          <DialogContent />
        </DialogItem>
      )}
      {pendingStatus && <ProgressOverlay />}
    </React.Fragment>
  );
};
export default PricingPage;
