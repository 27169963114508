import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Card,
  CardContent,
  Grid,
  Typography,
  Chip,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileSaveIcon from '../../../static/assets/svg/file_save.svg';
import ContentCopyIcon from '../../../static/assets/svg/content_copy.svg';
import BrushIcon from '../../../static/assets/svg/brush.svg';
import VerticalDotsIcon from '../../../static/assets/svg/more_vert.svg';
import ContractEditIcon from '../../../static/assets/svg/contract_edit.svg';
import CalendarIcon from '../../../static/assets/svg/calendar_month.svg';
import { Document } from '../../pages/document-vault/components/document-list-vault/types';

import styles from './DocumentList.module.scss';
import DocumentsEditButton from '../../pages/BusinessProfile/components/DocumentsEditButton';
import { DocumentVaultItemModel } from '../../models/document.vault.item.model';
import { Helpers } from '../helpers/helpers';
import { useNavigate } from 'react-router-dom';
import { NWClient } from '../../client/NWClient';

interface DocumentListProps {
  documents: any[];
  isDocumentView?: boolean;
}
const fileTypeColors: Record<string, string> = {
  pdf: '#FFA490',
  doc: '#92D1FF ',
  docx: '#92D1FF ',
  xlsx: '#92E9C0',
  BK: '#DCC2FF',
};

const signedColors: Record<string, string> = {
  true: '#92E9C0',
  false: '#FDBDBD ',
};

const DocumentList = ({ documents, isDocumentView }: DocumentListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [documentsList, setDocumentsList] = useState<any[]>(documents);

  useEffect(() => {
    setDocumentsList(documents);
  }, [documents]);

  const getWorkflow = (doc: Document): string => {
    if ('dc_workflows' in doc) {
      return doc.dc_workflows.length > 0 ? doc.dc_workflows.join(', ') : '-';
    } else if ('du_workflows' in doc) {
      return doc.du_workflows.length > 0 ? doc.du_workflows.join(', ') : '-';
    }
  };

  const getDocumentType = (doc: Document): string => {
    if ('dc_types' in doc) {
      return doc.dc_types.length > 0 ? doc.dc_types.join(', ') : '-';
    } else if ('du_types' in doc) {
      return doc.du_types.length > 0 ? doc.du_types.join(', ') : '-';
    }
  };

  const navigateByRowClick = (id: number) => {
    navigate(`/document-vault/business/${id}`);
  };

  const handleDocumentDeletion = (id: number) => {
    setDocumentsList((prevDocuments) => prevDocuments.filter((doc) => doc.id !== id));
  };

  return (
    <Card style={{ borderRadius: '8px', boxShadow: 'none', backgroundColor: 'transparent' }}>
      <CardContent>
        <Grid container alignItems='center' spacing={1} p={1} gap={1}>
          <Typography
            variant='h6'
            fontWeight='medium'
            style={{ fontFamily: "'Medium Inter', sans-serif" }}
          >
            {t('businessProfile.documentCardHeader')}
          </Typography>
        </Grid>

        <TableContainer
          component={Paper}
          style={{ marginLeft: '-1rem', marginRight: '-1rem', width: 'auto', borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow className={styles.row_background}>
                <TableCell>
                  <span className={styles.header_span_color}>{t('documentsTable.name')}</span>
                </TableCell>
                <TableCell>
                  <span className={styles.header_span_color}>{t('documentsTable.fileType')}</span>
                </TableCell>
                <TableCell>
                  <span className={styles.header_span_color}>{t('documentsTable.workflow')}</span>
                </TableCell>
                <TableCell>
                  <span className={styles.header_span_color}>
                    {t('documentsTable.documentType')}
                  </span>
                </TableCell>
                {/* <TableCell align='right' style={{ padding: '0.625rem 1rem' }}>
                  <span className={styles.header_span_color}>{t('documentsTable.signed')}</span>
                </TableCell> */}
                <TableCell align='center'>
                  <span className={styles.header_span_color}>{t('documentsTable.date')}</span>
                </TableCell>

                <TableCell align='center'>
                  <span className={styles.header_span_color}>{t('documentsTable.actions')}</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentsList.map((doc, index) => (
                <TableRow key={index}>
                  <TableCell
                    className={styles.clickable}
                    onClick={() => navigateByRowClick(doc.id)}
                  >
                    <span className={styles.row_span_color}>{doc.name}</span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={styles.type_chip}
                      style={{
                        backgroundColor:
                          fileTypeColors[doc.contract_generation ? 'BK' : doc.du_filetype],
                        textTransform: 'uppercase',
                      }}
                    >
                      {doc.contract_generation ? 'BK' : doc.du_filetype}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={styles.row_span_color}>{getWorkflow(doc)}</span>
                  </TableCell>

                  <TableCell>
                    <span className={styles.row_span_color}>{getDocumentType(doc)}</span>
                  </TableCell>
                  {/* <TableCell align='right'>
                    <span
                      style={{
                        backgroundColor: doc.signed ? signedColors['true'] : signedColors['false'],
                      }}
                      className={styles.signed_chip}
                    >
                      {doc.signed ? 'Yes' : 'No'}
                    </span>
                  </TableCell> */}
                  <TableCell align='center'>
                    <CalendarIcon />
                    <span className={styles.row_span_color}>
                      {Helpers.timeStampToDateV2(doc.updated)}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <DocumentsEditButton
                      doc={doc}
                      onDocumentDeleted={() => handleDocumentDeletion(doc.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default DocumentList;
