import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React from 'react';

const DisclaimerText = () => {
  const { t } = useTranslation();
  const disclaimerKeys = [
    'beforeProceeding',
    'description',
    'tosDocAccess',
    'resourceOffering',
    'documentAccessibility',
    'docAccuracy',
    'disclaimers',
    'legalities',
    'redistribution',
    'intellectualProperty',
    'contactInfo',
  ];
  return (
    <>
      <p>
        <b>{t('disclaimerText.beforeProceeding')}</b>
      </p>
      <p>
        {t('disclaimerText.description')}
        <Link to='https://businesskitz.com.au/terms-and-conditions/' target='_blank'>
          {t('links.termsAndConditions')}
        </Link>
        {' and Business Kitz '}
        <Link to='https://businesskitz.com.au/privacy-policy/' target='_blank'>
          {t('links.privacyPolicy')}
        </Link>
      </p>
      {disclaimerKeys.slice(2).map((key) => (
        <p key={key}>{t(`disclaimerText.${key}`)}</p>
      ))}
    </>
  );
};

export default DisclaimerText;
