import React from 'react';
import { Grid } from '@mui/material';
import DashboardHeader from '../dashboard/DashboardHeader';
import { RolesGate } from '../../common/roles-gate';
import { GROUPS } from '../../common/constants/groups';
import { AdminDashboardPanel } from './components/AdminDashboardPanel';
import { EmployeeDashboardPanel } from './components/EmployeeDashboardPanel';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/user/userSlice';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import DemoHome from '../demo-home/DemoHome';
import HomePage from '../demo-home/HomePage';
import { useAppSelector } from '../../common/hooks/useAppSelector';

const TemplateDashboard = (): JSX.Element => {
  const token = localStorage.getItem('access_token');
  const currentUser = useSelector(selectUser);
  const designUpdated = useAppSelector((state) => state.designUpdated.designUpdated);

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }
  return (
    <>
      {!designUpdated ? (
        <React.Fragment>
          <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
            className='dashboard'
          >
            <DashboardHeader />
            <RolesGate group={GROUPS.COMPANY_ADMIN}>
              <AdminDashboardPanel currentUser={currentUser} />
            </RolesGate>
            <RolesGate group={GROUPS.COMPANY_EMPLOYEE}>
              <EmployeeDashboardPanel />
            </RolesGate>
          </Grid>
        </React.Fragment>
      ) : (
        <HomePage />
      )}
    </>
  );
};

export default TemplateDashboard;
