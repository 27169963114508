import React, { useEffect, useState } from 'react';
import { Box, Typography, Tabs, Tab, Grid } from '@mui/material';
import styles from './DemoDocumentView.module.scss';
import DocumentDetails from './components/document-details/DocumentDetails';
import DocumentContent from './components/document-content/DocumentContent';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import AiSummaryDetails from './components/ai-summary-details/AiSummaryDetails';

const DemoDocumentView: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(switchDesign(true));
  }, [dispatch]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Grid container className={styles.header}>
        <Grid item xs={6}>
          <Typography variant='h5' component='h1' className={styles.title}>
            Document Vault
          </Typography>
          <Box className={styles.breadcrumbs}>
            <Typography variant='body2'>Document vault</Typography>
            <Typography variant='body2' className={styles.separator}>
              ›
            </Typography>
            <Typography variant='body2'>Document view</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {activeTab === 0 ? (
          <>
            <Grid item xs={6} className={styles.gridItem} sx={{ ml: 3 }}>
              <Tabs value={activeTab} onChange={handleTabChange} className={styles.tabs}>
                <Tab
                  label='Document details'
                  className={`${styles.tab} ${activeTab === 0 ? styles.activeTab : ''}`}
                />
                <Tab label='Document content' className={styles.tab} />
              </Tabs>
              <Box className={styles.tabContent}>
                <DocumentDetails />
              </Box>
            </Grid>
            <Grid item xs={5.7} sx={{ mt: -2.94 }}>
              <AiSummaryDetails />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} className={styles.gridItem} sx={{ ml: 3 }}>
            <Tabs value={activeTab} onChange={handleTabChange} className={styles.tabs}>
              <Tab label='Document details' className={styles.tab} />
              <Tab
                label='Document content'
                className={`${styles.tab} ${activeTab === 1 ? styles.activeTab : ''}`}
              />
            </Tabs>
            <Box className={styles.tabContent}>
              <DocumentContent />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DemoDocumentView;
