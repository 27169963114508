import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IndustryTypeInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

export default function IndustryTypeInput({ value, onChange, disabled }: IndustryTypeInputProps) {
  const { t } = useTranslation();

  return (
    <TextField
      id='industry-type-input'
      label={t('formInputs.industryTypeInputLabel')}
      variant='outlined'
      fullWidth
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}
