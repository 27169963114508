import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { NWClient } from '../../../../client/NWClient';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants/routes';
import { useSearchParams } from 'react-router-dom';

export const NoCompanyHandler = ({
  children,
}: {
  children?: ReactNode | undefined;
}): ReactElement => {
  const [searchParams] = useSearchParams();
  const backUrl = searchParams.get('backUrl');
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    NWClient.getCurrentCompany()
      .then((res) => {
        if (Array.isArray(res) ? res.length > 0 : Boolean(res)) {
          setCompany(Array.isArray(res) ? res[0] : res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return null;
  }

  if (!company?.id) {
    return <>{children}</>;
  }

  if (company && backUrl) {
    return <Navigate to={backUrl} replace />;
  }

  return <Navigate to={ROUTES.INDEX} replace />;
};
