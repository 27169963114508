import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';

import BusinessNameInput from '../form-inputs/business-name-input/BusinessNameInput';
import BusinessEmailAddressInput from '../form-inputs/business-email-address-input/BusinessEmailAddressInput';
import RegisteredAddressInput from '../form-inputs/registered-address-input/RegisteredAddressInput';
import PhysicalAddressInput from '../form-inputs/physical-address-input/PhysicalAddressInput';
import BusinessPhoneNumberInput from '../form-inputs/bussiness-phone-number-input/BusinessPhoneNumberInput';
import CountryInput from '../form-inputs/country-input/CountryInput';
import PostalAddressInput from '../form-inputs/postal-address-input/PostalAddressInput';
import RegistrationNumberInput from '../form-inputs/registration-number-input/RegistrationNumberInput';
import AccountCircleIcon from '../../../static/assets/svg/account_circle.svg';
import WebIcon from '../../../static/assets/svg/captive_portal.svg';
import InstagramIcon from '../../../static/assets/svg/instagram.svg';
import BrushIcon from '../../../static/assets/svg/brush.svg';
import OrganizationIcon from '../../../static/assets/svg/organization.svg';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

import {
  fetchBusinessProfileById,
  selectBusinessProfileStatus,
  selectBusinessProfileDetails,
} from '../../slices/business-profile/businessProfileSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { useQualificationsCardForm } from './useQualificationsCardForm';
import styles from './QualificationsCard.module.scss';
import ConfirmModal from '../confirm-modal/ConfirmModal';
import TaxRegistrationNumberInput from '../form-inputs/registration-number-input/TaxRegostrationNumberInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import AddSocialsModal from '../add-socials-modal/AddSocialsModal';
// import { SocialLink } from '../social-media-form/SocialMediaForm';
import { useAppSelector } from '../hooks/useAppSelector';
import { Company } from '../../models/company.model';
import { Employee } from '../../models/employee.model';
import { Helpers } from '../helpers/helpers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

type QualificationsCardProps = {
  simplified?: boolean;
  employee: Partial<Employee>;
};

export default function QualificationsCard({ simplified, employee }: QualificationsCardProps) {
  const { t } = useTranslation();
  const isLoading = false;
  const {
    formData,
    handleChange,
    saveChanges,
    isEdited,
    toggleEditing,
    formRef,
    showModal,
    confirmExit,
  } = useQualificationsCardForm(employee);

  const disabledStyles = {
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black', // Change text color
      WebkitTextFillColor: 'black', // Override browser autofill styles
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: '#f0f0f0', // Change background
      borderColor: 'red', // Change border color
    },
  };

  return (
    <Grid container className='inputs-editable'>
      <Grid item xs={12}>
        <Card className={`${styles.card} ${isEdited ? styles.card_border : ''}`} ref={formRef}>
          <CardContent className={isLoading ? styles.card_content_min_height : ''}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container alignItems='center' flexWrap='nowrap' p={1}>
                  <Grid item xs={11} container alignItems='center' gap={1}>
                    <Grid sx={{ marginBottom: '0.2rem' }} item>
                      <SchoolOutlinedIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant='h6'>Qualifications</Typography>{' '}
                      {/*TODO : Add translation */}
                    </Grid>
                  </Grid>
                  <Grid item xs={1} container justifyContent='flex-end'>
                    {!isEdited ? (
                      <Tooltip className='flex-shrink-0' title={t('buttons.edit')} placement='top'>
                        <IconButton onClick={toggleEditing}>
                          <BrushIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Button
                        type='submit'
                        className='btn-default btn-action-basic'
                        role='button'
                        disabled={!isEdited}
                        variant='contained'
                        size='large'
                        onClick={saveChanges}
                      >
                        {t('buttons.save')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={formData.degree ?? ''}
                          label={t('labels.degreeOrCertificate')}
                          onChange={(e) => {
                            handleChange('degree')(e.target.value);
                          }}
                          disabled={!isEdited}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={formData.university_name ?? ''}
                          label={t('labels.institutionName')}
                          onChange={(e) => {
                            handleChange('university_name')(e.target.value);
                          }}
                          disabled={!isEdited}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label={t('labels.startDate')}
                                value={formData.start_date ? dayjs(formData.start_date) : null}
                                onChange={(newValue) => {
                                  // Format date as YYYY-MM-DD for the form data
                                  formData.start_date = newValue
                                    ? newValue.format('YYYY-MM-DD')
                                    : null;
                                  handleChange('start_date')(formData.start_date);
                                }}
                                disabled={!isEdited}
                                format='DD/MM/YYYY'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    placeholder: 'DD/MM/YYYY',
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label={t('labels.endDate')}
                                value={formData.end_date ? dayjs(formData.end_date) : null}
                                onChange={(newValue) => {
                                  // Format date as YYYY-MM-DD for the form data
                                  formData.end_date = newValue
                                    ? newValue.format('YYYY-MM-DD')
                                    : null;
                                  handleChange('end_date')(formData.end_date);
                                }}
                                disabled={!isEdited}
                                format='DD/MM/YYYY'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    placeholder: 'DD/MM/YYYY',
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {
                  <Grid container className={styles.container_margin_top} alignItems='center'>
                    <Grid item>
                      {/* <Tooltip title={t('businessProfile.addSocials')} placement='top'>
                        <IconButton
                          onClick={toogleEditingSocialModal}
                          className={styles.add_social_button}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip> */}
                    </Grid>
                  </Grid>
                }
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
      <ConfirmModal open={showModal} onClose={saveChanges} onConfirm={confirmExit} />
      {/* <AddSocialsModal
        open={showAddSocialModal}
        onClose={saveChangesSocialForm}
        onConfirm={toogleEditingSocialModal}
      /> */}
    </Grid>
  );
}
