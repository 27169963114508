import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DocumentLibraryIcon from '../../../static/assets/svg/signup/document_library.svg';
import DocumentSignIcon from '../../../static/assets/svg/signup/document_sign.svg';
import DocumentVaultIcon from '../../../static/assets/svg/signup/document_vault.svg';
import CRMIcon from '../../../static/assets/svg/signup/CRM_made_simple.svg';
import IntegratedAiIcon from '../../../static/assets/svg/signup/integrated_AI.svg';
import FeatureItem from '../feature-item/feature-item';

const FEATURES_LIST = [
  {
    id: 'docLibraryTitle',
    labelText: 'docLibraryTitle',
    description: 'docLibraryDescription',
    icon: <DocumentLibraryIcon />,
  },
  {
    id: 'docSignTitle',
    labelText: 'docSignTitle',
    description: 'docSignDescription',
    icon: <DocumentSignIcon />,
  },
  {
    id: 'docVaultTitle',
    labelText: 'docVaultTitle',
    description: 'docVaultDescription',
    icon: <DocumentVaultIcon />,
  },
  {
    id: 'crmTitle',
    labelText: 'crmTitle',
    description: 'crmDescription',
    icon: <CRMIcon />,
  },
  {
    id: 'integratedAiTitle',
    labelText: 'integratedAiTitle',
    description: 'integratedAiDescription',
    icon: <IntegratedAiIcon />,
  },
];

const FeatureItems = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={4}
      className='d-flex justify-content-center align-items-center feature-items'
    >
      {FEATURES_LIST.map((featureItem) => (
        <FeatureItem
          key={featureItem.id}
          icon={featureItem.icon}
          labelText={featureItem.labelText}
          description={featureItem.description}
        />
      ))}
    </Grid>
  );
};

export default FeatureItems;
