import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import {
  selectAllDocumentContracts,
  fetchDocumentContracts,
} from '../../../slices/document-contracts/documentContractsSlice';
import { fetchEmployees } from '../../../slices/employees/employeesSlice';
import { fetchSuppliers } from '../../../slices/suppliers/suppliersSlice';
import { fetchCustomers } from '../../../slices/customers/customersSlice';
import { selectAllDocuments, fetchDocuments } from '../../../slices/documents/documentsSlice';
import { selectUser } from '../../../slices/user/userSlice';
import { DOCUMENT_CATEGORIES } from '../types';
import { selectAllCompanies } from '../../../slices/companies/companiesSlice';
import { fetchBusinessProfileContactById } from '../../../slices/business-profile/businessProfileSlice';

const useDocuments = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectAllCompanies)[0];
  const documentContracts = useAppSelector(selectAllDocumentContracts);
  const documentUploads = useAppSelector(selectAllDocuments);
  const documentContractsLoading = useAppSelector((state) => state.documentContracts.status);
  const documentUploadsLoading = useAppSelector((state) => state.documents.status);
  const employeesLoading = useAppSelector((state) => state.employees.status);
  const customersLoading = useAppSelector((state) => state.customers.status);
  const suppliersLoading = useAppSelector((state) => state.suppliers.status);
  const businessProfileLoading = useAppSelector((state) => state.businessProfile.status);
  const currentUser = useAppSelector(selectUser);

  const sortByDate = (a: any, b: any) => {
    return Number(new Date(b.created)) - Number(new Date(a.created));
  };
  const documents = [...documentContracts, ...documentUploads]
    .slice()
    .sort((a, b) => sortByDate(a, b));
  const [activeTab, setActiveTab] = useState<DOCUMENT_CATEGORIES>('All');
  const handleTabChange = (tab: DOCUMENT_CATEGORIES) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (company) {
      dispatch(fetchEmployees({ company_id: company.id }));
      dispatch(fetchSuppliers({ company_id: company.id }));
      dispatch(fetchCustomers({ company_id: company.id }));
      dispatch(fetchBusinessProfileContactById({ id: company.id }));
    }
  }, []);

  useEffect(() => {
    dispatch(
      fetchDocuments({
        data: {
          user: currentUser.id,
          category: activeTab === 'All' ? '' : activeTab.toLowerCase(),
        },
      })
    );
    dispatch(
      fetchDocumentContracts({
        data: {
          user: currentUser.id,
          category: activeTab === 'All' ? '' : activeTab.toLowerCase(),
        },
      })
    );
  }, [currentUser, activeTab]);

  return {
    documentContracts,
    documentUploads,
    documents,
    documentContractsLoading,
    documentUploadsLoading,
    employeesLoading,
    suppliersLoading,
    customersLoading,
    businessProfileLoading,
    activeTab,
    handleTabChange,
  };
};

export { useDocuments };
