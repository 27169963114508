// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DlgcPvVwcW06FHKfssYW{margin-top:16px}.zvs3iyGcqFlBG__RoT2D{display:flex;align-items:center;justify-content:center;position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.5)}`, "",{"version":3,"sources":["webpack://./src/app/common/message-modal/MessageModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA","sourcesContent":[".checkbox_container {\n  margin-top: 16px;\n}\n\n.modal_container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); // Fondo oscuro semi-transparente\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox_container": `DlgcPvVwcW06FHKfssYW`,
	"modal_container": `zvs3iyGcqFlBG__RoT2D`
};
export default ___CSS_LOADER_EXPORT___;
