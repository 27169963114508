import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { RootState } from '../../store';

export const fetchDisclaimerInfo = createAsyncThunk('user/fetchDisclaimerInfo', async () => {
  const response = await NWClient.DisclaimerCheck();
  return true;
});

export const acceptDisclaimer = createAsyncThunk('user/acceptDisclaimer', async () => {
  const response = await NWClient.DisclaimerAccept();
  return true;
});

export interface DisclaimerInfoState {
  disclaimerChecked: boolean;
  status: string;
  error: string;
}

const initialState: DisclaimerInfoState = {
  disclaimerChecked: false,
  status: '',
  error: '',
};

const disclaimerInfoSlice = createSlice({
  name: 'disclaimerInfo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDisclaimerInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDisclaimerInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.disclaimerChecked = action.payload;
      })
      .addCase(fetchDisclaimerInfo.rejected, (state) => {
        state.disclaimerChecked = false;
        state.status = 'failed';
      })
      .addCase(acceptDisclaimer.pending, (state) => {
        state.status = 'loadingAccepting';
      })
      .addCase(acceptDisclaimer.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.disclaimerChecked = action.payload;
      })
      .addCase(acceptDisclaimer.rejected, (state) => {
        state.disclaimerChecked = false;
        state.status = 'failed';
      });
  },
});

export default disclaimerInfoSlice.reducer;

export const selectDisclaimerChecked = (state: RootState) => state.disclaimerInfo.disclaimerChecked;
