import { useState, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import {
  fetchBusinessProfileById,
  selectBusinessProfileDetails,
  updateBusinessProfileEntry,
  BusinessProfileDetails,
} from '../../slices/business-profile/businessProfileSlice';
import { useAppSelector } from '../hooks/useAppSelector';
import { NWClient } from '../../client/NWClient';
import { Employee } from '../../models/employee.model';

export function useQualificationsCardForm(employee: Partial<Employee>) {
  const [isEdited, setIsEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [showAddSocialModal, setAddSocialShowModal] = useState(false);
  const formRef = useRef<HTMLDivElement | null>(null);

  const [formData, setFormData] = useState<any>({
    degree: employee.qualifications?.[0]?.degree,
    university_name: employee.qualifications?.[0]?.university_name,
    start_date: employee.qualifications?.[0]?.start_date,
    end_date: employee.qualifications?.[0]?.end_date,
  });

  useEffect(() => {
    if (employee) {
      setFormData({
        ...employee.qualifications[0],
      });
    }
  }, [employee]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isEdited && formRef.current && !formRef.current.contains(event.target as Node)) {
        const targetElement = event.target as HTMLElement;
        if (event.target instanceof HTMLElement) {
          const ignoredElements = ['BUTTON', 'INPUT', 'TEXTAREA', 'SELECT', 'LABEL', 'MENU-ITEM'];
          // if (ignoredElements.includes(event.target.tagName)) return;
        }

        if (
          targetElement.closest('.MuiDatePicker-root') || // Ignore clicks on DatePicker
          targetElement.closest('.MuiPickersPopper-root') || // Ignore clicks on date picker popper
          targetElement.closest('.MuiLocalizationProvider-root') || // Ignore clicks on LocalizationProvider
          targetElement.closest('.MuiDialog-root') // Ignore clicks on dialog elements
        ) {
          return;
        }

        setTimeout(() => {
          setShowModal(true);
        }, 0);
      }
    };
    if (isEdited) document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEdited]);

  const handleChange =
    <K extends keyof any>(field: K) =>
    (value: any[K]) => {
      setFormData((prev: any) => ({
        ...prev,
        [field]: value,
      }));
    };

  const handleCompanyNameChange = (value: string) => {
    setCompanyName(value);
  };

  const saveChanges = () => {
    if (!employee?.id) return;
    if (employee.qualifications.length === 0) {
      NWClient.post(`employee/${employee.id}/qualifications`, formData, true);
    } else {
      NWClient.patch(
        `employee/${employee.id}/qualifications/${employee.qualifications[0].id}/`,
        null,
        formData
      );
    }
    setIsEdited(false);
    setShowModal(false);
  };

  const toggleEditing = () => {
    setIsEdited((prev) => !prev);
  };

  const toogleEditingSocialModal = () => {
    setAddSocialShowModal((prev) => !prev);
  };

  const confirmExit = () => {
    setIsEdited(false);
    setShowModal(false);
  };

  return {
    formData,
    handleChange,
    handleCompanyNameChange,
    saveChanges,
    isEdited,
    setIsEdited,
    toggleEditing,
    formRef,
    showModal,
    setShowModal,
    confirmExit,
    showAddSocialModal,
    setAddSocialShowModal,
    toogleEditingSocialModal,
  };
}
