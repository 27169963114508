import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface EmailAddressInputInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  submitting?: boolean;
}

export default function EmailAddressInputInput({
  value,
  onChange,
  disabled,
  submitting,
}: EmailAddressInputInputProps) {
  const { t } = useTranslation();
  const [isTouched, setIsTouched] = useState(false);

  const handleBlur = () => {
    setIsTouched(true);
  };
  return (
    <TextField
      error={(submitting && value === '') || (isTouched && value === '')}
      id='email-address-input'
      label={`${t('formInputs.emailAddressInputLabel')} *`}
      variant='outlined'
      type='email'
      fullWidth
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      onBlur={handleBlur}
      disabled={disabled}
    />
  );
}
