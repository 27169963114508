import React from 'react';
import { Modal, Grid, Card, CardContent, Typography, Button, Tooltip, Stack } from '@mui/material';
import styles from './ConfirmModal.module.scss';
import { useTranslation } from 'react-i18next';

interface ConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Grid container className={styles.modal_container}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
              <Typography variant='body1' className={styles.modal_text}>
                {t('confirmModal.textAlert')}
              </Typography>
              <Stack
                direction='row'
                gap={'8px'}
                justifyContent='flex-end'
                className={styles.modal_actions}
              >
                <Tooltip
                  className='flex-shrink-0'
                  title={t('confirmModal.tooltip')}
                  placement='bottom'
                >
                  <Button
                    onClick={onConfirm}
                    variant='contained'
                    className='btn-default btn-action-basic btn-cancel'
                  >
                    {t('confirmModal.discard')}
                  </Button>
                </Tooltip>
                <Button
                  onClick={onClose}
                  variant='contained'
                  className='btn-default btn-action-basic'
                >
                  {t('confirmModal.save')}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmModal;
