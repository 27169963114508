import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import {
  DocumentVaultItemModel,
  DocumentVaultUploadData,
  DocumentVaultDeleteData,
  DocumentVaultSearchData,
  DocumentVaultUpdateProfileLabelsData,
} from '../../models/document.vault.item.model';
import { RootState } from '../../store';

export const fetchDocuments = createAsyncThunk(
  'documents/fetchDocuments',
  async ({ data }: DocumentVaultSearchData) => {
    const response = await NWClient.list('document-upload', data);
    return response;
  }
);

export const uploadDocument = createAsyncThunk(
  'documents/uploadDocument',
  async ({ data }: DocumentVaultUploadData) => {
    const response = await NWClient.post('document-upload', data, true);
    return response;
  }
);

export const updateDocumentProfileItems = createAsyncThunk(
  'documents/updateDocumentProfileItems',
  async ({ id, data }: DocumentVaultUpdateProfileLabelsData) => {
    const response = await NWClient.patch(`document-upload/${id}/`, null, data);
    return response;
  }
);

export const deleteDocument = createAsyncThunk(
  'documents/deleteDocument',
  async ({ id, userId }: DocumentVaultDeleteData) => {
    const response = await NWClient.delete('document-upload', id, null, { user: userId });
    return response;
  }
);

export interface DocumentsState {
  documentsList: DocumentVaultItemModel[];
  status: string;
  error: string;
}

const documentsArray: DocumentVaultItemModel[] = [];
const initialState: DocumentsState = {
  documentsList: documentsArray,
  status: 'idle',
  error: '',
};
const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (Array.isArray(action.payload)) {
          state.documentsList = [...action.payload];
        }
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(uploadDocument.pending, (state) => {
        state.status = 'uploading';
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documentsList.push(action.payload as DocumentVaultItemModel);
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateDocumentProfileItems.pending, (state) => {
        state.status = 'updating';
      })
      .addCase(updateDocumentProfileItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.documentsList.findIndex((doc) => doc.id === action.meta.arg.id);
        state.documentsList[index] = { ...(action.payload as DocumentVaultItemModel) };
      })
      .addCase(updateDocumentProfileItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteDocument.pending, (state) => {
        state.status = 'deleting';
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documentsList = [...state.documentsList].filter((el) => el.id !== action.meta.arg.id);
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default documentsSlice.reducer;

export const selectAllDocuments = (state: RootState) => state.documents.documentsList;
