import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ShareholderCheckboxProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled: boolean;
}

export default function ShareholderCheckbox({
  value,
  onChange,
  disabled,
}: ShareholderCheckboxProps) {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Checkbox
          id='shareholder-checkbox'
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
      }
      label={t('formInputs.shareholderInputLabel')}
    />
  );
}
