import React, { ChangeEvent, useState, useEffect, useId } from 'react';
import { SupplierContact } from '../../../models/supplier.contact.model';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { NWClient } from '../../../client/NWClient';
import { toast } from 'react-toastify';
import { CircularProgress, Divider, FormHelperText, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { ProgressOverlay } from '../../../common/progress-overlay/ProgressOverlay';
import { fetchCompanies } from '../../../slices/companies/companiesSlice';
import Checkbox from '@mui/material/Checkbox';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogItem from '../../../common/dialog-item/DialogItem';

const SupplierContactItem = (props: {
  contactInfo?: SupplierContact;
  editMode?: boolean;
  onSave?: () => void;
}) => {
  const {
    id,
    supplier_id,
    job_title,
    first_name,
    last_name,
    phone_number,
    authorized_rep,
    email_address,
    postal_address,
  } = props.contactInfo;

  const editMode = props.editMode;
  const checkboxId = useId();
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string | null>('');
  const [email, setEmail] = useState<string | null>('');
  const [address, setAddress] = useState<string | null>('');
  const [phoneNumber, setPhoneNumber] = useState<string | null>('');
  const [jobTitle, setJobTitle] = useState<string | null>('');
  const [authorizedRep, setAuthorizedRep] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [deleteContactMessageVisible, setDeleteContactMessageVisible] = useState<boolean>(false);
  const [formDataChanged, setFormDataChanged] = useState<boolean>(false);
  const { t } = useTranslation();

  const setContactInfo = () => {
    setFirstName(first_name ? first_name : '');
    setLastName(last_name ? last_name : '');
    setEmail(email_address ? email_address : '');
    setAddress(postal_address ? postal_address : '');
    setPhoneNumber(phone_number ? phone_number : '');
    setJobTitle(job_title ? job_title : '');
    setAuthorizedRep(authorized_rep !== undefined ? authorized_rep : false);
  };

  const handleInputsPossibleErrors = (propertyName: string) => {
    if (fieldsErrors && fieldsErrors[propertyName]) {
      const obj = { ...fieldsErrors };
      delete obj[propertyName];
      setFieldsErrors(obj);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      postal_address: address,
      phone_number: phoneNumber,
      job_title: jobTitle,
      authorized_rep: authorizedRep,
      supplier_id: supplier_id,
    };
    id
      ? NWClient.put('supplier_profile_contacts', id, data, true, true)
          .then(() => {
            toast.success(t('messages.contactCreated'), { theme: 'colored' });
            dispatch(fetchCompanies()).then(() => {
              setFormDataChanged(false);
              setPendingStatus(false);
            });
          })
          .catch((error) => {
            setFieldsErrors(error.response.data);
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          })
      : NWClient.post('supplier_profile_contacts', data, true)
          .then(() => {
            toast.success(t('messages.contactCreated'), { theme: 'colored' });
            dispatch(fetchCompanies()).then(() => {
              if (props.onSave) {
                props.onSave();
              }
              setPendingStatus(false);
              setFormDataChanged(false);
            });
          })
          .catch((error) => {
            setFieldsErrors(error.response.data);
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
  };

  const deleteContact = () => {
    setPendingStatus(true);
    NWClient.delete('supplier_profile_contacts', id)
      .then(() => {
        toast.success(t('messages.contactDeleted'), { theme: 'colored' });
        dispatch(fetchCompanies())
          .unwrap()
          .then(() => {
            setPendingStatus(false);
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      })
      .catch(() => {
        setPendingStatus(false);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  useEffect(() => {
    setContactInfo();
  }, [editMode]);

  return (
    <div className='supplier-contact-item'>
      {loading ? (
        <Grid container justifyContent='center'>
          <ProgressOverlay className='position-relative' />
          {/*<CircularProgress/>*/}
        </Grid>
      ) : (
        <>
          <Grid>
            <div className='setting-panel-body'>
              <ValidatorForm onSubmit={handleSubmit}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.firstName')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFirstName(e.target.value);
                        setFormDataChanged(true);
                      }}
                      name='firstName'
                      value={firstName}
                      disabled={id ? !editMode : false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.lastName')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setLastName(e.target.value);
                        setFormDataChanged(true);
                      }}
                      name='lastName'
                      value={lastName}
                      disabled={id ? !editMode : false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.email')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setEmail(e.target.value);
                        setFormDataChanged(true);
                      }}
                      name='email'
                      value={email}
                      disabled={id ? !editMode : false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.postalAddress')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setAddress(e.target.value);
                        setFormDataChanged(true);
                        handleInputsPossibleErrors('address');
                      }}
                      name='address'
                      value={address}
                      disabled={id ? !editMode : false}
                    />
                    {Boolean(fieldsErrors?.address) && (
                      <FormHelperText error={true}>
                        {fieldsErrors?.address.join('.')}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.phoneNumber')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setPhoneNumber(e.target.value);
                        setFormDataChanged(true);
                      }}
                      name='phoneNumber'
                      value={phoneNumber}
                      disabled={id ? !editMode : false}
                    />
                    {Boolean(fieldsErrors?.phone_number) && (
                      <FormHelperText error={true}>
                        {fieldsErrors?.phone_number.join('.')}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.jobTitle')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setJobTitle(e.target.value);
                        setFormDataChanged(true);
                      }}
                      name='jobTitle'
                      value={jobTitle}
                      disabled={id ? !editMode : false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className='checkbox-row d-flex align-items-start'>
                      <Checkbox
                        id={checkboxId}
                        checked={authorizedRep}
                        onChange={(event) => {
                          setAuthorizedRep(event.target.checked);
                          setFormDataChanged(true);
                        }}
                        disabled={id ? !editMode : false}
                      />
                      <label htmlFor={checkboxId}>{t('labels.authorizedRepresentative')}</label>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className='buttons-row' justifyContent='flex-end'>
                  {id && editMode && (
                    <IconButton
                      aria-label='delete'
                      style={{ marginLeft: '0.5rem' }}
                      onClick={() => setDeleteContactMessageVisible(true)}
                    >
                      <Tooltip title={t('messages.deleteContact')} placement='top' arrow>
                        <DeleteOutlineOutlinedIcon fontSize='large' style={{ marginRight: 0 }} />
                      </Tooltip>
                    </IconButton>
                  )}
                  {editMode && (
                    <Button
                      style={{ marginLeft: '0.5rem' }}
                      type='submit'
                      role='button'
                      variant='contained'
                      size='large'
                      disabled={id && !formDataChanged}
                    >
                      {id ? t('buttons.updateContact') : t('buttons.saveContact')}
                    </Button>
                  )}
                </Grid>
                {pendingStatus ? <ProgressOverlay /> : ''}
              </ValidatorForm>
            </div>
          </Grid>
          <Divider color='#000' />
        </>
      )}
      <DialogItem
        isErrorMessage={false}
        open={deleteContactMessageVisible}
        title={
          deleteContactMessageVisible
            ? t('dialogTitles.deleteContact')
            : t('dialogTitles.deleteDefault')
        }
        text={
          deleteContactMessageVisible
            ? t('messages.deleteContactDialog')
            : t('messages.deleteDefault')
        }
        noDefaultActionsRow={true}
        handleClose={() => {
          setDeleteContactMessageVisible(false);
        }}
      >
        <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
          <Button
            onClick={() => deleteContact()}
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            type='button'
            role='button'
            variant='outlined'
            size='medium'
          >
            {t('buttons.deleteContact')}
          </Button>
          <Button
            type='button'
            role='button'
            variant='contained'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => {
              setDeleteContactMessageVisible(false);
            }}
          >
            {t('buttons.goBack')}
          </Button>
        </div>
      </DialogItem>
    </div>
  );
};

export default SupplierContactItem;
