import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FeatureItemProps } from './types';

const FeatureItem = ({ icon, labelText, description }: FeatureItemProps) => {
  const { t } = useTranslation();

  return (
    <Grid item className='feature-item pt-3' xs={12}>
      <Grid container className='align-items-center d-flex flex-wrap gap-3'>
        <Grid item className='align-self-start'>
          {icon}
        </Grid>
        <Grid item xs>
          <Typography variant='h6' className='feature-item-title'>
            {t(`featureItems.${labelText}`)}
          </Typography>
          <Typography
            variant='body2'
            sx={{ whiteSpace: 'pre-line' }}
            className='feature-item-description'
          >
            {t(`featureItems.${description}`)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FeatureItem;
