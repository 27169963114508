import React, { useEffect } from 'react';
import DemoAuthTemplate from '../../common/demo-auth-template/DemoAuthTemplate';
//import ClerkLogIn from '../login/ClerkLogIn';
import LoginForm from '../login/LogInForm';
import { ROUTES } from '../../common/constants/routes';
import { switchDesign } from '../../common/design-switch/designSwitchSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';

const DemoLogin = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(switchDesign(true));
  }, []);
  return (
    <DemoAuthTemplate>
      {/*<ClerkLogIn />*/}
      <LoginForm />
    </DemoAuthTemplate>
  );
};

export default DemoLogin;
