import React, { useState } from 'react';
import { Box, Typography, Button, createTheme } from '@mui/material';
import { ThemeProvider } from 'react-bootstrap';
import FolderCheck from '../../../static/assets/svg/folder_check.svg';
import CloudUploadIcon from '../../../static/assets/svg/feather_upload-cloud.svg';
import { useTranslation } from 'react-i18next';

interface FileUploadProps {
  onFileSelect: (file: File) => void;
}

const FileUploadForm: React.FC<FileUploadProps> = ({ onFileSelect }) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      onFileSelect(file);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      onFileSelect(file);
    }
  };
  const localTheme = createTheme({});

  return (
    <ThemeProvider theme={localTheme}>
      <Box
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        className='file-upload-container'
      >
        {selectedFile && (
          <Box className='selected-file-text'>
            <FolderCheck></FolderCheck>
            <Typography variant='body2'>{selectedFile.name}</Typography>
          </Box>
        )}
        <input
          type='file'
          style={{ display: 'none' }}
          id='file-input'
          onChange={handleFileSelect}
        />
        {!selectedFile && (
          <Box className='button-container'>
            <Typography variant='h2' className='select-text' color='textSecondary'>
              {t('messages.selectFileMessage')}
            </Typography>
            <CloudUploadIcon />
            <Button
              component='label'
              htmlFor='file-input'
              variant='text'
              color='primary'
              className='select-file-button'
            >
              {t('buttons.selectFile')}
            </Button>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default FileUploadForm;
