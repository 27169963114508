import React, { useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import DetailsCard from '../../../common/details-card/DetailsCard';
import BankDetailsCard from '../../../common/bank-details-card/BankDetailsCard';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import {
  fetchBusinessProfileBankDetails,
  selectBusinessProfileBankDetails,
} from '../../../slices/business-profile/businessProfileSlice';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { Company } from '../../../models/company.model';

interface DetailsTabsProps {
  company: Company;
  key: string;
}

const DetailsTabs: React.FC<DetailsTabsProps> = ({ company }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const BusinessProfileBankDetails = useAppSelector(selectBusinessProfileBankDetails);

  useEffect(() => {
    dispatch(fetchBusinessProfileBankDetails({ companyId: company.id }));
  }, [dispatch]);

  return (
    <>
      <Grid container wrap='wrap' rowGap={2}>
        <Grid item xs={12}>
          <DetailsCard company={company} />
        </Grid>

        <Grid item xs={12}>
          {BusinessProfileBankDetails &&
            BusinessProfileBankDetails.length > 0 &&
            BusinessProfileBankDetails.map((bank) => (
              <Grid item key={bank.id}>
                <BankDetailsCard bank={bank} />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsTabs;
