import React from 'react';
import BrushIcon from '../../../../static/assets/svg/brush.svg';

import { useState, ChangeEvent } from 'react';
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import styles from './DocumentsEditButton.module.scss';
import FileSaveIcon from '../../../../static/assets/svg/file_save.svg';
import ContentCopyIcon from '../../../../static/assets/svg/content_copy.svg';
import VerticalDotsIcon from '../../../../static/assets/svg/more_vert.svg';
import ContractEditIcon from '../../../../static/assets/svg/contract_edit.svg';
import CalendarIcon from '../../../../static/assets/svg/calendar_month.svg';
import { NWClient } from '../../../client/NWClient';
import { toast } from 'react-toastify';
import DialogItem from '../../../common/dialog-item/DialogItem';
import { DocumentVaultItemModel } from '../../../models/document.vault.item.model';
import { DocumentHtmlModel } from '../../../models/document.html.model';
import { DocumentSigningModel } from '../../../models/document.signing.model';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { selectUser } from '../../../slices/user/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants/routes';
import MessageModal from '../../../common/message-modal/MessageModal';
import { deleteDocumentContract } from '../../../slices/document-contracts/documentContractsSlice';
import { deleteDocument } from '../../../slices/documents/documentsSlice';
import { Document } from '../../document-vault/components/document-list-vault/types';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';

interface DocumentsEditButtonProps {
  doc: any;
  onDocumentDeleted: () => void;
}

export const DocumentsEditButton = ({ doc, onDocumentDeleted }: DocumentsEditButtonProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [documentContractToDownloadId, setDocumentContractToDownloadId] = useState<null | number>(
    null
  );
  const [documentToDelete, setDocumentToDelete] = useState<Document | null>(doc);
  const [fileTypeForDownloading, setFileTypeForDownloading] = useState<'pdf' | 'doc'>('pdf');
  const [fileNameForDownloading, setFileNameForDownloading] = useState<string>('');
  const [fileTypeDialogVisible, setFileTypeDialogVisible] = useState<boolean>(false);
  const [planUpgradingMessage, setPlanUpgradingMessage] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [formatMessage, setFormatMessage] = useState(false);
  const [deleteMessageModalOpen, setDeleteMessageModalOpen] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(selectUser);

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const [checked, setChecked] = useState<boolean>(() => {
    const savedChecked = localStorage.getItem('checked');
    if (savedChecked === null) {
      localStorage.setItem('checked', 'true');
    }
    return savedChecked === 'true';
  });

  const handleFileTypeDialogClose = () => {
    setFileTypeDialogVisible(false);
    setDocumentContractToDownloadId(null);
    setFileNameForDownloading(doc.name.split('.').slice(0, -1).join('.'));
    setFileTypeForDownloading('pdf');
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadContract = (id: number) => {
    setPendingStatus(true);
    NWClient.downloadDocumentContract(
      id,
      fileNameForDownloading + '.' + fileTypeForDownloading,
      fileTypeForDownloading
    )
      .then(() => {
        handleFileTypeDialogClose();
      })
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setPendingStatus(false);
      });
  };

  const handleDocumentDownload = () => {
    if (doc.contract_generation) {
      setDocumentContractToDownloadId(doc.id);
      setFileTypeDialogVisible(true);
    } else if (typeof checked !== 'undefined' && checked) {
      setFileTypeDialogVisible(true);
    } else {
      setPendingStatus(true);
      NWClient.downloadDocument(doc.id, doc.name)
        .catch(() => {
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          setPendingStatus(false);
        });
    }
  };

  const handleDocumentEdit = () => {
    navigate(`${ROUTES.DOCUMENT_VAULT}/business/${doc.id}?initialTab=1`);
  };

  const handleDocumentSign = () => {
    setPendingStatus(true);
    if (!doc.contract_generation) {
      if (typeof checked !== 'undefined' && checked) {
        NWClient.get('document-upload', doc.id).then((res: DocumentVaultItemModel) => {
          NWClient.get('document-upload-html', Math.max(...res.document_upload_html)).then(
            (res: DocumentHtmlModel) => {
              const data = {
                user: currentUser.id,
                document_upload_html: res.id,
              };
              NWClient.post('document-sign', data, true)
                .then((res: DocumentSigningModel) => {
                  navigate(`${ROUTES.DOCUMENTS}/${res.signing_doc_id}`);
                })
                .catch((error) => {
                  if (
                    error.response?.data?.errors?.length > 0 &&
                    error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
                  ) {
                    setPlanUpgradingMessage(true);
                  } else if (
                    error.response?.data?.errors?.length > 0 &&
                    error.response?.data?.errors[0] === t('messages.userNotAssociatedWithCompany')
                  ) {
                    navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${path}`);
                  } else {
                    toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                      theme: 'colored',
                    });
                  }
                })
                .finally(() => {
                  setPendingStatus(false);
                  return;
                });
            }
          );
        });
      } else {
        NWClient.get('document-upload', doc.id).then((res: DocumentVaultItemModel) => {
          NWClient.get('document-upload-html', Math.min(...res.document_upload_html)).then(
            (res: DocumentHtmlModel) => {
              const data = {
                user: currentUser.id,
                document_upload_html: res.id,
              };
              NWClient.post('document-sign', data, true)
                .then((res: DocumentSigningModel) => {
                  navigate(`${ROUTES.DOCUMENTS}/${res.signing_doc_id}`);
                })
                .catch((error) => {
                  if (
                    error.response?.data?.errors?.length > 0 &&
                    error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
                  ) {
                    setPlanUpgradingMessage(true);
                  } else if (
                    error.response?.data?.errors?.length > 0 &&
                    error.response?.data?.errors[0] === t('messages.userNotAssociatedWithCompany')
                  ) {
                    navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${path}`);
                  } else {
                    toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                      theme: 'colored',
                    });
                  }
                })
                .finally(() => {
                  setPendingStatus(false);
                  return;
                });
            }
          );
        });
      }
    } else {
      if (doc.contract_generation) {
        const data = {
          user: currentUser.id,
          [doc.contract_generation ? 'document_contract' : 'document_upload']: doc.id,
          //signing_doc_id: 1, //should be optional later, to refactor
        };
        NWClient.post('document-sign', data, true)
          .then((res: DocumentSigningModel) => {
            navigate(`${ROUTES.DOCUMENTS}/${res.signing_doc_id}`);
          })
          .catch((error) => {
            if (
              error.response?.data?.errors?.length > 0 &&
              error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
            ) {
              setPlanUpgradingMessage(true);
            } else if (
              error.response?.data?.errors?.length > 0 &&
              error.response?.data?.errors[0] === t('messages.userNotAssociatedWithCompany')
            ) {
              navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${path}`);
            } else {
              toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                theme: 'colored',
              });
            }
          })
          .finally(() => {
            setPendingStatus(false);
          });
      } else {
        setFormatMessage(true);
      }
    }
  };
  const handleDeleteDocument = () => {
    setPendingStatus(true);
    setDeleteMessageModalOpen(false);
    dispatch(
      documentToDelete.contract_generation
        ? deleteDocumentContract({ id: documentToDelete?.id, userId: currentUser?.id })
        : deleteDocument({ id: documentToDelete?.id, userId: currentUser?.id })
    )
      .unwrap()
      .catch((error) => {
        toast.error(error.message || t('messages.errorOccurred'));
      })
      .finally(() => {
        setDocumentToDelete(null);
        setDeleteMessageModalOpen(false);
        setPendingStatus(false);
        onDocumentDeleted();
      });
  };
  const handleDocumentVersions = () => {
    navigate(`${ROUTES.DOCUMENT_VAULT}/business/${doc.id}?initialTab=2`);
  };
  const handleDocumentLabels = () => {
    navigate(`${ROUTES.DOCUMENT_VAULT}/business/${doc.id}?initialTab=3`);
  };
  const handleDocumentSummary = () => {
    navigate(`${ROUTES.DOCUMENT_VAULT}/business/${doc.id}`);
  };
  const handleDocumentCreateTemplate = () => {
    navigate(`${ROUTES.DOCUMENT_VAULT}/business/${doc.id}?initialTab=4`);
  };

  return (
    <div>
      <IconButton
        onClick={() => {
          handleDocumentDownload();
        }}
        className={styles.action_button}
      >
        <FileSaveIcon />
      </IconButton>
      <IconButton onClick={() => handleDocumentSign()} className={styles.action_button}>
        <ContractEditIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          handleDocumentEdit();
        }}
        className={styles.action_button}
      >
        <BrushIcon />
      </IconButton>
      <>
        <IconButton onClick={handleClick}>
          <VerticalDotsIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              handleClose();
              handleDocumentSummary();
            }}
          >
            <span className={styles.margin_left}> {t('buttons.summary')}</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleDocumentVersions();
            }}
          >
            <span className={styles.margin_left}> {t('buttons.versions')}</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleDocumentLabels();
            }}
          >
            <span className={styles.margin_left}> {t('buttons.labels')}</span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose();
              handleDocumentCreateTemplate();
            }}
          >
            <span className={styles.margin_left}> {t('buttons.createTemplate')}</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setDeleteMessageModalOpen(true);
            }}
          >
            <span className={styles.margin_left}> {t('buttons.delete')}</span>
          </MenuItem>
        </Menu>
      </>
      {fileTypeDialogVisible && (
        <DialogItem
          isErrorMessage={false}
          open={fileTypeDialogVisible}
          title={t('dialogTitles.chooseFileType')}
          text={t('messages.fileTypeToDownload')}
          noDefaultActionsRow={true}
          handleClose={() => handleFileTypeDialogClose()}
        >
          <Grid container className='form-controls mt-1' spacing={2} alignItems='center'>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor='fileTypeForDownloading'>{t('labels.fileType')}</InputLabel>
              <Select
                onChange={(e: SelectChangeEvent) => {
                  setFileTypeForDownloading(e.target.value as 'pdf' | 'doc');
                }}
                id='fileTypeForDownloading'
                name='fileTypeForDownloading'
                value={fileTypeForDownloading}
                className='w-100'
              >
                <MenuItem value={'pdf'}>{t('contractGeneratorDialog.formatPDF')}</MenuItem>
                <MenuItem value={'doc'}>{t('contractGeneratorDialog.formatWord')}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('labels.fileName')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFileNameForDownloading(e.target.value)
                }
                name='fileName'
                value={fileNameForDownloading}
                tabIndex={-1}
                sx={{ marginTop: '1.4rem' }}
              />
            </Grid>
          </Grid>
          <div className='buttons-row d-flex flex-wrap justify-content-end mt-4'>
            <Button
              type='button'
              role='button'
              variant='outlined'
              size='medium'
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              onClick={() => handleFileTypeDialogClose()}
            >
              {t('buttons.goBack')}
            </Button>
            <Button
              onClick={
                typeof checked !== 'undefined' && checked
                  ? () =>
                      NWClient.downloadHtmlDocument(
                        Math.max(...doc.document_upload_html),
                        fileNameForDownloading + '.' + fileTypeForDownloading,
                        fileTypeForDownloading
                      )
                  : () => handleDownloadContract(documentContractToDownloadId)
              }
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              type='button'
              role='button'
              variant='contained'
              size='medium'
            >
              {t('buttons.download')}
            </Button>
          </div>
        </DialogItem>
      )}

      {deleteMessageModalOpen && (
        <MessageModal
          open={deleteMessageModalOpen}
          modalTitle={t('dialogTitles.deleteFile')}
          modalMessage={t('messages.deleteFile')}
          actionButtonText={t('buttons.deleteFile')}
          actionButtonFn={() => {
            handleDeleteDocument();
          }}
          goBackButtonText={t('buttons.goBack')}
          document={documentToDelete}
          onClose={() => {
            setDocumentToDelete(null);
            setDeleteMessageModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default DocumentsEditButton;
