import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { Customer, CustomerSearchData } from '../../models/customer.model';
import { RootState } from '../../store';

export const fetchCustomers = createAsyncThunk(
  'companies/fetchCustomers',
  async ({ company_id }: CustomerSearchData, { rejectWithValue }) => {
    if (company_id === undefined) {
      return rejectWithValue('Company ID is required');
    }
    const response = await NWClient.list('customer', { company_id });
    return response;
  }
);

export interface CustomersState {
  customersList: Customer[];
  status: string;
  error: string;
}

const customersArray: Customer[] = [];
const initialState: CustomersState = { customersList: customersArray, status: 'idle', error: '' };
const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.customersList = [...action.payload];
          state.status = 'succeeded';
        }
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default customersSlice.reducer;

export const selectAllCustomers = (state: RootState) => state.customers.customersList;
export const selectCustomerById = (state: RootState, customerId: number) =>
  state.customers.customersList.find((item) => item.id === customerId);
