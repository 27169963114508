import React, { useRef, useState } from 'react';
import { useLibrary } from './hooks/useLibrary';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import styles from './scss/Library.module.scss';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Button,
} from '@mui/material';
import UploadButton from '../../common/upload-button/UploadButton';
import FormUpload from '../../common/form-upload/FormUpload';
import Pagination from '../../common/pagination/Pagination';
import SearchField from '../../common/search-field/SearchField';
import FilterDrawer from '../../common/filter-drawer/FilterDrawer';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { useFileTypeDownload } from '../../common/file-type-download-modal/useFileTypeDownload';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import LibraryList from './components/library-list/LibraryList';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import OwnLibraryIcon from '../../../static/assets/svg/own-library-icon.svg';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { selectAllDocumentTemplates } from '../../slices/document-templates/documentTemplatesSlice';
import ButtonDropDown from '../../common/button-drop-down/ButtonDropDown';
import MessageModal from '../../common/message-modal/MessageModal';
import DisclaimerText from '../../common/disclaimer-text/DisclaimerText';
import { acceptDisclaimer } from '../../slices/disclaimer/disclaimerSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Library = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    libraryItems,
    handleTabChange,
    activeTab,
    pendingStatus: libraryItemsPendingStatus,
    setLibraryType,
    libraryType,
    region,
    setRegion,
    disclaimerMessage,
    setDisclaimerMessage,
  } = useLibrary();
  const documentTemplates = useAppSelector(selectAllDocumentTemplates);
  const disclaimerStatus = useAppSelector((state) => state.disclaimerInfo.status);
  const disclaimerInfoLoadingStatus = useAppSelector((state) => state.disclaimerInfo.status);
  const sortByDate = (a: any, b: any) => {
    return Number(new Date(b.created)) - Number(new Date(a.created));
  };
  const regionsOptions = [
    { label: t('regions.au'), value: 'au' },
    { label: t('regions.uk'), value: 'uk' },
    { label: t('regions.global'), value: 'global' },
  ];
  return (
    <>
      <Grid container spacing={3} sx={{ mt: '-100px' }}>
        <Grid item xs={12} sx={{ pt: '32px !important' }}>
          <Stack spacing={0} sx={{ width: '70%', marginBottom: '20px' }}>
            <Typography variant='h5' component='h1' className={styles.page_title}>
              {t('headers.documentWorkspace')}
            </Typography>
            <Stack direction='row' marginTop={'8px'} gap={'8px'}>
              <Button
                variant='contained'
                className={`app-bar-link ${libraryType === 'businessKitz' ? '' : ' white-variant'}`}
                onClick={() => setLibraryType('businessKitz')}
              >
                <AccountBalanceOutlinedIcon fontSize='medium' />
                {t('buttons.businessKitzLibrary')}
              </Button>
              <Button
                variant='contained'
                className={`app-bar-link ${libraryType === 'own' ? '' : ' white-variant'}`}
                onClick={() => {
                  setLibraryType('own');
                  handleTabChange('All');
                }}
              >
                <OwnLibraryIcon />
                {t('buttons.ownLibrary')}
              </Button>
              <Box className='region-select-box'>
                <ButtonDropDown
                  value={region}
                  onChange={(event) => setRegion(event.target.value as 'uk' | 'au' | 'global')}
                  options={regionsOptions}
                  disabled={libraryType === 'own'}
                />
              </Box>
              <Tooltip title={t('buttons.documentTerms')} placement='top' arrow>
                <Button
                  variant='contained'
                  className='app-bar-link white-variant min-width-inherit'
                  onClick={() => {
                    setDisclaimerMessage(true);
                  }}
                >
                  <InfoOutlinedIcon fontSize='medium' />
                </Button>
              </Tooltip>
            </Stack>
          </Stack>
          <Box className={styles.document_header}>
            <Typography variant='h6' component='h2'>
              {t('headers.templates')}
            </Typography>
            <Box className={styles.actions_group}>
              {/*<Pagination page={page} totalPages={1} onPageChange={handlePageChange} />
              <SearchField
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder='Search for document'
              />
              <FilterDrawer sections={filterSections} onApplyFilters={handleApplyFilters} />*/}
            </Box>
          </Box>

          <Box className={styles.tab_container}>
            <Box className={styles.tabs_section}>
              <Tabs
                value={activeTab}
                onChange={(_, newValue) => handleTabChange(newValue)}
                aria-label='document categories'
                className={styles.tabs}
              >
                <Tab label='All' value='All' />
                <Tab label='Business' value='Business' disabled={libraryType === 'own'} />
                <Tab label='Employees' value='Employment' disabled={libraryType === 'own'} />
                <Tab label='Customers' value='Customers' disabled={libraryType === 'own'} />
                <Tab label='Suppliers' value='Suppliers' disabled={libraryType === 'own'} />
              </Tabs>
            </Box>
          </Box>

          <Box sx={{ mt: '15px', position: 'relative' }}>
            <LibraryList
              libraryItems={
                libraryType === 'own'
                  ? [...documentTemplates].sort((a, b) => sortByDate(a, b))
                  : libraryItems
              }
            />
            {(libraryItemsPendingStatus || disclaimerInfoLoadingStatus === 'loading') && (
              <ProgressOverlay className={'position-absolute'} />
            )}
          </Box>
        </Grid>
      </Grid>
      {disclaimerMessage && (
        <MessageModal
          open={disclaimerMessage}
          modalTitle={t('headers.documentTerms')}
          modalContent={<DisclaimerText />}
          actionButtonText={t('buttons.accept')}
          headerVariant={'h5'}
          actionButtonFn={() => {
            dispatch(acceptDisclaimer())
              .unwrap()
              .finally(() => {
                setDisclaimerMessage(false);
              });
          }}
          goBackButtonText={t('buttons.goBack')}
          onClose={() => {
            setDisclaimerMessage(false);
          }}
        />
      )}
      {disclaimerStatus === 'loadingAccepting' && <ProgressOverlay />}
    </>
  );
};

export default Library;
