// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MbB0ppjK_0GXeaUekogL{margin-left:.2em !important}`, "",{"version":3,"sources":["webpack://./src/app/pages/BusinessProfile/components/DocumentsEditButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA","sourcesContent":[".margin_left {\n  margin-left: 0.2em !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"margin_left": `MbB0ppjK_0GXeaUekogL`
};
export default ___CSS_LOADER_EXPORT___;
