import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileSaveIcon from '../../../../../static/assets/svg/file_save.svg';
import CalendarIcon from '../../../../../static/assets/svg/calendar_month.svg';
import styles from './LibraryList.module.scss';
import { Helpers } from '../../../../common/helpers/helpers';
import { v4 as uuidv4 } from 'uuid';
import { LibraryListProps, LibraryItem } from './types';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { selectAllDocuments } from '../../../../slices/documents/documentsSlice';
import { selectAllCompanies } from '../../../../slices/companies/companiesSlice';
import { useLibraryFunctions } from '../../hooks/useLibraryFunctions';
import MessageModal from '../../../../common/message-modal/MessageModal';
import { ROUTES } from '../../../../common/constants/routes';
import { useNavigate } from 'react-router';
import FileTypeDownloadModal from '../../../../common/file-type-download-modal/FileTypeDownloadModal';
import { useFileTypeDownload } from '../../../../common/file-type-download-modal/useFileTypeDownload';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import MenuBlock from '../../../../common/menu-block';
import VerticalDotsIcon from '../../../../../static/assets/svg/more_vert.svg';
import { useDeleteLibraryItemMessageModal } from '../../hooks/useDeleteMessageModal';
import ContractGenerator from '../../../../common/contract-generator/ContractGenerator';
import { PacmanLoaderBlock } from '../../../../common/pacman-loader';
import { useContractsGenerator } from '../../../../common/contract-generator/hooks/useContractsGenerator';
import ContractGeneratorIcon from '../../../../../static/assets/svg/contract-generator-icon.svg';
import {
  selectDisclaimerChecked,
  acceptDisclaimer,
} from '../../../../slices/disclaimer/disclaimerSlice';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import DisclaimerText from '../../../../common/disclaimer-text/DisclaimerText';

const LibraryList = ({ libraryItems }: LibraryListProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectAllCompanies)[0];
  const navigate = useNavigate();
  const documentUploads = useAppSelector(selectAllDocuments);
  const disclaimerAccepted = useAppSelector(selectDisclaimerChecked);
  const disclaimerStatus = useAppSelector((state) => state.disclaimerInfo.status);
  const freePlan = !company?.stripe_product_id && !company?.plan_settings.stripe_product_id;
  const {
    handleS3DownloadLink,
    planUpgradingMessage,
    setPlanUpgradingMessage,
    navigateToTemplatePage,
    disclaimerMessage,
    setDisclaimerMessage,
  } = useLibraryFunctions();
  const {
    setModalOpen: setFileTypeModalOpen,
    modalOpen: fileTypeModalOpen,
    handleCloseModal: handleCloseFileTypeModal,
    libraryItem: libraryItemForDownloading,
    setLibraryItem: setLibraryItemForDownloading,
    setFileName: setFileNameForDownloading,
    fileName: fileNameForDownloading,
    fileType: fileTypeForDownloading,
    setFileType: setFileTypeForDownloading,
    handleTemplateDownload,
    pendingStatus: downloadPendingStatus,
  } = useFileTypeDownload();

  const {
    modalOpen: deleteLibraryItemModalOpen,
    setModalOpen: setDeleteLibraryItemModalOpen,
    handleDeleteDocumentTemplate,
    libraryItemToDelete,
    setLibraryItemToDelete,
    pendingStatus: deletingLibraryItemPending,
  } = useDeleteLibraryItemMessageModal();

  const {
    generatorOpened,
    setGeneratorOpened,
    setLibraryItem: setLibraryItemForGenerator,
    libraryItem: libraryItemForGenerator,
  } = useContractsGenerator();

  const handleNavigate = (item: LibraryItem) => {
    const actionAvailableFree = 'is_free' in item && item.is_free;
    if (item.download_link) {
      return false;
    } else {
      if (freePlan) {
        if (actionAvailableFree) {
          navigateToTemplatePage('region' in item ? item.region : 'company-templates', item.id);
        } else {
          setPlanUpgradingMessage(true);
        }
      } else {
        navigateToTemplatePage('region' in item ? item.region : 'company-templates', item.id);
      }
    }
  };

  const renderTableHeader = () => (
    <TableHead>
      <TableRow className={styles.header_row}>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('libraryTable.name')}
          </Typography>
        </TableCell>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('libraryTable.profiles')}
          </Typography>
        </TableCell>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('libraryTable.workflow')}
          </Typography>
        </TableCell>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('libraryTable.documentType')}
          </Typography>
        </TableCell>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('libraryTable.dateCreatedModified')}
          </Typography>
        </TableCell>
        <TableCell className={`${styles.header_cell} ${styles.actions_header}`}>
          <Typography variant='body2' className={styles.header_text}>
            {t('libraryTable.actions')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const renderActionButtons = (item: LibraryItem) => {
    const actionAvailableFree = 'is_free' in item && item.is_free;
    const templateMoreActions = [
      {
        name: t('buttons.view'),
        onClick: () => {
          handleNavigate(item);
        },
      },
      {
        name: t('buttons.edit'),
        onClick: () => {
          handleNavigate(item);
        },
      },
      {
        name: t('buttons.delete'),
        onClick: () => {
          setLibraryItemToDelete(item);
          setDeleteLibraryItemModalOpen(true);
        },
      },
    ];

    const handleGenerateContract = () => {
      const actionAvailableFree = 'is_free' in item && item.is_free;
      if ((freePlan && actionAvailableFree) || !freePlan) {
        if (disclaimerAccepted) {
          setLibraryItemForGenerator(item);
          setGeneratorOpened(true);
        } else {
          setLibraryItemForGenerator(item);
          setDisclaimerMessage(true);
        }
      } else if (freePlan && !actionAvailableFree) {
        setPlanUpgradingMessage(true);
      }
    };

    const handleDownloading = () => {
      if (!item.download_link) {
        setFileNameForDownloading(
          'name' in item
            ? item.name
            : documentUploads
                ?.find((el) => el.id === item.document_upload)
                ?.name?.replace('.pdf', '')
                .replace('.docx', '')
                .replace('.doc', '')
        );
        setLibraryItemForDownloading(item);
        setFileTypeModalOpen(true);
      } else {
        handleS3DownloadLink(item.id, 'name' in item ? item.name : '');
      }
    };

    return (
      <Box className={styles.actions_container}>
        {!item.download_link && (
          <IconButton
            size='small'
            onClick={() => handleGenerateContract()}
            className={styles.action_button}
          >
            <ContractGeneratorIcon />
          </IconButton>
        )}
        <IconButton
          size='small'
          onClick={() => {
            if (freePlan) {
              if (actionAvailableFree) {
                handleDownloading();
              } else {
                setPlanUpgradingMessage(true);
              }
            } else {
              handleDownloading();
            }
          }}
          className={styles.action_button}
        >
          <FileSaveIcon />
        </IconButton>
        {item.document_upload && !item.download_link && (
          <MenuBlock options={templateMoreActions} moreIcon={<VerticalDotsIcon />} />
        )}
      </Box>
    );
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className={styles.table_container}
        sx={{ boxShadow: 'none' }}
      >
        <Table>
          {renderTableHeader()}
          <TableBody>
            {libraryItems.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <p className={styles.empty_message}>
                    {t('emptyStateMessages.emptyLibraryMessage')}
                  </p>
                </TableCell>
              </TableRow>
            )}
            {libraryItems.map((item) => {
              return (
                <TableRow key={`${item.id}-${uuidv4()}`} className={styles.table_row}>
                  <TableCell className={styles.table_cell} onClick={() => handleNavigate(item)}>
                    <Box className={styles.profile_container}>
                      <Typography variant='body2' className={styles.profile_text}>
                        {'name' in item
                          ? item.name
                          : documentUploads.find((el) => el.id === item.document_upload)?.name}
                      </Typography>
                      {item.is_free && (
                        <Box className={styles.free_tag}>{t('pricingOptionNames.free')}</Box>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell className={styles.table_cell} onClick={() => handleNavigate(item)}>
                    <Typography
                      variant='body2'
                      sx={{ textTransform: 'capitalize' }}
                      className={styles.cell_text}
                    >
                      {'dl_categories' in item
                        ? item.dl_categories.length > 0
                          ? item.dl_categories.join(', ')
                          : '-'
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.table_cell} onClick={() => handleNavigate(item)}>
                    <Typography variant='body2' className={styles.cell_text}>
                      {'dl_workflows' in item
                        ? item.dl_workflows.length > 0
                          ? item.dl_workflows.join(', ')
                          : '-'
                        : item.dt_workflows.length > 0
                        ? item.dt_workflows.join(', ')
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.table_cell} onClick={() => handleNavigate(item)}>
                    <Typography variant='body2' className={styles.cell_text}>
                      {'dl_types' in item
                        ? item.dl_types.length > 0
                          ? item.dl_types.join(', ')
                          : '-'
                        : item.dt_types.length > 0
                        ? item.dt_types.join(', ')
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.table_cell} onClick={() => handleNavigate(item)}>
                    <Box className={styles.date_container}>
                      <CalendarIcon />
                      <Typography variant='body2' className={styles.cell_text}>
                        {'created' in item ? Helpers.timeStampToDateV2(item.created) : '2025'}{' '}
                        {/*2025 - just a placeholder*/}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={styles.table_cell}>{renderActionButtons(item)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {planUpgradingMessage && (
        <MessageModal
          open={planUpgradingMessage}
          modalMessage={t('messages.considerStartingPlanLibrary')}
          actionButtonText={t('buttons.ok')}
          actionButtonFn={() => navigate(ROUTES.PRICING)}
          goBackButtonText={t('buttons.later')}
          onClose={() => {
            setPlanUpgradingMessage(false);
          }}
        />
      )}
      {fileTypeModalOpen && libraryItemForDownloading && (
        <FileTypeDownloadModal
          onClose={() => {
            handleCloseFileTypeModal();
          }}
          open={fileTypeModalOpen}
          fileName={fileNameForDownloading}
          fileType={fileTypeForDownloading}
          handleFileTypeChange={setFileTypeForDownloading}
          handleFileNameChange={setFileNameForDownloading}
          libraryItem={libraryItemForDownloading}
          handleDownloadLibraryHTML={() => {
            handleTemplateDownload(
              libraryItemForDownloading.id,
              fileTypeForDownloading,
              fileNameForDownloading
            );
          }}
        />
      )}
      {deleteLibraryItemModalOpen && libraryItemToDelete && (
        <MessageModal
          open={deleteLibraryItemModalOpen}
          modalTitle={t('dialogTitles.deleteTemplate')}
          modalMessage={t('messages.deleteTemplate')}
          actionButtonText={t('buttons.deleteTemplate')}
          actionButtonFn={handleDeleteDocumentTemplate}
          goBackButtonText={t('buttons.goBack')}
          document={libraryItemToDelete}
          onClose={() => {
            setLibraryItemToDelete(null);
            setDeleteLibraryItemModalOpen(false);
          }}
        />
      )}
      {disclaimerMessage && (
        <MessageModal
          open={disclaimerMessage}
          modalTitle={t('headers.documentTerms')}
          modalContent={<DisclaimerText />}
          actionButtonText={t('buttons.accept')}
          headerVariant={'h5'}
          actionButtonFn={() => {
            dispatch(acceptDisclaimer())
              .unwrap()
              .then(() => {
                setDisclaimerMessage(false);
                setGeneratorOpened(true);
              });
          }}
          goBackButtonText={t('buttons.goBack')}
          onClose={() => {
            setDisclaimerMessage(false);
            setLibraryItemForGenerator(null);
          }}
        />
      )}
      {company && generatorOpened && libraryItemForGenerator && (
        <ContractGenerator
          templateId={libraryItemForGenerator.id}
          libraryItem={libraryItemForGenerator}
          open={generatorOpened}
          onClose={() => {
            setGeneratorOpened(false);
            setLibraryItemForGenerator(null);
          }}
          templateLabels={
            'region' in libraryItemForGenerator
              ? {
                  categoryLabel: libraryItemForGenerator.category,
                  typeLabel: libraryItemForGenerator.dl_types[0],
                  workflowLabel: libraryItemForGenerator.dl_workflows[0],
                }
              : null
          }
          templateTitle={
            libraryItemForGenerator.name ||
            ('document_upload' in libraryItemForGenerator && libraryItemForGenerator.document_upload
              ? documentUploads.length > 0
                ? documentUploads.find((el) => el.id === libraryItemForGenerator.document_upload)
                    .name +
                  '_' +
                  libraryItemForGenerator.updated
                : ''
              : '')
          }
        />
      )}
      {(downloadPendingStatus ||
        deletingLibraryItemPending ||
        disclaimerStatus === 'loadingAccepting') && <ProgressOverlay />}
    </>
  );
};

export default LibraryList;
