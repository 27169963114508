import { useState } from 'react';
import { Document } from '../components/document-list-vault/types';
import { NWClient } from '../../../client/NWClient';
import { DocumentSigningModel } from '../../../models/document.signing.model';
import { ROUTES } from '../../../common/constants/routes';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { selectUser } from '../../../slices/user/userSlice';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SIGNING_DATA } from '../types';

export const useDocumentSigning = () => {
  const currentUser = useAppSelector(selectUser);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [planUpgradingMessage, setPlanUpgradingMessage] = useState<boolean>(false);
  const [document, setDocument] = useState<Document>(null);

  const handleSign = (data: SIGNING_DATA) => {
    setPendingStatus(true);
    setDialogOpen(false);
    NWClient.post('document-sign', data, true)
      .then((res: DocumentSigningModel) => {
        navigate(`${ROUTES.DOCUMENTS}/${res.signing_doc_id}`);
      })
      .catch((error) => {
        if (
          error.response?.data?.errors?.length > 0 &&
          error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
        ) {
          setPlanUpgradingMessage(true);
        } else if (
          error.response?.data?.errors?.length > 0 &&
          error.response?.data?.errors[0] === t('messages.userNotAssociatedWithCompany')
        ) {
          navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${path}`);
        } else {
          toast.error(error.message ? error.message : t('messages.errorOccurred'));
        }
      })
      .finally(() => {
        setPendingStatus(false);
      });
  };

  const handleSignLatestVersion = (doc: Document) => {
    const data = {
      user: currentUser.id,
      document_upload_html:
        'document_upload_html' in doc ? Math.max(...doc.document_upload_html) : null,
    };
    handleSign(data);
  };

  const handleSignOriginalDocument = (doc: Document) => {
    const data = {
      user: currentUser.id,
      [doc.contract_generation ? 'document_contract' : 'document_upload']: doc.id,
    };
    handleSign(data);
  };

  return {
    dialogOpen,
    setDialogOpen,
    document,
    setDocument,
    planUpgradingMessage,
    setPlanUpgradingMessage,
    pendingStatus,
    setPendingStatus,
    handleSignOriginalDocument,
    handleSignLatestVersion,
  };
};
