import React, { useState, useEffect, ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from 'react-router-dom';
import PasswordResetEmailBlock from './PasswordResetEmailBlock';
import { NWClient } from '../../client/NWClient';
import { Helpers } from '../../common/helpers/helpers';
import { ROUTES } from '../../common/constants/routes';
import { REGEXP } from '../../common/constants/regexp';
import { FORM_ITEMS } from '../../common/constants/form_items';
import FormErrorText from '../../common/form-error-text/form-error-text';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const LogInForm = () => {
  const emailRegExp = REGEXP.EMAIL;
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorTextArray, setErrorTextArray] = useState([]);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [shouldResetPassword, setShouldResetPassword] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const canSubmit = email.trim() && emailValid && password.trim();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleResendEmail = () => {
    NWClient.resendActivationCode({ email })
      .then(() => {
        toast('Confirmation email resent successfully');
      })
      .catch((error) => {
        console.error('Error resending confirmation email:', error);
      });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      email: email.trim().toLowerCase(),
      password,
    };
    NWClient.logIn(data)
      .then((res: { token: string }) => {
        setPendingStatus(false);
        localStorage.setItem('access_token', res.token);
        navigate(ROUTES.INDEX);
      })
      .catch((error) => {
        Helpers.handleRequestError(error.response, setErrorTextArray, setPendingStatus);
      });
  };

  useEffect(() => {
    setEmailValid(emailRegExp.test(email));
  }, [email, emailRegExp]);

  return (
    <React.Fragment>
      <ValidatorForm
        className='user-data-form'
        aria-invalid={Boolean(errorTextArray.length)}
        aria-errormessage={FORM_ITEMS.ERROR_CONTAINER_ID}
        onSubmit={(e) => handleSubmit(e)}
      >
        <TextValidator
          label={t('labels.emailAddress')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          name='email'
          value={email}
          validators={['required', 'isEmail']}
          errorMessages={[t('messages.fieldRequired'), t('messages.emailNotValid')]}
        />

        <TextValidator
          label={t('labels.password')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePasswordChange(e)}
          name='password'
          type={showPassword ? 'text' : 'password'}
          validators={['required']}
          errorMessages={[t('messages.fieldRequired')]}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className='link-container text-center'>
          <span
            role='button'
            tabIndex={0}
            className='forgot-password-link'
            onClick={() => setShouldResetPassword(true)}
            onKeyUp={(e) => {
              console.log(e);
            }}
          >
            {t('buttons.forgotPassword')}
          </span>
        </div>
        <FormErrorText errorTextArray={errorTextArray} />
        {errorTextArray.some((error) => {
          return String(error.errors).includes('confirmation');
        }) && (
          <div className='confirmation-error'>
            <span
              role='button'
              style={{ fontSize: '0.875rem', color: '#3f51b5', cursor: 'pointer' }}
              tabIndex={0}
              onClick={() => {
                handleResendEmail();
              }}
              onKeyUp={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleResendEmail();
                }
              }}
            >
              Resend confirmation email
            </span>
          </div>
        )}
        <div className='buttons-row'>
          <Button
            type='submit'
            role='button'
            variant='contained'
            disabled={!canSubmit}
            size='large'
            className='btn-default btn-action-basic'
          >
            {t('buttons.signIn')}
          </Button>
        </div>
        <div className='text-center mb-0'>
          <Link to={ROUTES.SIGN_UP}>{t('buttons.signUp')}</Link>
        </div>
        {pendingStatus ? <ProgressOverlay /> : ''}
      </ValidatorForm>
      {shouldResetPassword ? <PasswordResetEmailBlock /> : ''}
    </React.Fragment>
  );
};

export default LogInForm;
