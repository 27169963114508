import React from 'react';
import { UploadButtonProps } from './types';
import { Button, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const UploadButton = ({ onClick, buttonText, isArrowDown, disabled }: UploadButtonProps) => {
  return (
    <Button
      disabled={disabled}
      sx={{ zIndex: 10 }}
      variant='text'
      className='upload-button'
      onClick={onClick}
    >
      <UploadFileIcon className='upload-file-icon'></UploadFileIcon>
      <Typography className='button-text'>{buttonText}</Typography>
      {isArrowDown && <KeyboardArrowDownIcon className='arrowdown-icon'></KeyboardArrowDownIcon>}
    </Button>
  );
};

export default UploadButton;
