import React, { useState, useRef } from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Backdrop,
  Fade,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import {
  BusinessProfileContacts,
  createBusinessProfileContact,
} from '../../slices/business-profile/businessProfileSlice';
import ContactNameInput from '../form-inputs/contact-name-input/ContactNameInput';
import EmailAddressInput from '../form-inputs/email-address-input/EmailAddresInput';
import PhoneInput from '../form-inputs/phone-input/PhoneInput';
import AddressInput from '../form-inputs/address-input/AddressInput';
import PositionInput from '../form-inputs/position-input/PositionInput';
import AuthorizedSignCheckbox from '../authorized-sign-checkbox/AuthorizedSignCheckbox';
import ShareholderCheckbox from '../share-holder-checkbox/ShareHolderCheckbox';
import HiringManagerCheckbox from '../hiring-manager-checkbox/HiringManagerCheckBox';
import LegalRepresentationCheckbox from '../legal-representation-checkbox/LegalRepresentationCheckbox';
import styles from './CreateContactModal.module.scss';
import { useAppSelector } from '../hooks/useAppSelector';

interface CreateContactModalProps {
  open: boolean;
  onClose: () => void;
  companyId: number;
}

export default function CreateContactModal({ open, onClose, companyId }: CreateContactModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLDivElement | null>(null);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const [formData, setFormData] = useState({
    contact_name: '',
    contact_email: '',
    company: companyId,
    contact_phone_number: '',
    contact_position: '',
    contact_address: '',
    is_authorized_signatory: false,
    is_shareholder: false,
    is_hiring_manager: false,
    is_legal_representative: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    contact_name: false,
    contact_email: false,
    contact_position: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange =
    <K extends keyof typeof formData>(field: K) =>
    (value: (typeof formData)[K]) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

  const handleCreate = () => {
    setIsSubmitting(true);
    const errors = {
      contact_name: !formData.contact_name.trim(),
      contact_email: !formData.contact_email.trim(),
      contact_position: !formData.contact_position.trim(),
    };

    setValidationErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      console.error('Required fields are empty');
      return;
    }
    dispatch(
      createBusinessProfileContact({
        companyId: companyId,
        data: formData as Omit<BusinessProfileContacts, 'id'>,
      })
    );
    setIsSubmitting(false);
    onClose();
  };

  // Submission Button
  const submissionButtonSx = {
    fontFamily: "'Regular Inter', sans-serif",
    boxShadow: 'none',
    fontSize: '0.75rem',
    fontWeight: 400,
    minWidth: '100px',
    height: '44px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: '#ecdfff', // $purple-light
    color: '#6414db', // $brand-purple
    '&:hover': {
      color: '#fff', // $base-white
      backgroundColor: '#b17dff', // $purple-light-2
    },
  };

  // Closing Button
  const closingButtonSx = {
    fontFamily: "'Regular Inter', sans-serif",
    fontSize: '0.75rem',
    fontWeight: 400,
    minWidth: '100px',
    height: '44px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    backgroundColor: '#fff', // $base-white
    color: '#000000', // $base-black
    '&:hover': {
      backgroundColor: '#ededed', // $gray-variant
    },
  };

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Grid container className={styles.modal_container}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card ref={formRef}>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
              <Typography variant='h6' gutterBottom>
                {t('businessProfile.createContact')}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ContactNameInput
                    value={formData.contact_name}
                    onChange={handleChange('contact_name')}
                    disabled={false}
                    submitting={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EmailAddressInput
                    value={formData.contact_email}
                    onChange={handleChange('contact_email')}
                    disabled={false}
                    submitting={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    value={formData.contact_phone_number}
                    onChange={handleChange('contact_phone_number')}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddressInput
                    value={formData.contact_address}
                    onChange={handleChange('contact_address')}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PositionInput
                    value={formData.contact_position}
                    onChange={handleChange('contact_position')}
                    disabled={false}
                    submitting={isSubmitting}
                    autocompleteRef={autocompleteRef}
                    popperRef={popperRef}
                  />
                </Grid>
              </Grid>

              <Grid container className={styles.checkbox_container}>
                <Grid item xs={6}>
                  <AuthorizedSignCheckbox
                    value={formData.is_authorized_signatory}
                    onChange={handleChange('is_authorized_signatory')}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ShareholderCheckbox
                    value={formData.is_shareholder}
                    onChange={handleChange('is_shareholder')}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <HiringManagerCheckbox
                    value={formData.is_hiring_manager}
                    onChange={handleChange('is_hiring_manager')}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LegalRepresentationCheckbox
                    value={formData.is_legal_representative}
                    onChange={handleChange('is_legal_representative')}
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Stack direction='row' gap={'8px'} justifyContent='flex-end' marginTop={'16px'}>
                <Button
                  variant='contained'
                  className='btn-default btn-action-basic btn-cancel'
                  onClick={onClose}
                >
                  {t('buttons.close')}
                </Button>
                <Button
                  variant='contained'
                  className='btn-default btn-action-basic'
                  onClick={handleCreate}
                >
                  {t('buttons.create')}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
}
