import React, { useEffect } from 'react';
import FeatureItems from '../../common/feature-items/FeatureItems';
import DemoAuthTemplate from '../../common/demo-auth-template/DemoAuthTemplate';
//import ClerkSignUp from '../signup/ClerkSignUp';
import SignUpForm from '../signup/SignUpForm';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { switchDesign } from '../../common/design-switch/designSwitchSlice';

const DemoSignUp = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(switchDesign(true));
  }, []);
  return (
    <DemoAuthTemplate featureItems={<FeatureItems />}>
      {/*<ClerkSignUp />*/}
      <SignUpForm />
    </DemoAuthTemplate>
  );
};

export default DemoSignUp;
