import React, { useState, useEffect, ChangeEvent } from 'react';
import { User } from '../../models/user.model';
import { NWClient } from '../../client/NWClient';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { ROUTES } from '../../common/constants/routes';
import { useNavigate } from 'react-router-dom';
import { Country, State } from 'country-state-city';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { fetchCompanies } from '../../slices/companies/companiesSlice';
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import DialogItem from '../../common/dialog-item/DialogItem';
import PlanInfoBlock from './PlanInfoBlock';
import { Price } from '../../models/price.model';
import { useTranslation } from 'react-i18next';
import { FORM_ITEMS } from '../../common/constants/form_items';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface Coupon {
  id: string;
  name: string;
  promo_code: string;
  percentage_amount: number | null;
  active: boolean;
  start_date: string;
  end_date: string;
  users: number[];
  fixed_amount: number | null;
  limit_of_user: number;
  after_limit_fixed_discount: number | null;
  after_limit_percentage_discount: number | null;
  updated_by: number | null;
  company: number[];
}

const ProductPlanConfirmation = () => {
  const [searchParams] = useSearchParams();
  const [product, setProduct] = useState(null);
  const productId = searchParams.get('productId');
  const priceId = searchParams.get('priceId');
  const [priceItem, setPriceItem] = useState(null);
  const [pendingStatus, setPendingStatus] = useState(true);
  const [company, setCompany] = useState(null);
  const [name, setName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [errorState, setErrorState] = useState(false);
  const [successState, setSuccessState] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('AU');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [couponCode, setCouponCode] = useState('');
  /*const [couponsArray, setCouponsArray] = useState<Coupon[]>([]);*/
  const [couponApplied, setCouponApplied] = useState<Coupon | null>(null);
  const [showSecret, setShowSecret] = useState(false);
  const { t } = useTranslation();
  const countries = Country.getAllCountries();
  const errorMessageTitle = t('messages.errorOccurred');
  const successMessageTitle = t('messages.operationMessage');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const canSubmit = Boolean(couponCode);
  /*(name && addressLine1 && country && city && postalCode) ||
    (couponApplied && couponApplied.percentage_amount && couponApplied.percentage_amount === 100);*/

  const handleClickShowSecret = () => setShowSecret(!showSecret);
  const handleMouseDownSecret = () => setShowSecret(!showSecret);

  const checkingCouponCode = (coupons: Coupon[] = []) => {
    const couponSelected = coupons.find(
      (el) =>
        el.promo_code === couponCode &&
        el.active &&
        new Date(el.end_date) > new Date() &&
        el.limit_of_user > el.users.length
    );
    if (couponSelected) {
      setCouponApplied(couponSelected);
      setSuccessState(true);
      setSuccessText(t('messages.couponDiscountApplied'));
    } else {
      setErrorState(true);
      setErrorText(t('messages.promoCodeNotValid'));
    }
  };

  const subscribeWithPaymentDetails = async (stripe: Stripe, elements: StripeElements) => {
    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          line1: addressLine1,
          line2: addressLine2,
          country,
          state,
          city,
          postal_code: postalCode,
        },
        name,
        email,
      },
    });

    if (error) {
      setPendingStatus(false);
      setErrorState(true);
      setErrorText(error.message);
    } else {
      const data = {
        payment_method: paymentMethod.id,
        price_id: priceId,
        company_id: company.id,
        quantity: 1,
        promo_code: couponApplied ? couponApplied.promo_code : null,
      };

      NWClient.post('stripe/subscription/subscribe', data)
        .then(() => {
          dispatch(fetchCompanies()).then(() => {
            navigate(ROUTES.INDEX);
          });
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : error.message
              ? error.message
              : error.request.statusText;
          setErrorState(true);
          setErrorText(errorMessage);
        })
        .finally(() => {
          setPendingStatus(false);
        });
    }
  };

  const subscribeWithoutPaymentDetails = () => {
    const data = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      payment_method: null,
      price_id: priceId,
      company_id: company.id,
      quantity: 1 /*+ (company.members.length - 1)*/,
      promo_code: couponCode.trim() /*couponApplied ? couponApplied.promo_code : null,*/,
    };

    NWClient.post('stripe/subscription/subscribe', data)
      .then(() => {
        dispatch(fetchCompanies()).then(() => {
          window.location.href = ROUTES.INDEX; //temporary fix
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
            ? error.message
            : error.request.statusText;
        setErrorState(true);
        setErrorText(errorMessage);
        setPendingStatus(false);
      });
  };

  const resetCouponCode = () => {
    setCouponApplied(null);
    setCouponCode('');
  };

  const applyCouponCode = () => {
    setPendingStatus(true);
    /*if (couponsArray.length === 0) {*/
    NWClient.list('coupon', { promo_code: couponCode })
      .then((res: Coupon[]) => {
        //setCouponsArray(res);
        checkingCouponCode(res);
      })
      .catch((error) => {
        setErrorState(true);
        setErrorText(error.message);
      })
      .finally(() => {
        setPendingStatus(false);
      });
    /*} else {
      checkingCouponCode(couponsArray);
      setPendingStatus(false);
    }*/
  };

  const handleSubmit = (/*stripe: Stripe, elements: StripeElements*/) => {
    setPendingStatus(true);
    if (couponCode) {
      subscribeWithoutPaymentDetails();
    }
    /*if (!canSubmit) {
      setPendingStatus(false);
      setErrorState(true);
      setErrorText(t('messages.fillInRequiredFields'));
    } else {
      if(couponCode){
        subscribeWithoutPaymentDetails();
      }

      if (
        couponApplied &&
        couponApplied.percentage_amount &&
        couponApplied.percentage_amount === 100
      ) {
        subscribeWithoutPaymentDetails();
      } else {
        subscribeWithPaymentDetails(stripe, elements);
      }
    }*/
  };

  const getInfo = async () => {
    NWClient.currentUser().then((user: User) => {
      setEmail(user.email);
      NWClient.list('company')
        .then((res) => {
          if (res.length > 0) {
            setCompany(res[0]);
            setPendingStatus(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            navigate(ROUTES.LOGIN);
          }
        });
    });
  };

  useEffect(() => {
    getInfo();
  }, [navigate, productId]);
  useEffect(() => {
    if (product && Object.keys(product).includes('prices')) {
      setPriceItem(product.prices.find((el: Price) => el.id === priceId));
    }
  }, [product, priceId]);
  return (
    <>
      <h1 className='text-center mw-1100 mb-4'>{t('planConfirmation.header')}</h1>
      <div className='plan-info-container d-flex justify-content-center'>
        <div className='plan-description-item'>
          {/*<div className='header-row'>
            <h2>{t('labels.yourCoupon')}</h2>
          </div>*/}
          <TextField
            label={t('labels.enterYourCouponCode')}
            type={showSecret ? 'text' : 'password'}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setCouponCode(e.target.value)}
            name='couponNumber'
            value={couponCode}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowSecret}
                    onMouseDown={handleMouseDownSecret}
                  >
                    {showSecret ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/*<div className='buttons-row'>
            <Button type='button' size='medium' onClick={() => resetCouponCode()}>
              {t('buttons.resetCouponCode')}
            </Button>
            <Button
              disabled={!couponCode}
              type='button'
              variant='contained'
              size='medium'
              onClick={() => applyCouponCode()}
            >
              {t('buttons.applyCouponCode')}
            </Button>
          </div>

          <div className='header-row'>
            <h2>{t('planConfirmation.yourPlan')}</h2>
          </div>

            <PlanInfoBlock
              companyName={company ? company.name : ''}
              companySubscription={company ? company.stripe_subscription : null}
              productName={product ? product.name : ''}
              priceId={priceId}
              interval={priceItem && priceItem.recurring ? priceItem.recurring.interval : ''}
              employeesCount={company && company.members ? company.members.length - 1 : 0}
              //humanReadablePrice={priceItem ? priceItem.humanReadablePrice : ''}
              humanReadablePrice={
                priceItem && priceItem.recurring && priceItem.recurring.interval
                  ? `${(priceItem.unitAmount / 100).toFixed(
                      2
                    )} ${priceItem.currency.toUpperCase()} ${t('planConfirmation.perUserItem')}${
                      priceItem.recurring.interval
                    }`
                  : ''
              }
              trialPeriodDays={
                priceItem && priceItem.recurring ? priceItem.recurring.trialPeriodDays : null
              }
              unitAmount={priceItem ? priceItem.unitAmount : 0}
              currency={priceItem ? priceItem.currency : ''}
              discountPercent={couponApplied ? couponApplied.percentage_amount : 0}
              discountAmount={couponApplied ? couponApplied.fixed_amount : 0}
            />*/}

          <div className='buttons-row'>
            <Button
              type='button'
              variant='contained'
              size='medium'
              disabled={!canSubmit}
              onClick={() => handleSubmit(/*stripe, elements*/)}
            >
              {t('buttons.confirm')}
            </Button>
          </div>
          <div className='disclaimer'>
            {t('messages.acknowledgeAccept')}{' '}
            <a
              href='https://businesskitz.com.au/terms-and-conditions/'
              rel='noreferrer'
              target='_blank'
            >
              {t('messages.termsAndConditions')}
            </a>
            .
          </div>
        </div>
      </div>
      {pendingStatus && <ProgressOverlay />}

      <DialogItem
        isErrorMessage={errorState}
        open={errorState || successState}
        title={(errorState && errorMessageTitle) || (successState && successMessageTitle)}
        text={(errorState && errorText) || (successState && successText)}
        handleClose={() => {
          setErrorState(false);
          setSuccessState(false);
        }}
      />
    </>
  );
};

export default ProductPlanConfirmation;
