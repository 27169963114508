import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { DocumentEditButtonProps } from './types';
import { useDocumentEditButton } from './useDocumentEditButton';
import VerticalDotsIcon from '../../../static/assets/svg/more_vert.svg';

export const DocumentsEditButton = ({
  getDocumentSummaryFn,
  documentVersionsFn,
  deleteDocumentFn,
  documentLabelsFn,
  createTemplateFn,
  document,
}: DocumentEditButtonProps) => {
  const { t } = useTranslation();
  const { anchorEl, setAnchorEl } = useDocumentEditButton();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDocumentSummary = () => {
    getDocumentSummaryFn(document);
    handleClose();
  };
  const handleDocumentCreateTemplate = () => {
    createTemplateFn(document);
    handleClose();
  };
  const handleDocumentDelete = () => {
    deleteDocumentFn(document);
    handleClose();
  };
  const handleDocumentVersions = () => {
    documentVersionsFn(document);
    handleClose();
  };
  const handleDocumentLabels = () => {
    documentLabelsFn(document);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <VerticalDotsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleDocumentSummary();
          }}
        >
          <span> {t('buttons.summary')}</span>
        </MenuItem>
        {!document.contract_generation && (
          <MenuItem
            onClick={() => {
              handleDocumentCreateTemplate();
            }}
          >
            <span> {t('buttons.createTemplate')}</span>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleDocumentVersions();
          }}
        >
          <span>{t('buttons.versions')}</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDocumentLabels();
          }}
        >
          <span> {t('buttons.labels')}</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDocumentDelete();
          }}
        >
          <span> {t('buttons.delete')}</span>
        </MenuItem>
      </Menu>
    </>
  );
};
