import { Card, CardContent, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import DocumentList from '../../../../common/document-list/DocumentList';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { selectCustomerById } from '../../../../slices/customers/customersSlice';
import { Company } from '../../../../models/company.model';
import CustomerDetailsCard from '../customer-details-card/CustomerDetailsCard';

interface NewCustomerDetailsTabProps {
  company: Company;
}

const NewCustomerDetailsTab: React.FC<NewCustomerDetailsTabProps> = ({ company }) => {
  const { customerId } = useParams();
  const numericCustomerId = Number(customerId);

  const customer = useSelector((state: RootState) => selectCustomerById(state, numericCustomerId));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <CustomerDetailsCard company={company} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NewCustomerDetailsTab;
