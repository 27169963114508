import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ToDo } from '../../models/todo.model';
import { NWClient } from '../../client/NWClient';
import { RootState } from '../../store';

interface ToDoState {
  items: ToDo[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ToDoState = {
  items: [],
  status: 'idle',
  error: null,
};

export const fetchTodos = createAsyncThunk('todos/fetchTodos', async () => {
  return await NWClient.list<ToDo>('todos');
});

export const addTodo = createAsyncThunk(
  'todos/addTodo',
  async ({ data }: { data: Omit<ToDo, 'id'> }) => {
    return await NWClient.post<ToDo>('todos', data);
  }
);

export const updateTodo = createAsyncThunk(
  'todos/updateTodo',
  async ({ id, data }: { id: number; data: Omit<ToDo, 'id'> }) => {
    return await NWClient.put<ToDo>('todos', id, data, true);
  }
);

export const deleteTodo = createAsyncThunk('todos/deleteTodo', async ({ id }: { id: number }) => {
  await NWClient.delete('todos', id);
  return id;
});

const todoSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTodos.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTodos.fulfilled, (state, action: PayloadAction<ToDo[]>) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchTodos.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch todos';
      })
      .addCase(addTodo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addTodo.fulfilled, (state, action: PayloadAction<ToDo>) => {
        state.items.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(addTodo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch todos';
      })
      .addCase(updateTodo.fulfilled, (state, action: PayloadAction<ToDo>) => {
        const index = state.items.findIndex((todo) => todo.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
        state.status = 'succeeded';
      })
      .addCase(updateTodo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch todos';
      })
      .addCase(deleteTodo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteTodo.fulfilled, (state, action: PayloadAction<number>) => {
        state.items = state.items.filter((todo) => todo.id !== action.payload);
        state.status = 'succeeded';
      })
      .addCase(deleteTodo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch todos';
      });
  },
});

export const selectTodos = (state: RootState) => state.todos.items;
export const selectTodoStatus = (state: RootState) => state.todos.status;
export const selectTodoError = (state: RootState) => state.todos.error;

export default todoSlice.reducer;
