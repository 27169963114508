import React from 'react';
import { Divider, Tab, Tabs } from '@mui/material';
import Settings from '../../../../../static/assets/svg/menu/settings.svg';
import { Helpers } from '../../../../common/helpers/helpers';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { selectUser } from '../../../../slices/user/userSlice';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PricingIcon from '../../../../../static/assets/svg/menu/pricing.svg';
import CompanySettingsIcon from '../../../../../static/assets/svg/menu/business-icon.svg';
import { useTranslation } from 'react-i18next';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';

interface SettingsMenuProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const SettingsMenu = ({ value, handleChange }: SettingsMenuProps) => {
  const user = useAppSelector(selectUser);
  const userIsAnEmployee = user.groups.some((el) => el.name === 'Company employee');
  const { t } = useTranslation();
  return (
    <div className='settings-menu'>
      <div className='settings-menu-header'>
        <Settings />
        <h3>{t('settings.title')}</h3>
      </div>
      <Divider flexItem={true} />
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='navigation'
          orientation='vertical'
          role='navigation'
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          tabIndex={0}
        >
          {/*<Tab
            disableFocusRipple
            disableRipple
            iconPosition='start'
            className='settings-menu-tab'
            icon={
              <div className={'settings-menu-icon-container'}>
                <AccountCircleIcon fontSize='medium' color='primary' />
              </div>
            }
            label={t('settings.titles.account')}
            {...Helpers.a11yProps(0, 'vertical')}
          />*/}
          <Tab
            disableFocusRipple
            disableRipple
            iconPosition='start'
            className='settings-menu-tab'
            icon={
              <div className={'settings-menu-icon-container'}>
                <CompanySettingsIcon />
              </div>
            }
            label={t('settings.titles.business')}
            {...Helpers.a11yProps(0, 'vertical')}
          />
          {!userIsAnEmployee && (
            <Tab
              disableFocusRipple
              disableRipple
              iconPosition='start'
              className='settings-menu-tab child-item'
              label={t('settings.titles.companyContacts')}
              {...Helpers.a11yProps(1, 'vertical')}
            />
          )}
          {!userIsAnEmployee && (
            <Tab
              disableFocusRipple
              disableRipple
              iconPosition='start'
              className='settings-menu-tab'
              icon={
                <div className={'settings-menu-icon-container'}>
                  <PricingIcon />
                </div>
              }
              label={t('settings.titles.plan')}
              {...Helpers.a11yProps(2, 'vertical')}
            />
          )}
        </Tabs>
      </div>
    </div>
  );
};
