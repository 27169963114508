import React, { useEffect, useRef, useState } from 'react';
import { useDocuments } from './hooks/useDocuments';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import styles from './scss/DocumentVault.module.scss';
import { Box, Grid, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import UploadButton from '../../common/upload-button/UploadButton';
import FormUpload from '../../common/form-upload/FormUpload';
import Pagination from '../../common/pagination/Pagination';
import SearchField from '../../common/search-field/SearchField';
import FilterDrawer from '../../common/filter-drawer/FilterDrawer';
import DocumentListVault from './components/document-list-vault/DocumentListVault';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useFileTypeDownload } from '../../common/file-type-download-modal/useFileTypeDownload';
import { useTranslation } from 'react-i18next';
import { selectAllEmployees } from '../../slices/employees/employeesSlice';
import { selectAllSuppliers } from '../../slices/suppliers/suppliersSlice';
import { selectAllCustomers } from '../../slices/customers/customersSlice';
import { selectBusinessProfileContacts } from '../../slices/business-profile/businessProfileSlice';

const DocumentVault = () => {
  /********* To refactor **************/
  const { t } = useTranslation();
  const employees = useAppSelector(selectAllEmployees);
  const suppliers = useAppSelector(selectAllSuppliers);
  const customers = useAppSelector(selectAllCustomers);
  const businessContacts = useAppSelector(selectBusinessProfileContacts);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const formUploadRef = useRef<HTMLDivElement>(null);
  const uploadButtonRef = useRef<HTMLButtonElement>(null);
  const handleUploadClose = () => {
    setIsUploadOpen(false);
  };

  const handleUploadOpen = () => {
    setIsUploadOpen(true);
  };

  const {
    documents,
    documentContractsLoading,
    documentUploadsLoading,
    customersLoading,
    suppliersLoading,
    employeesLoading,
    businessProfileLoading,
    handleTabChange,
    activeTab,
  } = useDocuments();
  const { pendingStatus: downloadPendingStatus } = useFileTypeDownload();

  /********* To refactor later*************/

  return (
    <>
      <Grid container spacing={3} sx={{ mt: '-100px' }}>
        <Grid item xs={12} sx={{ pt: '32px !important' }}>
          <Stack spacing={0} sx={{ width: '50%', marginBottom: '20px' }}>
            <Typography variant='h5' component='h1' className={styles.page_title}>
              {t('headers.documentVault')}
            </Typography>
            <Stack direction='row' marginTop={'8px'} spacing={2}>
              <Box ref={uploadButtonRef}>
                <UploadButton
                  disabled={
                    customersLoading === 'loading' ||
                    suppliersLoading === 'loading' ||
                    employeesLoading === 'loading' ||
                    businessProfileLoading === 'loading'
                  }
                  onClick={handleUploadOpen}
                  buttonText='Upload'
                  isArrowDown={false}
                />
              </Box>
              {isUploadOpen && (
                <Box className={styles.form_box} ref={formUploadRef}>
                  <FormUpload
                    employees={employees}
                    suppliers={suppliers}
                    customers={customers}
                    businessContacts={businessContacts}
                    categoriesAsOption={true}
                    onCancel={handleUploadClose}
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    // @ts-ignore
                    categoryValue={activeTab === 'All' ? '' : activeTab.toLowerCase()}
                  />
                </Box>
              )}
            </Stack>
          </Stack>
          <Box className={styles.document_header}>
            <Typography variant='h6' component='h2'>
              {t('menu.documents')}
            </Typography>
            <Box className={styles.actions_group}>
              {/*<Pagination page={page} totalPages={1} onPageChange={handlePageChange} />
              <SearchField
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder='Search for document'
              />
              <FilterDrawer sections={filterSections} onApplyFilters={handleApplyFilters} />*/}
            </Box>
          </Box>

          <Box className={styles.tab_container}>
            <Box className={styles.tabs_section}>
              <Tabs
                value={activeTab}
                onChange={(_, newValue) => handleTabChange(newValue)}
                aria-label='document categories'
                className={styles.tabs}
              >
                <Tab label='All' value='All' />
                <Tab label='Business' value='Business' />
                <Tab label='Employees' value='Employment' />
                <Tab label='Customers' value='Customers' />
                <Tab label='Suppliers' value='Suppliers' />
              </Tabs>
            </Box>
          </Box>

          <Box sx={{ mt: '15px', position: 'relative' }}>
            <DocumentListVault documents={documents} />
            {(documentUploadsLoading === 'loading' ||
              documentContractsLoading === 'loading' ||
              customersLoading === 'loading' ||
              employeesLoading === 'loading' ||
              businessProfileLoading === 'loading' ||
              suppliersLoading === 'loading') && (
              <ProgressOverlay className={'position-absolute'} />
            )}
          </Box>
        </Grid>
      </Grid>
      {downloadPendingStatus && <ProgressOverlay />}
    </>
  );
};

export default DocumentVault;
