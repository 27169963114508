import React from 'react';
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../common/constants/routes';
import { PRODUCT_PLANS_LINKS } from '../../common/constants/stripe_links';
import { EXTERNAL_LINKS } from '../../common/constants/external_urls';

const PlanPricingTable = (props: {
  paymentLinkParams: string;
  redirectToCompanyDetails: boolean;
}) => {
  const { t } = useTranslation();
  const { paymentLinkParams, redirectToCompanyDetails } = props;

  return (
    <TableContainer sx={{ overflowX: 'initial' }} component={Paper}>
      <Table stickyHeader className='price-options-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 205 }}>
              <div className='plan-header-description d-flex flex-wrap bg-white'>
                <div className='plan-header-top-part mt-auto mb-0'>
                  <div className='plan-title'>{t('planPageMiscellaneous.features')}</div>
                  <div className='monthly-price regular'>
                    {t('planPageMiscellaneous.whatYouWillGet')}
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell style={{ maxWidth: 180 }}>
              <div className='plan-header-description d-flex flex-wrap disabled'>
                <div className='plan-header-top-part'>
                  <div className='plan-title'>{t('pricingOptionNames.free')}</div>
                  <div className='monthly-price'>
                    {t('pricingOptionsDescription.free.priceMonth')}
                  </div>
                </div>
                <div className='plan-header-bottom-part'>
                  <div className='d-flex justify-content-center'>
                    <span className='d-inline-flex justify-content-center align-items-center plan-link disabled'>
                      {t('subscriptionPlanControls.free')}
                    </span>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell style={{ minWidth: 200 }}>
              <div className='plan-header-description d-flex flex-wrap'>
                <div className='plan-header-top-part'>
                  <div className='plan-title'>{t('pricingOptionNames.businessStarter')}</div>
                  <div className='monthly-price'>
                    {t('pricingOptionsDescription.businessStarter.priceMonth')}
                  </div>
                  <div className='price-details'>
                    {t('pricingOptionsDescription.businessStarter.priceYear')}
                  </div>
                </div>
                <div className='plan-header-bottom-part'>
                  <div className='plan-details text-center'>
                    {t('pricingOptionsDescription.businessStarter.description')}
                  </div>
                  <div className='d-flex justify-content-center'>
                    <a
                      className='d-inline-flex justify-content-center align-items-center plan-link'
                      href={
                        redirectToCompanyDetails
                          ? ROUTES.COMPANY_CREATE + paymentLinkParams + `&redirectToStripe=true`
                          : (!process.env.ENV_TYPE || process.env.ENV_TYPE !== 'PROD'
                              ? PRODUCT_PLANS_LINKS.TEST.BUSINESS_STARTER
                              : PRODUCT_PLANS_LINKS.PROD.BUSINESS_STARTER) + paymentLinkParams
                      }
                    >
                      {t('buttons.getStarted')}
                    </a>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell style={{ minWidth: 208 }}>
              <div className='plan-header-description d-flex flex-wrap'>
                <div className='plan-header-top-part'>
                  <div className='plan-title'>{t('pricingOptionNames.businessEssentials')}</div>
                  <div className='monthly-price'>
                    {t('pricingOptionsDescription.businessEssentials.priceEmployee')}
                  </div>
                  <div className='price-details'>
                    {t('pricingOptionsDescription.businessEssentials.priceMonth')}
                  </div>
                  <div className='plan-label d-inline-flex'>
                    {t('planPageMiscellaneous.mostPopular')}
                  </div>
                </div>
                <div className='plan-header-bottom-part'>
                  <div className='plan-details text-center'>
                    {t('pricingOptionsDescription.businessEssentials.description')}
                  </div>
                  <div className='d-flex justify-content-center'>
                    <a
                      className='d-inline-flex justify-content-center align-items-center plan-link'
                      href={
                        redirectToCompanyDetails
                          ? ROUTES.COMPANY_CREATE + paymentLinkParams + `&redirectToStripe=true`
                          : (!process.env.ENV_TYPE || process.env.ENV_TYPE !== 'PROD'
                              ? PRODUCT_PLANS_LINKS.TEST.BUSINESS_ESSENTIALS
                              : PRODUCT_PLANS_LINKS.PROD.BUSINESS_ESSENTIALS) + paymentLinkParams
                      }
                    >
                      {t('buttons.getStarted')}
                    </a>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell style={{ minWidth: 210 }}>
              <div className='plan-header-description d-flex flex-wrap'>
                <div className='plan-header-top-part'>
                  <div className='plan-title'>{t('pricingOptionNames.businessGrowth')}</div>
                  <div className='monthly-price'>
                    {t('pricingOptionsDescription.businessGrowth.priceEmployee')}
                  </div>
                  <div className='price-details'>
                    {t('pricingOptionsDescription.businessGrowth.priceMonth')}
                  </div>
                  <div className='plan-label d-inline-flex'>
                    {t('planPageMiscellaneous.recommended')}
                  </div>
                </div>
                <div className='plan-header-bottom-part'>
                  <div className='plan-details text-center'>
                    {t('pricingOptionsDescription.businessGrowth.description')}
                  </div>
                  <div className='d-flex justify-content-center'>
                    <a
                      className='d-inline-flex justify-content-center align-items-center plan-link'
                      href={
                        redirectToCompanyDetails
                          ? ROUTES.COMPANY_CREATE + paymentLinkParams + `&redirectToStripe=true`
                          : (!process.env.ENV_TYPE || process.env.ENV_TYPE !== 'PROD'
                              ? PRODUCT_PLANS_LINKS.TEST.BUSINESS_GROWTH
                              : PRODUCT_PLANS_LINKS.PROD.BUSINESS_GROWTH) + paymentLinkParams
                      }
                    >
                      {t('buttons.getStarted')}
                    </a>
                  </div>
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.employmentLibrary')}</div>
                <div className='feature-description'>
                  ({t('applicationFeaturesDescription.documentsDraftedByLawyers')})
                </div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <div className='feature-value'>2 {t('planPageMiscellaneous.documents')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>2 {t('planPageMiscellaneous.documents')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.fullAccess')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.fullAccess')}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.businessLibrary')}</div>
                <div className='feature-description'>
                  ({t('applicationFeaturesDescription.highQualityTemplates')})
                </div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <div className='feature-value'>2 {t('planPageMiscellaneous.documents')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>5 {t('planPageMiscellaneous.documents')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.fullAccess')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.fullAccess')}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.customerLibrary')}</div>
                <div className='feature-description'>
                  ({t('applicationFeaturesDescription.documentsDraftedByLawyers')})
                </div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <div className='feature-value'>2 {t('planPageMiscellaneous.documents')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>5 {t('planPageMiscellaneous.documents')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.fullAccess')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.fullAccess')}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.manageEmployees')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <div className='feature-value highlighted'>
                2 {t('planPageMiscellaneous.included')}
              </div>
            </TableCell>
            <TableCell>
              <div className='feature-value highlighted'>
                10 {t('planPageMiscellaneous.included')}
              </div>
            </TableCell>
            <TableCell>
              <div className='feature-value highlighted'>
                20 {t('planPageMiscellaneous.included')}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.manageSuppliers')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <div className='feature-value'>5 {t('planPageMiscellaneous.included')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>20 {t('planPageMiscellaneous.included')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>50 {t('planPageMiscellaneous.included')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.unlimited')}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.manageCustomers')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <div className='feature-value'>5 {t('planPageMiscellaneous.included')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>20 {t('planPageMiscellaneous.included')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>50 {t('planPageMiscellaneous.included')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.unlimited')}</div>
            </TableCell>
          </TableRow>
          {/* LegalKitz */}
          <TableRow
            className='legal-kitz'
            onClick={() => {
              window.open(EXTERNAL_LINKS.LEGAL_KITZ, '_blank');
            }}
          >
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('legalKitz.legalDocument')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <div className='feature-value highlighted'>
                2{t('planPageMiscellaneous.perMonth')}
              </div>
            </TableCell>
            <TableCell>
              <div className='feature-value highlighted'>
                5{t('planPageMiscellaneous.perMonth')}
              </div>
            </TableCell>
          </TableRow>
          <TableRow
            className='legal-kitz'
            onClick={() => {
              window.open(EXTERNAL_LINKS.LEGAL_KITZ, '_blank');
            }}
          >
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('legalKitz.callLegalKitz')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <div className='feature-value highlighted'>
                15 {t('planPageMiscellaneous.minPerMonth')}
              </div>
            </TableCell>
            <TableCell>
              <div className='feature-value highlighted'>
                30 {t('planPageMiscellaneous.minPerMonth')}
              </div>
            </TableCell>
          </TableRow>
          <TableRow
            className='legal-kitz'
            onClick={() => {
              window.open(EXTERNAL_LINKS.LEGAL_KITZ, '_blank');
            }}
          >
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('legalKitz.discount')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <div className='feature-value highlighted'>
                10% {t('planPageMiscellaneous.discount')}
              </div>
            </TableCell>
            <TableCell>
              <div className='feature-value highlighted'>
                15% {t('planPageMiscellaneous.discount')}
              </div>
            </TableCell>
            <TableCell>
              <div className='feature-value highlighted'>
                25% {t('planPageMiscellaneous.discount')}
              </div>
            </TableCell>
          </TableRow>
          {/* LegalKitz */}
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>
                  {t('applicationFeatures.createYourOwnDocuments')}
                </div>
                <div className='feature-description'>
                  ({t('applicationFeaturesDescription.withAI')})
                </div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <div className='feature-value'>3/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>10/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>50/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.unlimited')}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.documentSummary')}</div>
                <div className='feature-description'>
                  ({t('applicationFeaturesDescription.withAI')})
                </div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <div className='feature-value'>5/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>20/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>50/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.unlimited')}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.createTemplates')}</div>
                <div className='feature-description'>
                  ({t('applicationFeaturesDescription.withAI')})
                </div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <div className='feature-value'>5/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>20/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>50/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.unlimited')}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.signDocuments')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <div className='feature-value'>5/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>20/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>50/{t('planPageMiscellaneous.month')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>{t('planPageMiscellaneous.unlimited')}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.storeDocuments')}</div>
                <div className='feature-description'>
                  ({t('applicationFeaturesDescription.intelligentDocumentStorage')})
                </div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <div className='feature-value'>0.25 {t('planPageMiscellaneous.gb')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>2.5 {t('planPageMiscellaneous.gb')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>10 {t('planPageMiscellaneous.gb')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>25 {t('planPageMiscellaneous.gb')}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.libraryLegalReview')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <div className='feature-value'>1/{t('planPageMiscellaneous.year')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>3/{t('planPageMiscellaneous.year')}</div>
            </TableCell>
            <TableCell>
              <div className='feature-value'>3/{t('planPageMiscellaneous.year')}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' colSpan={5}>
              <div className='general-header-wrapper'>
                <div className='header-value'>{t('planPageMiscellaneous.comingSoon')}</div>
                <div className='header-description'>
                  {t('planPageMiscellaneous.stagedReleasesDuring2025')}
                </div>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.userManagement')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.invoiceManagement')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>
                  {t('applicationFeatures.manageTaskWithReminders')}
                </div>
                <div className='feature-description'>
                  ({t('applicationFeaturesDescription.toDoList')})
                </div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>
                  {t('applicationFeatures.autoPilotContractManagement')}
                </div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' scope='row'>
              <div className='feature-info-wrapper'>
                <div className='feature-title'>{t('applicationFeatures.collaborateAndEdit')}</div>
              </div>
            </TableCell>
            <TableCell valign='middle'>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <CloseOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
            <TableCell>
              <CheckOutlinedIcon />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlanPricingTable;
