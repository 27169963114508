import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import CustomerContactItem from './components/CustomerContactItem';
import Button from '@mui/material/Button';
import { CustomerContact } from '../../models/customer.contact.model';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { fetchCompanies, selectAllCompanies } from '../../slices/companies/companiesSlice';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';

const CustomerContactsList = () => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectAllCompanies)[0];
  const customer = company?.customers.find((el) => el.id === Number(customerId));
  const [contactsList, setContactsList] = useState<CustomerContact[]>(
    customer ? customer.contact_details.slice() : []
  );
  const [allowAddingContacts, setAllowAddingContacts] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const addContact = () => {
    setAllowAddingContacts(false);
    const data = {
      detail_type: '',
      detail: '',
      customer_id: Number(customerId),
    };
    const dataArray = contactsList.slice();
    dataArray.push(data);
    setContactsList(dataArray);
  };
  const setContacts = () => {
    if (customer) {
      setContactsList(customer.contact_details.slice());
    }
  };

  useEffect(() => {
    if (company) {
      setContacts();
      setLoading(false);
    } else {
      dispatch(fetchCompanies())
        .then(() => {
          setContacts();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [company, dispatch]);
  return (
    <>
      {loading ? (
        <Grid container justifyContent='center'>
          <ProgressOverlay className='position-relative' />
          {/*<CircularProgress/>*/}
        </Grid>
      ) : (
        <div className='profile-details-wrapper'>
          <Grid
            container
            item
            className='setting-panel-header justify-content-between'
            alignItems='center'
          >
            <div className='d-flex align-items-start header'>
              <ContactsOutlinedIcon />
              <h2>{t('customerTabs.contactDetails')}</h2>
            </div>
            <div className='d-flex flex-wrap buttons'>
              {!(contactsList.length > 0) && (
                <Button
                  onClick={() => {
                    addContact();
                    setEditMode(true);
                  }}
                  type='button'
                  role='button'
                  variant='contained'
                  size='large'
                >
                  {t('buttons.addContactDetails')}
                </Button>
              )}
              {customer.contact_details.length > 0 && (
                <Button
                  style={{ marginLeft: '0.5rem' }}
                  variant='contained'
                  size='large'
                  onClick={() => {
                    if (editMode) {
                      setContactsList(customer.contact_details.slice());
                      setEditMode(false);
                    } else {
                      setEditMode(true);
                      setAllowAddingContacts(true);
                    }
                  }}
                >
                  {!editMode ? t('buttons.edit') : t('buttons.viewMode')}
                </Button>
              )}
            </div>
          </Grid>
          {contactsList.map((el, i) => (
            <CustomerContactItem
              key={el.id || 'contact' + i}
              editMode={editMode}
              contactInfo={el}
              onSave={() => setAllowAddingContacts(true)}
            />
          ))}
          {allowAddingContacts && editMode && contactsList.length > 0 && (
            <Grid
              container
              className='buttons-row pt-3 pb-3'
              style={{ paddingLeft: '1.75rem', paddingRight: '1.75rem' }}
              justifyContent='flex-end'
            >
              <Button
                onClick={() => addContact()}
                type='button'
                role='button'
                variant='contained'
                size='large'
              >
                {t('buttons.addContactDetails')}
              </Button>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default CustomerContactsList;
