import React, { ChangeEvent, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import DialogItem from '../dialog-item/DialogItem';
import { Divider, Grid, Modal, Card, CardContent, FormControl } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector } from '../hooks/useAppSelector';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { CompanyMember } from '../../models/member.model';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { Supplier } from '../../models/supplier.model';
import { Customer } from '../../models/customer.model';
import { selectUser } from '../../slices/user/userSlice';
import styles from './ContractGenerator.module.scss';
import { LibraryItem } from '../../pages/library/components/library-list/types';
import { ContractGeneratorProps } from './types';
import { ContractGeneratorData } from './types';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useContractsGenerator } from './hooks/useContractsGenerator';
import { PacmanLoaderBlock } from '../pacman-loader';
import MessageModal from '../message-modal/MessageModal';
import { ROUTES } from '../constants/routes';
import { useNavigate } from 'react-router-dom';

const ContractGeneratorDialog = ({
  open,
  onClose,
  templateTitle,
  templateId,
  templateLabels,
  libraryItem,
}: ContractGeneratorProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { name } = useParams();
  const company = useAppSelector(selectAllCompanies)[0];
  const currentUser = useAppSelector(selectUser);
  const employees: CompanyMember[] = company?.members || [];
  const employeesFiltered = employees.filter((el) =>
    el.groups.every((item) => item.name !== 'Company admin')
  );
  const employeesSorted = employeesFiltered.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );
  const suppliers: Supplier[] = company?.suppliers || [];
  const suppliersSorted = suppliers.slice().sort((a, b) => a.name.localeCompare(b.name));
  const customers: Customer[] = company?.customers || [];
  const customersSorted = customers
    .slice()
    .sort((a, b) => a.customer_name.localeCompare(b.customer_name));
  const {
    secondParty,
    handleGenerateTemplate,
    setSecondParty,
    secondPartyCategory,
    setSecondPartyCategory,
    analysisLoading,
    planUpgradingMessage,
    setPlanUpgradingMessage,
  } = useContractsGenerator();

  const getSecondParty = () => {
    switch (secondPartyCategory) {
      case 'employment':
        return employeesSorted;
      case 'customers':
        return customersSorted;
      case 'suppliers':
        return suppliersSorted;
      case 'business':
        return [];
    }
  };

  const getSecondPartyName = (option: {
    first_name?: string;
    last_name?: string;
    name?: string;
    customer_name?: string;
  }) => {
    switch (secondPartyCategory) {
      case 'employment':
        return option.first_name + ' ' + option.last_name;
      case 'customers':
        return option.customer_name;
      case 'suppliers':
        return option.name;
      case 'business':
        return '';
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose} closeAfterTransition className='action-modal'>
        <Grid container className={styles.modal_container}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <CardContent sx={{ paddingBottom: '16px !important' }}>
                <Grid container item className='setting-panel-header pt-2 pb-2 ps-0 pe-0'>
                  <h4>{t('contractGeneratorDialog.generatorTitle')}</h4>
                  <Tooltip title={t('messages.contractGeneratorGeneralMessage')} placement='top'>
                    <InfoIcon fontSize='small' color='secondary' />
                  </Tooltip>
                </Grid>
                <Grid container item className='setting-panel-header pt-2 pb-2 ps-0 pe-0'>
                  <h5 className='fw-normal'>{templateTitle}</h5>
                </Grid>
                {templateLabels && (
                  <Grid container item className='pt-1 pb-1 d-flex flex-wrap align-items-start'>
                    {templateLabels && templateLabels.categoryLabel
                      ? templateLabels.categoryLabel + ' / '
                      : null}
                    {templateLabels && templateLabels.workflowLabel
                      ? templateLabels.workflowLabel + ' / '
                      : null}
                    {templateLabels && templateLabels.typeLabel ? templateLabels.typeLabel : null}
                  </Grid>
                )}
                <Grid container marginTop={'8px'} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl className='w-100'>
                      <InputLabel htmlFor='secondPartyCategorySelect'>
                        {t('labels.chooseProfile')}
                      </InputLabel>
                      <Select
                        className='w-100'
                        id='secondPartyCategorySelect'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setSecondPartyCategory(e.target.value);
                          setSecondParty('noSecondParty');
                        }}
                        name='secondPartyCategory'
                        value={secondPartyCategory}
                        tabIndex={-1}
                      >
                        <MenuItem value={'employment'}>{t('employees.header')}</MenuItem>
                        <MenuItem value={'customers'}>{t('Customers')}</MenuItem>
                        <MenuItem value={'suppliers'}>{t('documentVault.suppliers')}</MenuItem>
                        <MenuItem value={'business'}>{t('documentVault.business')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl className='w-100'>
                      <InputLabel htmlFor='secondPartySelect'>
                        {t('labels.chooseSecondParty')}
                      </InputLabel>
                      <Select
                        className='w-100'
                        id='secondPartySelect'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setSecondParty(e.target.value);
                        }}
                        name='secondParty'
                        value={secondParty}
                        tabIndex={-1}
                      >
                        <MenuItem key={'noSecondParty'} value={'noSecondParty'}>
                          {t('contractGeneratorDialog.noSecondParty')}
                        </MenuItem>
                        {getSecondParty().map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {getSecondPartyName(option)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container justifyContent='flex-end' gap={'8px'} mt={2}>
                  <Button
                    variant='contained'
                    onClick={onClose}
                    className='btn-default btn-action-basic btn-cancel'
                  >
                    {t('buttons.goBack')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => {
                      const data = {
                        user: currentUser?.id,
                        ['document_upload' in libraryItem
                          ? 'document_template'
                          : 'document_library']: libraryItem.id,
                        company_id: company?.id,
                        category: secondPartyCategory,
                      };
                      if (secondParty !== 'noSecondParty') {
                        data.secondary_id = Number(secondParty);
                      }
                      handleGenerateTemplate(libraryItem, data);
                    }}
                    className='btn-default btn-action-basic'
                  >
                    {t('buttons.next')}
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Modal>
      {analysisLoading && <PacmanLoaderBlock loading={analysisLoading} />}
      {planUpgradingMessage && (
        <MessageModal
          open={planUpgradingMessage}
          modalMessage={t('messages.monthLimitReached')}
          actionButtonText={t('buttons.ok')}
          actionButtonFn={() => navigate(ROUTES.PRICING)}
          goBackButtonText={t('buttons.later')}
          onClose={() => {
            setPlanUpgradingMessage(false);
          }}
        />
      )}
    </>
  );
};

export default ContractGeneratorDialog;
