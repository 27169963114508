import { Link } from 'react-router-dom';
import { LinkItemProps } from './types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const LinkItem = ({ children, url, expandedView, linkText, active, onClick }: LinkItemProps) => (
  <>
    {expandedView ? (
      <Link onClick={onClick} to={url} className={'main-nav-link' + (active ? ' active' : '')}>
        {children}
        <span className='main-nav-link-text'>{linkText}</span>
      </Link>
    ) : (
      <Tooltip title={linkText} placement='top' arrow>
        <Link onClick={onClick} to={url} className={'main-nav-link' + (active ? ' active' : '')}>
          {children}
        </Link>
      </Tooltip>
    )}
  </>
);

export default LinkItem;
