import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import {
  DocumentTemplateModel,
  DocumentTemplateParams,
  DocumentTemplateDeleteData,
  DocumentTemplateSearchParams,
} from '../../models/document.template.model';
import { RootState } from '../../store';

export const fetchDocumentTemplates = createAsyncThunk(
  'documentTemplates/fetchDocumentTemplates',
  async ({ data }: DocumentTemplateSearchParams) => {
    const response = await NWClient.list('document-template', data);
    return response;
  }
);

export const addDocumentTemplate = createAsyncThunk(
  'documentTemplates/addDocumentTemplate',
  async ({ data }: DocumentTemplateParams) => {
    const response = await NWClient.post('document-template', data, true);
    return response;
  }
);

export const deleteDocumentTemplate = createAsyncThunk(
  'documentContracts/deleteDocumentContract',
  async ({ id, userId }: DocumentTemplateDeleteData) => {
    const response = await NWClient.delete('document-template', id, null, { user: userId });
    return response;
  }
);

export interface DocumentTemplatesState {
  documentTemplatesList: DocumentTemplateModel[];
  status: string;
  error: string;
}

const documentTemplatesArray: DocumentTemplateModel[] = [];
const initialState: DocumentTemplatesState = {
  documentTemplatesList: documentTemplatesArray,
  status: 'idle',
  error: '',
};
const documentTemplatesSlice = createSlice({
  name: 'documentTemplates',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDocumentTemplates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDocumentTemplates.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (Array.isArray(action.payload)) {
          state.documentTemplatesList = [...action.payload];
        }
      })
      .addCase(fetchDocumentTemplates.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addDocumentTemplate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addDocumentTemplate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documentTemplatesList.push(action.payload as DocumentTemplateModel);
      })
      .addCase(addDocumentTemplate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteDocumentTemplate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteDocumentTemplate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documentTemplatesList = [...state.documentTemplatesList].filter(
          (el) => el.id !== action.meta.arg.id
        );
      })
      .addCase(deleteDocumentTemplate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default documentTemplatesSlice.reducer;

export const selectAllDocumentTemplates = (state: RootState) =>
  state.documentTemplates.documentTemplatesList;
