import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import UploadButton from '../../common/upload-button/UploadButton';
import FormUpload from '../../common/form-upload/FormUpload';
import AddIcon from '@mui/icons-material/Add';
import BasicTabs from '../../common/basic-tabs/BasicTabs';
import styles from './NewCustomerProfile.module.scss';
import { useTranslation } from 'react-i18next';
import DocumentsTabs from '../BusinessProfile/components/DocumentsTabs';
import NotesTab from '../../common/notes-tab/NotesTab';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';
import { fetchCustomers, selectCustomerById } from '../../slices/customers/customersSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import NewCustomerAllTab from './components/tabs/NewCustomerAllTab';
import NewCustomerDetailsTab from './components/tabs/NewCustomerDetailsTab';
import { NwBreadCrumbs } from '../../common/nw-breadcrumbs';
import { ROUTES } from '../../common/constants/routes';

const NewCustomerProfile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { customerId } = useParams();

  const company = useAppSelector(selectAllCompanies)[0];
  const numericCustomerId = Number(customerId);
  const navigate = useNavigate();

  const customer = useSelector((state: RootState) => selectCustomerById(state, numericCustomerId));
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const uploadButtonRef = useRef<HTMLButtonElement>(null);
  const formUploadRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(switchDesign(true));
  }, [dispatch]);

  useEffect(() => {
    if (company?.id) {
      dispatch(fetchCustomers({ company_id: company.id }));
    }
  }, [dispatch, company?.id]);

  const handleUploadOpen = () => {
    setIsUploadOpen((prev) => !prev);
  };
  const handleUploadClose = () => {
    setIsUploadOpen(false);
  };
  const handleCreateCustomerProfile = () => {
    navigate(ROUTES.CUSTOMER_PROFILE_CREATE);
  };
  if (!customer) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={3} sx={{ mt: '-100px' }}>
      <Grid item xs={12}>
        <NwBreadCrumbs />
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} mb={3}>
          <Typography variant='h5' component='h1' className={styles.page_title}>
            Customer Profile
          </Typography>
          <Stack direction='row' spacing={2}>
            <Box ref={uploadButtonRef}>
              <UploadButton onClick={handleUploadOpen} buttonText='Upload' isArrowDown={false} />
            </Box>
            {isUploadOpen && (
              <Box className={styles.form_box} ref={formUploadRef}>
                <FormUpload categoryValue='customers' onCancel={handleUploadClose} />
              </Box>
            )}
            {/* TODO: Change this text */}
            <Tooltip title={t('businessProfile.addContacts')} placement='top'>
              <IconButton onClick={handleCreateCustomerProfile} className={styles.add_button}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Grid item xs={12}>
            <Typography variant='h6' fontWeight='bold'>
              {customer.contact_name}
            </Typography>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} className={styles.grid_margin_bottom}>
        <BasicTabs
          tabs={[
            t('businessProfile.allTab'),
            t('businessProfile.detailsTab'),
            t('businessProfile.documentsTabs'),
            t('businessProfile.notesTab'),
          ]}
          tabContents={[
            <NewCustomerAllTab key='all' />,
            <NewCustomerDetailsTab company={company} key='details' />,
            <DocumentsTabs company={company} key='documents' />,
            <NotesTab companyId={company.id} key='notes' />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default NewCustomerProfile;
