import React, { useState } from 'react';
import { NWClient } from '../../client/NWClient';
import { toast } from 'react-toastify';
import { useAppSelector } from '../hooks/useAppSelector';
import { selectUser } from '../../slices/user/userSlice';
import { useTranslation } from 'react-i18next';
import { HandleAnalyzeArgumentsTypes } from './types';
import { Document } from '../../pages/document-vault/components/document-list-vault/types';

export const useAISummaryDialog = () => {
  const { t } = useTranslation();
  const purposes = [
    {
      title: t('purpose.verification'),
      value: 'verification',
    },
    {
      title: t('purpose.analysis'),
      value: 'analysis',
    },
    {
      title: t('purpose.recordKeeping'),
      value: 'record keeping',
    },
  ];
  const currentUser = useAppSelector(selectUser);
  const [documentType, setDocumentType] = useState<string>('contract');
  const [purpose, setPurpose] = useState(purposes);
  const [resultFormat, setResultFormat] = useState<string>('table');
  const [detailLevel, setDetailLevel] = useState<string>('high level');
  const [simplifyJargon, setSimplifyJargon] = useState<boolean>(true);
  const [contextualInformation, setContextualInformation] = useState('');
  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [planUpgradingMessage, setPlanUpgradingMessage] = useState<boolean>(false);
  const [document, setDocument] = useState<Document>(null);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleAnalyze = ({
    handleAnalyzeSuccess,
    handleAnalyzeError,
    isDocumentContract,
    documentId,
  }: HandleAnalyzeArgumentsTypes) => {
    setAnalysisLoading(true);
    NWClient.post(
      'ai-assist',
      {
        [isDocumentContract ? 'document_contract_id' : 'document_upload_id']:
          typeof documentId === 'string' ? Number(documentId) : documentId,
        document_type: documentType,
        result_format: resultFormat,
        detail_level: detailLevel,
        purpose: JSON.stringify(purpose.map((el) => el.value)),
        simplify_jargon: simplifyJargon,
        contextual_information: contextualInformation,
        user: currentUser.id,
      },
      true
    )
      .then(() => {
        handleAnalyzeSuccess();
      })
      .catch((error) => {
        if (
          error.response?.data?.errors?.length > 0 &&
          error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
        ) {
          setPlanUpgradingMessage(true);
          handleCloseDialog();
        } else {
          toast.error(error.message ? error.message : t('messages.errorOccurred'), {
            theme: 'colored',
          });
        }
        handleAnalyzeError();
      });
  };
  return {
    handleAnalyze,
    analysisLoading,
    setAnalysisLoading,
    dialogOpen,
    setDialogOpen,
    handleCloseDialog,
    planUpgradingMessage,
    setPlanUpgradingMessage,
    documentType,
    setDocumentType,
    purpose,
    setPurpose,
    resultFormat,
    setResultFormat,
    detailLevel,
    setDetailLevel,
    simplifyJargon,
    setSimplifyJargon,
    contextualInformation,
    setContextualInformation,
    document,
    setDocument,
    purposes,
  };
};
