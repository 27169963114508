import React from 'react';
import BrushIcon from '@mui/icons-material/Brush';

import { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import styles from './NotesTab.module.scss';

interface NotesEditButtonProps {
  onEdit: () => void;
  onDelete: () => void;
}

export const NotesEditButton = ({ onEdit, onDelete }: NotesEditButtonProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} className={styles.add_button_size}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            onEdit();
          }}
        >
          <BrushIcon style={{ marginRight: 8 }} />
          {t('buttons.edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onDelete();
          }}
        >
          <DeleteIcon style={{ marginRight: 8 }} />
          {t('buttons.delete')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default NotesEditButton;
