import { useState } from 'react';
import { NWClient } from '../../client/NWClient';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Document } from '../../pages/document-vault/components/document-list-vault/types';
import { LibraryItem } from '../../pages/library/components/library-list/types';
import { Helpers } from '../helpers/helpers';

export const useFileTypeDownload = () => {
  const { t } = useTranslation();
  const [fileType, setFileType] = useState<'pdf' | 'doc'>('pdf');
  const [fileName, setFileName] = useState<string>('');
  const [document, setDocument] = useState<Document>(null);
  const [libraryItem, setLibraryItem] = useState<LibraryItem>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);

  const handleCloseModal = () => {
    setModalOpen(false);
    setFileName('');
    setFileType('pdf');
    setDocument(null);
    setLibraryItem(null);
  };

  const downloadDocumentUploadItem = (id: number, name: string) => {
    setPendingStatus(true);
    NWClient.downloadDocument(id, name)
      .then(() => {
        handleCloseModal();
      })
      .catch(() => {
        toast.error(t('messages.errorOccurred'));
      })
      .finally(() => {
        setPendingStatus(false);
      });
  };

  const handleDownloadContract = (id: number) => {
    setPendingStatus(true);
    NWClient.downloadDocumentContract(id, fileName + '.' + fileType, fileType)
      .then(() => {
        handleCloseModal();
      })
      .catch(() => {
        toast.error(t('messages.errorOccurred'));
      })
      .finally(() => {
        setPendingStatus(false);
      });
  };

  const handleDownloadHTML = (
    htmlId: number,
    fileNameForDownloading: string,
    fileTypeForDownloading: string
  ) => {
    setPendingStatus(true);
    NWClient.downloadHtmlDocument(
      htmlId,
      fileNameForDownloading + '.' + fileTypeForDownloading,
      fileTypeForDownloading
    )
      .then(() => {
        handleCloseModal();
      })
      .catch(() => {
        toast.error(t('messages.errorOccurred'));
      })
      .finally(() => {
        setPendingStatus(false);
      });
  };

  const handleTemplateDownload = (
    id: number,
    fileTypeForDownloading: string,
    fileNameForDownloading: string
  ) => {
    setPendingStatus(true);

    NWClient.downloadLibraryHtmlDocument(id, fileTypeForDownloading, fileNameForDownloading)
      .then(() => {
        handleCloseModal();
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        const errorMessage = Helpers.returnErrorText(error);
        toast.error(errorMessage.toString(), { theme: 'colored' });
      })
      .finally(() => {
        setPendingStatus(false);
      });
  };

  return {
    fileType,
    setFileType,
    fileName,
    setFileName,
    modalOpen,
    setModalOpen,
    handleCloseModal,
    pendingStatus,
    setPendingStatus,
    handleDownloadContract,
    document,
    setDocument,
    downloadDocumentUploadItem,
    handleDownloadHTML,
    handleTemplateDownload,
    libraryItem,
    setLibraryItem,
  };
};
