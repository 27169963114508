import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AddressInputInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

export default function AddressInputInput({ value, onChange, disabled }: AddressInputInputProps) {
  const { t } = useTranslation();

  return (
    <TextField
      id='address-input'
      label={t('formInputs.addressInputLabel')}
      variant='outlined'
      fullWidth
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}
