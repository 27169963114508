import { Box, Button, IconButton, Menu, MenuItem, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import { TaskItemBlockProps } from './types';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RedCircleIcon from '../../../static/assets/svg/rule_settings.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
const TaskItem = ({
  iconLeft,
  taskCompleted,
  taskText,
  status,
  date,
  actions,
  onAction,
  onComplete,
  onContinue,
}: TaskItemBlockProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleComplete = () => {
    if (status === 'in_progress' || status === 'overdue') {
      onComplete && onComplete();
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    onAction('delete');
    setDeleteConfirmOpen(false);
    handleMenuClose();
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
  };

  const handleModify = () => {
    onAction('modify');
    handleMenuClose();
  };

  const handleContinue = () => {
    onContinue && onContinue();
    handleMenuClose();
  };

  function formatDate(date: Date): string {
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }
  return (
    <Box
      className={
        'task-item d-flex align-items-center' +
        (taskCompleted || status === 'completed' ? ' completed' : '') +
        (iconLeft ? ' icon-left' : '')
      }
    >
      <div style={{ display: 'flex', gap: '6px', marginBottom: '0.5rem' }}>
        {!status && taskCompleted && <CheckCircleIcon fontSize='small' className='check-icon' />}
        {!status && !taskCompleted && <CircleOutlinedIcon fontSize='small' />}
        <div style={{ marginTop: '0.25rem' }}>{taskText}</div>
      </div>
      {!status && !taskCompleted && <StartOutlinedIcon fontSize='small' />}
      {status === 'overdue' && iconLeft && (
        <IconButton
          onClick={handleComplete}
          className='task-item-icon-button'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered ? (
            <CheckCircleIcon fontSize='medium' className='check-icon' />
          ) : (
            <RedCircleIcon />
          )}
        </IconButton>
      )}

      {status === 'in_progress' && iconLeft && (
        <IconButton
          onClick={handleComplete}
          className='task-item-icon-button'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered ? (
            <CheckCircleIcon fontSize='medium' className='check-icon' />
          ) : (
            <CircleOutlinedIcon fontSize='medium' />
          )}
        </IconButton>
      )}

      {status === 'completed' && iconLeft && (
        <>
          <CheckCircleIcon fontSize='small' className='check-icon' />
          {actions && (
            <Box className='actions-container'>
              <IconButton onClick={handleMenuOpen} disableRipple>
                <MoreVertIcon fontSize='small' />
              </IconButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                <MenuItem onClick={handleContinue}>Continue task</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
              </Menu>
            </Box>
          )}
        </>
      )}
      {date && status != 'completed' && (
        <Box className='date-container'>
          <CalendarMonthOutlinedIcon fontSize='small' />
          <Typography className='date-text'>{formatDate(date)}</Typography>
          {actions && (
            <Box className='actions-container'>
              <IconButton onClick={handleMenuOpen} className='morevert-icon' disableRipple>
                <MoreVertIcon fontSize='small' />
              </IconButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                <MenuItem onClick={handleComplete}>Complete</MenuItem>
                <MenuItem onClick={handleModify}>Change</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
      )}
      <Modal
        open={deleteConfirmOpen}
        onClose={handleCancelDelete}
        aria-labelledby='delete-confirmation-dialog'
      >
        <Box className='modal-container'>
          <Typography variant='h6' className='modal-title'>
            {t('messages.deleteTodoDialog')}
          </Typography>
          <Box className='modal-button-container'>
            <Button onClick={handleConfirmDelete} className='modal-delete'>
              {t('buttons.delete')}
            </Button>
            <Button onClick={handleCancelDelete} className='modal-cancel'>
              {t('buttons.no')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default TaskItem;
