import { Card, CardContent, CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DocumentList from '../../../../common/document-list/DocumentList';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { selectCustomerById } from '../../../../slices/customers/customersSlice';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../../../slices/companies/companiesSlice';
import { fetchDocuments } from '../../../../slices/documents/documentsSlice';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { fetchDocumentContracts } from '../../../../slices/document-contracts/documentContractsSlice';
import CustomerDetailsCard from '../customer-details-card/CustomerDetailsCard';

const NewCustomerAllTab: React.FC = () => {
  const { customerId } = useParams();
  const numericCustomerId = Number(customerId);
  const company = useAppSelector(selectAllCompanies)[0];
  const dispatch = useAppDispatch();

  const customer = useSelector((state: RootState) => selectCustomerById(state, numericCustomerId));

  const [documents, setDocuments] = useState<any[]>([]);

  useEffect(() => {
    const fetchAllDocuments = async () => {
      const results = await Promise.all(
        company.members.map(async (member) => {
          const [docsAction, contractsAction] = await Promise.all([
            dispatch(fetchDocuments({ data: { user: member.id, category: 'business' } })),
            dispatch(fetchDocumentContracts({ data: { user: member.id, category: 'business' } })),
          ]);
          return [
            ...(Array.isArray(docsAction.payload) ? docsAction.payload : []),
            ...(Array.isArray(contractsAction.payload) ? contractsAction.payload : []),
          ];
        })
      );
      // Flatten the result and update the documents state
      setDocuments(results.flat());
    };

    fetchAllDocuments();
  }, [company.members, dispatch]);

  if (!customer || !company) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <CustomerDetailsCard company={company} simplified />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <DocumentList documents={documents} />
      </Grid>
    </Grid>
  );
};

export default NewCustomerAllTab;
