import React, { useEffect, useState, useRef } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import NWAppBar from '../../common/nw-app-bar/NWAppBar';
import NWSideNav from '../../common/nw-side-nav/NWSideNav';
import { ROUTES } from '../../common/constants/routes';
import { NWClient } from '../../client/NWClient';
import { API_CONSTANTS } from '../../common/constants/api';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import MainNavigation from '../../common/main-navigation/MainNavigation';
import AppBar from '../../common/app-bar/AppBar';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { switchDesign } from '../../common/design-switch/designSwitchSlice';
import { fetchUser } from '../../slices/user/userSlice';
import { fetchCompanies } from '../../slices/companies/companiesSlice';

const Layout = (): JSX.Element => {
  const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>();
  const { documentId } = useParams();
  const [pendingStatus, setPendingStatus] = useState(true);
  const intervalDuration = API_CONSTANTS.CHECK_TOKEN_INTERVAL;
  const token = localStorage.getItem('access_token');
  const designUpdated = useAppSelector((state) => state.designUpdated.designUpdated);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const locationExceptions = [
    ROUTES.SIGN_UP,
    ROUTES.EMAIL_SENT,
    ROUTES.PROCESS_SUCCESS,
    ROUTES.CONFIRMATION,
    ROUTES.LOGIN,
    ROUTES.ACCEPTED_INVITE,
    ROUTES.JOIN_COMPANY,
    ROUTES.PASSWORD_RESET,
  ];
  const locationIncludeExceptionUrl = locationExceptions.some((el) => {
    return location.pathname.includes(el);
  });
  const locationWithFullWidth = [
    ROUTES.SIGNING,
    ROUTES.DOCUMENTS,
    ROUTES.DOCUMENT_VAULT_CATEGORY_DOCUMENT,
  ];
  const locationWithFullWidthUrl = locationWithFullWidth.some((el) => {
    return location.pathname.includes(el);
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname === ROUTES.INDEX ||
      location.pathname === ROUTES.BUSINESS_PROFILE ||
      location.pathname === ROUTES.DOCUMENT_VAULT ||
      location.pathname === ROUTES.DOCUMENT_LIBRARY ||
      location.pathname === ROUTES.EMPLOYEE_PROFILE_V2 ||
      location.pathname === ROUTES.EMPLOYEE_PROFILES_V2
    ) {
      dispatch(switchDesign(true));
    } else {
      dispatch(switchDesign(false));
    }
  }, [location]);

  useEffect(() => {
    if (locationIncludeExceptionUrl) {
      setPendingStatus(false);
    } else if (!locationIncludeExceptionUrl && token) {
      setPendingStatus(false);
      dispatch(fetchCompanies());
    } else if (!locationIncludeExceptionUrl && !token) {
      navigate(ROUTES.LOGIN);
    }
  }, [locationIncludeExceptionUrl, token]);

  useEffect(() => {
    if (!locationIncludeExceptionUrl) {
      intervalRef.current = setInterval(() => {
        //dispatch(fetchUser());
        NWClient.currentUser().catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem('access_token');
            navigate(ROUTES.LOGIN);
          }
        });
      }, intervalDuration);
    }

    return () => clearInterval(intervalRef.current);
  }, [intervalDuration, locationIncludeExceptionUrl, navigate, token]);

  const renderedLayout = () => {
    if (locationIncludeExceptionUrl) {
      return <Outlet />;
    }

    return (
      <React.Fragment>
        {pendingStatus ? (
          <ProgressOverlay />
        ) : (
          <Box className={'layout' + (designUpdated ? ' updated-variant' : '')}>
            <MainNavigation />
            <Box sx={{ width: '100%' }}>
              {designUpdated ? <AppBar /> : <NWAppBar />}
              <Container maxWidth={locationWithFullWidthUrl || documentId ? false : 'lg'}>
                <Outlet />
              </Container>
            </Box>
          </Box>
        )}
      </React.Fragment>
    );
  };

  return renderedLayout();
};
export default Layout;
