import React, { useState } from 'react';
import { Document } from '../components/document-list-vault/types';
import { deleteDocumentTemplate } from '../../../slices/document-templates/documentTemplatesSlice';
import { toast } from 'react-toastify';
import { deleteDocumentContract } from '../../../slices/document-contracts/documentContractsSlice';
import { deleteDocument } from '../../../slices/documents/documentsSlice';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { selectUser } from '../../../slices/user/userSlice';
import { useTranslation } from 'react-i18next';

export const useDeleteMessageModal = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [documentToDelete, setDocumentToDelete] = useState<Document | null>(null);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);

  const handleDeleteDocument = () => {
    setPendingStatus(true);
    setModalOpen(false);
    dispatch(
      documentToDelete.contract_generation
        ? deleteDocumentContract({ id: documentToDelete?.id, userId: currentUser?.id })
        : deleteDocument({ id: documentToDelete?.id, userId: currentUser?.id })
    )
      .unwrap()
      .catch((error) => {
        toast.error(error.message || t('messages.errorOccurred'));
      })
      .finally(() => {
        setDocumentToDelete(null);
        setModalOpen(false);
        setPendingStatus(false);
      });
  };

  return {
    modalOpen,
    setModalOpen,
    documentToDelete,
    setDocumentToDelete,
    pendingStatus,
    handleDeleteDocument,
  };
};
