import React, { useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import DetailsCard from '../../../common/details-card/DetailsCard';
import BankDetailsCard from '../../../common/bank-details-card/BankDetailsCard';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import {
  fetchBusinessProfileBankDetails,
  selectBusinessProfileBankDetails,
} from '../../../slices/business-profile/businessProfileSlice';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { Company } from '../../../models/company.model';
import { Employee } from '../../../models/employee.model';
import EmployeeDetailsCard from '../../../common/employee-details-card/EmployeeDetailsCard';
import EmploymentCard from '../../../common/employment-card/EmploymentCard';
import QualificationsCard from '../../../common/qualifications-card/QualificationsCard';
import EmergencyContactCard from '../../../common/emergency-contact-card/EmergencyContactCard';

interface DetailsTabsProps {
  company: Company;
  employee: Employee;
  key: string;
}

const DetailsTabs: React.FC<DetailsTabsProps> = ({ company, employee }) => {
  return (
    <>
      <Grid container wrap='wrap' rowGap={2}>
        <Grid item xs={12}>
          <EmployeeDetailsCard employee={employee} company={company} />
        </Grid>

        <Grid item xs={12}>
          <EmploymentCard employee={employee} />
        </Grid>

        <Grid item xs={12}>
          <QualificationsCard employee={employee} />
        </Grid>

        <Grid item xs={12}>
          <EmergencyContactCard employee={employee} />
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsTabs;
