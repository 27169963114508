import { Document } from '../components/document-list-vault/types';
import { ROUTES } from '../../../common/constants/routes';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { selectAllEmployees } from '../../../slices/employees/employeesSlice';
import { selectAllSuppliers } from '../../../slices/suppliers/suppliersSlice';
import { selectAllCustomers } from '../../../slices/customers/customersSlice';
import { useNavigate } from 'react-router-dom';
import { selectAllCompanies } from '../../../slices/companies/companiesSlice';
import { selectBusinessProfileContacts } from '../../../slices/business-profile/businessProfileSlice';

export const useDocumentsFunctions = () => {
  const company = useAppSelector(selectAllCompanies)[0];
  const employees = useAppSelector(selectAllEmployees);
  const suppliers = useAppSelector(selectAllSuppliers);
  const customers = useAppSelector(selectAllCustomers);
  const businessContacts = useAppSelector(selectBusinessProfileContacts);
  const navigate = useNavigate();
  const getProfileType = (
    profile: string
  ): 'employment' | 'supplier' | 'customer' | 'business' | 'unknown' => {
    const lowerProfile = profile.toLowerCase();
    if (lowerProfile.includes('supplier')) return 'supplier';
    if (lowerProfile.includes('customer')) return 'customer';
    if (lowerProfile.includes('business')) return 'business';
    if (lowerProfile !== '-') return 'employment';
    return 'unknown';
  };

  const getWorkflow = (doc: Document): string => {
    if ('dc_workflows' in doc) {
      return doc.dc_workflows.length > 0 ? doc.dc_workflows.join(', ') : '-';
    } else if ('du_workflows' in doc) {
      return doc.du_workflows.length > 0 ? doc.du_workflows.join(', ') : '-';
    }
  };

  const getDocumentType = (doc: Document): string => {
    if ('dc_types' in doc) {
      return doc.dc_types.length > 0 ? doc.dc_types.join(', ') : '-';
    } else if ('du_types' in doc) {
      return doc.du_types.length > 0 ? doc.du_types.join(', ') : '-';
    }
  };

  const getProfileName = (profileType: string, doc: Document): string[] => {
    switch (profileType) {
      case 'employment':
        return doc.employees.length > 0
          ? doc.employees.map((el) => {
              return (
                employees.find((item) => item.id === el)?.first_name +
                ' ' +
                employees.find((item) => item.id === el)?.last_name
              );
            })
          : ['-'];
      case 'supplier':
        return doc.suppliers.length > 0
          ? doc.suppliers.map((el) => {
              return suppliers.find((item) => item.id === el)?.name;
            })
          : ['-'];
      case 'customer':
        return doc.customers.length > 0
          ? doc.customers.map((el) => {
              return customers.find((item) => item.id === el)?.customer_name;
            })
          : ['-'];
      case 'business':
        return doc.business_profile_contacts.length > 0
          ? doc.business_profile_contacts.map((el) => {
              return businessContacts.find((item) => item.id === el)?.contact_name;
            })
          : ['-'];
      default:
        return ['-'];
    }
  };

  const getFileTypeFromName = (name: string, contract_generation: number) => {
    const lastCharacters = name?.slice(-4);
    if (contract_generation === undefined) {
      if (lastCharacters.includes('.')) {
        const charactersWithoutDot = lastCharacters.replace('.', '');
        return charactersWithoutDot;
      }
      return lastCharacters;
    } else {
      return 'bk';
    }
  };

  const navigateToDocumentPageTab = (doc: Document, tab: number) => {
    navigate(
      `${ROUTES.DOCUMENT_VAULT}/${doc.category}/${doc.id}?initialTab=${tab}${
        doc.contract_generation ? '&contractGenerated=true' : ''
      }`
    );
  };
  return {
    getProfileType,
    getWorkflow,
    getDocumentType,
    getProfileName,
    getFileTypeFromName,
    navigateToDocumentPageTab,
  };
};
