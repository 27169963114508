import React, { useEffect, useState } from 'react';
import ListItemBlock from '../../common/list-item-block/ListItemBlock';
import { ROUTES } from '../../common/constants/routes';
import {
  Box,
  Typography,
  List,
  ListItem,
  Button,
  Modal,
  OutlinedInput,
  SelectChangeEvent,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import AddIcon from '../../../static/assets/svg/add-icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TaskItem from '../../common/task-item-block/task-item-block';
import ListItemButton from '../../common/list-item-button/ListItemButton';
import { ButtonGroup } from 'react-bootstrap';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { deleteTodo, fetchTodos, updateTodo } from '../../slices/todo/todoSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import TodoForm from '../categories/components/TodoForm/TodoForm';
import { ToDoStatus } from '../../common/task-item-block/types';
import { ToDo } from '../../models/todo.model';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CompanyCreateForm from '../../common/company-create-form';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { selectAllEmployees } from '../../slices/employees/employeesSlice';
import { selectAllSuppliers } from '../../slices/suppliers/suppliersSlice';
import { selectAllCustomers } from '../../slices/customers/customersSlice';
import { fetchEmployees } from '../../slices/employees/employeesSlice';
import { fetchSuppliers } from '../../slices/suppliers/suppliersSlice';
import { fetchCustomers } from '../../slices/customers/customersSlice';
import { fetchDocuments } from '../../slices/documents/documentsSlice';
import { fetchDocumentContracts } from '../../slices/document-contracts/documentContractsSlice';
import { fetchCompanies } from '../../slices/companies/companiesSlice';
import { selectUser } from '../../slices/user/userSlice';
import { selectAllDocuments } from '../../slices/documents/documentsSlice';
import { selectAllDocumentContracts } from '../../slices/document-contracts/documentContractsSlice';
import { RootState } from '../../store';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const todosPending = useAppSelector((state: RootState) => state.todos.status);

  const documentsMenuItems = [
    {
      name: t('menuItems.createFromBusinessKitzLibrary'),
      onClick: () => {
        navigate(ROUTES.DOCUMENT_LIBRARY);
      },
    },
    {
      name: t('menuItems.createFromOwnLibrary'),
      onClick: () => {
        navigate(`${ROUTES.DOCUMENT_LIBRARY}/company-templates`);
      },
    },
  ];
  const profilesMenuItems = [
    {
      name: t('menuItems.createEmployee'),
      onClick: () => {
        navigate(ROUTES.EMPLOYEE_PROFILES);
      },
    },
    {
      name: t('menuItems.createSupplier'),
      onClick: () => {
        navigate(ROUTES.SUPPLIER_PROFILES);
      },
    },
    {
      name: t('menuItems.createCustomer'),
      onClick: () => {
        navigate(ROUTES.CUSTOMER_PROFILES);
      },
    },
  ];

  const dispatch = useAppDispatch();
  const todos = useAppSelector((state) => state.todos.items);
  const company = useAppSelector(selectAllCompanies)[0];
  const companyLoadingStatus = useAppSelector((state) => state.companies.status);
  const currentUser = useAppSelector(selectUser);
  const employees = useAppSelector(selectAllEmployees);
  const customers = useAppSelector(selectAllCustomers);
  const suppliers = useAppSelector(selectAllSuppliers);
  const documentUploads = useAppSelector(selectAllDocuments);
  const documentContracts = useAppSelector(selectAllDocumentContracts);
  const [isSkipped, setIsSkipped] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [documentUploadsLoading, setDocumentUploadsLoading] = useState<boolean>(true);
  const [documentContractsLoading, setDocumentContractsLoading] = useState<boolean>(true);
  const [suppliersLoading, setSuppliersLoading] = useState<boolean>(true);
  const [customersLoading, setCustomersLoading] = useState<boolean>(true);
  const [employeesLoading, setEmployeesLoading] = useState<boolean>(true);
  const [todosLoading, setTodosLoading] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [selectedTodo, setSelectedTodo] = useState(null);
  const [createNewDocument, setCreateNewDocument] = useState<string>('selectAnOption');

  const recentDocumentsArraySorted = [...documentUploads, ...documentContracts]
    .slice()
    .sort(function (a, b) {
      return Number(new Date(b.updated)) - Number(new Date(a.updated));
    })
    .slice(0, 4);

  const recentDocuments = recentDocumentsArraySorted.map((el) => ({
    id: el.id,
    name: el.name,
    labelText: el.category,
    onListItemClick: el.contract_generation
      ? () =>
          navigate(
            `${ROUTES.DOCUMENT_VAULT}/${el.category}/${el.id}?initialTab=1&contractGenerated=true`
          )
      : () => navigate(`${ROUTES.DOCUMENT_VAULT}/${el.category}/${el.id}`),
  }));

  const recentProfilesArraySorted = [...employees, ...customers, ...suppliers]
    .slice()
    .sort(function (a, b) {
      return Number(new Date(b.updated)) - Number(new Date(a.updated));
    })
    .slice(0, 4);

  const getProfileUrl = (el: any) => {
    if ('supplier_type' in el) {
      return ROUTES.SUPPLIER_PROFILES;
    }
    if ('customer_name' in el) {
      return ROUTES.CUSTOMER_PROFILES;
    }
    if ('employment_type' in el) {
      return ROUTES.EMPLOYEE_PROFILES;
    }
  };

  const getProfileCategory = (el: any) => {
    if ('supplier_type' in el) {
      return t('labels.supplier');
    }
    if ('customer_name' in el) {
      return t('labels.customer');
    }
    if ('employment_type' in el) {
      return t('labels.employee');
    }
  };

  const getProfileName = (el: any) => {
    if ('supplier_type' in el) {
      return el.name;
    }
    if ('customer_name' in el) {
      return el.customer_name;
    }
    if ('employment_type' in el) {
      return el.first_name + ' ' + el.last_name;
    }
  };

  const recentProfiles = recentProfilesArraySorted.map((el) => ({
    id: el.id,
    name: getProfileName(el),
    labelText: getProfileCategory(el),
    onListItemClick: () => navigate(`${getProfileUrl(el)}/${el.id}`),
  }));

  useEffect(() => {
    if (isSkipped) {
      dispatch(fetchTodos())
        .unwrap()
        .finally(() => setTodosLoading(false));
    }
  }, [isSkipped, dispatch]);

  const handleSkip = () => {
    setIsSkipped(true);
  };
  const handleAction = (action: string, todo: any) => {
    if (action === 'delete') {
      setTodosLoading(true);
      dispatch(deleteTodo({ id: todo.id }))
        .unwrap()
        .finally(() => setTodosLoading(false));
    }
    if (action === 'modify') {
      setSelectedTodo(todo);
      setOpen(true);
    }
  };
  const handleStart = (todoId: number) => {
    const todo = todos.find((t) => t.id === todoId);
    if (todo) {
      setTodosLoading(true);
      dispatch(
        updateTodo({
          id: todoId,
          data: {
            ...todo,
            started_at: new Date().toISOString(),
          },
        })
      )
        .unwrap()
        .finally(() => setTodosLoading(false));
    }
  };

  const handleComplete = (todoId: number) => {
    const todo = todos.find((t) => t.id === todoId);
    if (todo) {
      setTodosLoading(true);
      dispatch(
        updateTodo({
          id: todoId,
          data: {
            ...todo,
            completed_at: new Date().toISOString(),
          },
        })
      )
        .unwrap()
        .finally(() => setTodosLoading(false));
    }
  };

  const handleContinue = (todoId: number) => {
    const todo = todos.find((t) => t.id === todoId);
    if (todo) {
      setTodosLoading(true);
      dispatch(
        updateTodo({
          id: todoId,
          data: {
            ...todo,
            completed_at: null,
            started_at: new Date().toISOString(),
          },
        })
      )
        .unwrap()
        .finally(() => setTodosLoading(false));
    }
  };

  const getTodoStatus = (todo: ToDo): ToDoStatus => {
    if (todo.completed_at) {
      return 'completed';
    }
    const now = new Date();
    const dueDate = new Date(todo.due_at);
    if (now > dueDate) {
      return 'overdue';
    }
    return 'in_progress';
  };
  const sortTodos = (todos: ToDo[]): ToDo[] => {
    const groups: { [key in ToDoStatus]: ToDo[] } = {
      overdue: [],
      in_progress: [],
      completed: [],
    };

    todos.forEach((todo) => {
      const status = getTodoStatus(todo);
      groups[status].push(todo);
    });
    groups.overdue.sort((a, b) => new Date(b.due_at).getTime() - new Date(a.due_at).getTime());
    groups.in_progress.sort((a, b) => new Date(a.due_at).getTime() - new Date(b.due_at).getTime());
    return [...groups.overdue, ...groups.in_progress, ...groups.completed];
  };

  const handleOpen = () => {
    setSelectedTodo(null);
    setOpen(true);
  };
  const handleClose = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
    setSelectedTodo(null);
  };
  useEffect(() => {
    switch (createNewDocument) {
      case 'createFromBusinessKitzLibrary':
        navigate(ROUTES.DOCUMENT_LIBRARY);
        break;
      case 'createFromOwnLibrary':
        navigate(`${ROUTES.DOCUMENT_LIBRARY}/company-templates`);
        break;
    }
  }, [createNewDocument]);
  useEffect(() => {
    const closeHandler = () => setOpen(false);
    document.addEventListener('closeModal', closeHandler);
    return () => {
      document.removeEventListener('closeModal', closeHandler);
    };
  }, []);

  useEffect(() => {
    if (!company) {
      dispatch(fetchCompanies());
    }
    if (company) {
      dispatch(fetchEmployees({ company_id: company.id }))
        .unwrap()
        .finally(() => setEmployeesLoading(false));
      dispatch(fetchSuppliers({ company_id: company.id }))
        .unwrap()
        .finally(() => setSuppliersLoading(false));
      dispatch(fetchCustomers({ company_id: company.id }))
        .unwrap()
        .finally(() => setCustomersLoading(false));
      dispatch(fetchDocuments({ data: { user: currentUser.id } }))
        .unwrap()
        .finally(() => setDocumentUploadsLoading(false));
      dispatch(fetchDocumentContracts({ data: { user: currentUser.id } }))
        .unwrap()
        .finally(() => setDocumentContractsLoading(false));
    }
  }, [company]);

  return (
    <>
      <div className='d-md-flex home-page-wrapper mt-2'>
        <div className='flex-md-grow-1 d-md-flex flex-md-column'>
          <div className='d-md-flex flex-md-column'>
            <div className='flex-md-grow-1'>
              <Box className='box-with-header flex-grow-1'>
                <Box className='header-action-row d-flex justify-content-between'>
                  <Typography variant='h2'>{t('headers.documents')}</Typography>
                </Box>
                <Box className='d-flex align-items-start flex-wrap gap-2 buttons-container action-controls-container'>
                  <FormControl>
                    <InputLabel id='createNewDocumentLabel'>
                      {t('labels.createDocument')}
                    </InputLabel>
                    <Select
                      labelId='createNewDocumentLabel'
                      value={createNewDocument}
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      }}
                      onChange={(event: SelectChangeEvent) =>
                        setCreateNewDocument(event.target.value)
                      }
                      input={<OutlinedInput />}
                    >
                      <MenuItem value='selectAnOption'>{t('menuItems.selectAnOption')}</MenuItem>
                      <MenuItem value='createFromBusinessKitzLibrary'>
                        {t('menuItems.createFromBusinessKitzLibrary')}
                      </MenuItem>
                      <MenuItem value='createFromOwnLibrary'>
                        {t('menuItems.createFromOwnLibrary')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <ListItemButton
                    buttonText={t('buttons.store')}
                    onClick={() => navigate(ROUTES.DOCUMENT_VAULT)}
                  />
                  <ListItemButton
                    buttonText={t('buttons.sign')}
                    onClick={() => navigate(ROUTES.DOCUMENTS)}
                  />
                </Box>
              </Box>
            </div>
            <div className='flex-md-grow-1'>
              <Box className='box-with-header flex-grow-1'>
                <Box className='header-action-row d-flex justify-content-between'>
                  <Typography variant='h2'>{t('headers.profiles')}</Typography>
                </Box>
                <Box className='d-flex align-items-start flex-wrap gap-2 buttons-container'>
                  <ListItemButton
                    buttonText={t('buttons.employee')}
                    onClick={() => navigate(ROUTES.EMPLOYEE_PROFILES)}
                  />
                  <ListItemButton
                    buttonText={t('buttons.supplier')}
                    onClick={() => navigate(ROUTES.SUPPLIER_PROFILES)}
                  />
                  <ListItemButton
                    buttonText={t('buttons.customer')}
                    onClick={() => navigate(ROUTES.CUSTOMER_PROFILES)}
                  />
                </Box>
              </Box>
            </div>
          </div>
          <div className='d-flex flex-grow-1 flex-column'>
            <Box className='position-relative box-with-header flex-grow-1'>
              <Box className='header-action-row d-flex justify-content-between'>
                <Typography variant='h2'>{t('headers.toDo')}</Typography>
                {company && (
                  <Link to={null}>
                    {!isSkipped && (
                      <Button
                        variant='contained'
                        className='btn-action-basic btn-small'
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}
                    {isSkipped && (
                      <span
                        role='button'
                        tabIndex={-1}
                        onKeyUp={() => {
                          return;
                        }}
                        className='add-icon-wrapper d-flex justify-content-center align-items-center'
                        onClick={handleOpen}
                      >
                        <AddIcon />
                      </span>
                    )}
                  </Link>
                )}
              </Box>
              {company ? (
                <>
                  {!isSkipped && (
                    <List className='tasks-list pt-0 pb-0'>
                      <ListItem>
                        <TaskItem taskText={'Upload and store a document'} />
                      </ListItem>
                      <ListItem>
                        <TaskItem taskText={'Create new document'} taskCompleted />
                      </ListItem>
                      <ListItem>
                        <TaskItem taskText={'Add employee'} />
                      </ListItem>
                      <ListItem>
                        <TaskItem
                          taskText={'Create a template from existing AI components'}
                          taskCompleted
                        />
                      </ListItem>
                      <ListItem>
                        <TaskItem taskText={'Create new document'} taskCompleted />
                      </ListItem>
                      <ListItem>
                        <TaskItem taskText={'Add a supplier or customer'} />
                      </ListItem>
                      <ListItem>
                        <TaskItem taskText={'Document summary with AI'} taskCompleted />
                      </ListItem>
                      <ListItem>
                        <TaskItem taskText={'Start subscription'} />
                      </ListItem>
                    </List>
                  )}
                  {isSkipped && todos.length === 0 && !todosLoading && (
                    <Box className='d-flex flex-column flex-grow-1 justify-content-center align-items-center empty-state-message-container '>
                      <div className='empty-state-message'>{t('emptyStateMessages.todos')}</div>
                    </Box>
                  )}
                  {isSkipped && todos.length > 0 && (
                    <List className='tasks-list pt-0 pb-0'>
                      {sortTodos(todos).map((todo) => (
                        <ListItem key={todo.id}>
                          <TaskItem
                            taskText={todo.message}
                            date={new Date(todo.due_at)}
                            status={getTodoStatus(todo)}
                            iconLeft
                            onAction={(action) => handleAction(action, todo)}
                            actions
                            onStart={() => handleStart(todo.id)}
                            onComplete={() => handleComplete(todo.id)}
                            onContinue={() => handleContinue(todo.id)}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                  {(todosLoading || todosPending === 'loading') && (
                    <ProgressOverlay className='position-absolute' />
                  )}
                </>
              ) : (
                <Box className='d-flex flex-column flex-grow-1 justify-content-center align-items-center empty-state-message-container '>
                  <CompanyCreateForm />
                </Box>
              )}
            </Box>
          </div>
        </div>
        <div className='flex-md-shrink-0 d-md-flex flex-md-column'>
          <div>
            <ListItemBlock
              headerText={'Recent documents'}
              listArray={recentDocuments}
              withMenu={Boolean(company)}
              menuOptions={documentsMenuItems}
              emptyStateMessage={t('emptyStateMessages.documents')}
              loading={company && (documentUploadsLoading || documentContractsLoading)}
            />
          </div>
          <div className='flex-grow-1'>
            <ListItemBlock
              headerText={'Recent profiles'}
              listArray={recentProfiles}
              withMenu={Boolean(company)}
              menuOptions={profilesMenuItems}
              emptyStateMessage={t('emptyStateMessages.profiles')}
              loading={company && (employeesLoading || suppliersLoading || customersLoading)}
            />
          </div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '8px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            p: 4,
          }}
        >
          {open && <TodoForm existingTodo={selectedTodo} onClose={() => setOpen(false)} />}
        </Box>
      </Modal>
      {(loading || companyLoadingStatus === 'loading') && <ProgressOverlay />}
    </>
  );
};

export default HomePage;
