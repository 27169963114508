import React from 'react';
import BusinessKitzLogo from '../../../static/assets/svg/business-kitz-logo.svg';
import { Grid } from '@mui/material';
import DemoAuthFooter from '../demo-auth-footer/DemoAuthFooter';
import ClerkLogIn from '../../pages/login/ClerkLogIn';

interface DemoAuthTemplateProps extends React.PropsWithChildren {
  featureItems?: JSX.Element;
}

const DemoAuthTemplate = ({ featureItems, children }: DemoAuthTemplateProps) => {
  return (
    <div className='demo-auth-template'>
      <header>
        <Grid container>
          <Grid item xs={12} className='demo-auth-template-logo-item'>
            <BusinessKitzLogo />
          </Grid>
        </Grid>
      </header>
      <Grid
        container
        className='demo-auth-template-container d-flex justify-content-center align-items-center flex-grow-1 px-2'
      >
        {featureItems && (
          <Grid item xs={12} sm={6} md={6} lg={4} sx={{ p: 3 }}>
            {featureItems}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            //minHeight: '60vh',
          }}
        >
          {children}
        </Grid>
      </Grid>
      <DemoAuthFooter />
    </div>
  );
};

export default DemoAuthTemplate;
