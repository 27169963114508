import React from 'react';
import { Modal, Button, Typography, Grid, Card, CardContent, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './FileTypeDownloadModal.module.scss';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { FileTypeDownloadModalProps } from './types';

export default function FileTypeDownloadModal({
  open,
  onClose,
  handleDownloadContract,
  handleFileNameChange,
  fileName,
  fileType,
  handleFileTypeChange,
  document,
  handleDownloadHTML,
  handleDownloadDocument,
  libraryItem,
  handleDownloadLibraryHTML,
}: FileTypeDownloadModalProps) {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition className='action-modal'>
      <Grid container className={styles.modal_container}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
              {(document?.contract_generation || libraryItem) && (
                <Typography variant='h6' gutterBottom>
                  {t('dialogTitles.chooseFileType')}
                </Typography>
              )}
              <Typography>
                {document?.contract_generation || libraryItem
                  ? t('messages.fileTypeToDownload')
                  : t('messages.downloadingVersionMessage')}
              </Typography>

              <Grid container spacing={2} marginTop={'10px'}>
                {(document?.contract_generation || libraryItem) && (
                  <>
                    <Grid item xs={12}>
                      <FormControl className='w-100'>
                        <InputLabel htmlFor='fileTypeForDownloading'>
                          {t('labels.fileType')}
                        </InputLabel>
                        <Select
                          onChange={(e: SelectChangeEvent) => {
                            handleFileTypeChange(e.target.value as 'pdf' | 'doc');
                          }}
                          id='fileTypeForDownloading'
                          name='fileTypeForDownloading'
                          value={fileType}
                        >
                          <MenuItem value={'pdf'}>
                            {t('contractGeneratorDialog.formatPDF')}
                          </MenuItem>
                          {/* eslint-disable-next-line prettier/prettier */}
                      <MenuItem value={'doc'}>{t('contractGeneratorDialog.formatWord')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t('labels.fileName')}
                        onChange={(e) => handleFileNameChange(e.target.value)}
                        name='fileName'
                        value={fileName}
                        tabIndex={-1}
                        className='w-100'
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container justifyContent='flex-end' gap={'8px'} mt={2}>
                <Button
                  variant='contained'
                  onClick={onClose}
                  className='btn-default btn-action-basic btn-cancel'
                >
                  {t('buttons.goBack')}
                </Button>
                {document?.contract_generation || libraryItem ? (
                  <Button
                    variant='contained'
                    onClick={libraryItem ? handleDownloadLibraryHTML : handleDownloadContract}
                    className='btn-default btn-action-basic'
                  >
                    {t('buttons.download')}
                  </Button>
                ) : (
                  <>
                    <Button
                      variant='contained'
                      onClick={handleDownloadDocument}
                      className='btn-default btn-action-basic'
                    >
                      {t('buttons.downloadOriginalDocument')}
                    </Button>
                    <Button
                      variant='contained'
                      onClick={handleDownloadHTML}
                      className='btn-default btn-action-basic'
                    >
                      {t('buttons.downloadLatestVersion')}
                    </Button>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
}
