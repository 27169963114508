import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';

import BrushIcon from '../../../../../static/assets/svg/brush.svg';
import OrganizationIcon from '../../../../../static/assets/svg/organization.svg';

import {
  fetchBusinessProfileById,
  selectBusinessProfileStatus,
  selectBusinessProfileDetails,
} from '../../../../slices/business-profile/businessProfileSlice';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './CustomerDetailsCard.module.scss';
import ConfirmModal from '../../../../common/confirm-modal/ConfirmModal';
import Tooltip from '@mui/material/Tooltip';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { Company } from '../../../../models/company.model';
import ContactNameInput from '../../../../common/form-inputs/contact-name-input/ContactNameInput';
import PhoneInput from '../../../../common/form-inputs/phone-input/PhoneInput';
import EmailAddressInputInput from '../../../../common/form-inputs/email-address-input/EmailAddresInput';
import WebsiteInput from '../../../../common/form-inputs/website-input/WebsiteInput';
import PhysicalAddressInput from '../../../../common/form-inputs/physical-address-input/PhysicalAddressInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { selectCustomerById } from '../../../../slices/customers/customersSlice';
import { useParams } from 'react-router-dom';
import CustomerNameInput from '../../../../common/form-inputs/customer-name-input/CustomerNameInput';
import PhysicalCodenput from '../../../../common/form-inputs/physical-code-input/PhysicalCodeInput';
import PhysicalCityInput from '../../../../common/form-inputs/physical-city-input/PhysicalCityInput';
import PhysicalCountryInput from '../../../../common/form-inputs/physical-country-input/PhysicalCountryInput';
import PostalAddressInput from '../../../../common/form-inputs/postal-address-input/PostalAddressInput';
import PostalCodeInput from '../../../../common/form-inputs/postal-code-input/PostalCodeInput';
import PostalCityInput from '../../../../common/form-inputs/postal-city-input/PostalCityInput';
import PostalCountryInput from '../../../../common/form-inputs/postal-country-input/PostalCountryInput';
import IndustryTypeInput from '../../../../common/form-inputs/industry-type-input/IndustryTypeInput';
import PrefferredContactMethodInput from '../../../../common/form-inputs/preferred-contact-method/PreferredContactMethodInput';
import PreferredCurrencyInput from '../../../../common/form-inputs/preferred-currency-input/PreferredCurrencyInput';

type DetailsCardProps = {
  simplified?: boolean;
  company: Company;
  forceIsEdited?: boolean;
};

export type CustomerDetails = {
  company: number;
  customer_name?: string;
  contact_name?: string;
  contact_number?: string;
  email_address?: string;
  website?: string;
  physical_address?: string;
  physical_code?: string;
  physical_city?: string;
  physical_country?: string;
  postal_address?: string;
  postal_code?: string;
  postal_city?: string;
  postal_country?: string;
  industy_type?: string;
  preferred_contact_method?: string;
  preferred_currency?: string;
};

export default function CustomerDetailsCard({
  simplified,
  company,
  forceIsEdited,
}: DetailsCardProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { customerId } = useParams();
  const numericCustomerId = Number(customerId);

  const businessProfileDetails = useAppSelector(selectBusinessProfileDetails);
  const businessProfileStatus = useAppSelector(selectBusinessProfileStatus);
  const isLoading = businessProfileStatus === 'loading';
  const [isEdited, setIsEdited] = useState(forceIsEdited);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef<HTMLDivElement | null>(null);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const customer = useSelector((state: RootState) => selectCustomerById(state, numericCustomerId));

  const [formData, setFormData] = useState<CustomerDetails>({
    company: 0,
    customer_name: '',
    contact_name: '',
    contact_number: '',
    email_address: '',
    website: '',
    physical_address: '',
    physical_code: '',
    physical_city: '',
    physical_country: '',
    postal_address: '',
    postal_code: '',
    postal_city: '',
    postal_country: '',
    industy_type: '',
    preferred_contact_method: '',
    preferred_currency: '',
  });

  useEffect(() => {
    if (customer) {
      setFormData({
        ...customer,
      });
    }
  }, [customer]);

  const toggleEditing = () => {
    setIsEdited((prev) => !prev);
  };

  const handleChange =
    <K extends keyof CustomerDetails>(field: K) =>
    (value: CustomerDetails[K]) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

  const confirmExit = () => {
    setIsEdited(false);
    setShowModal(false);
  };

  const saveChanges = () => {
    if (!businessProfileDetails?.company)
      //   dispatch(
      //     update...({
      //       id: ..,
      //       data: formData,
      //     })
      //   );
      setIsEdited(false);
    setShowModal(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isEdited && formRef.current && !formRef.current.contains(event.target as Node)) {
        if (event.target instanceof HTMLElement) {
          const ignoredElements = ['BUTTON', 'INPUT', 'TEXTAREA', 'SELECT', 'LABEL'];
          // if (ignoredElements.includes(event.target.tagName)) return;
        }

        setTimeout(() => {
          setShowModal(true);
        }, 0);
      }
    };
    if (isEdited) document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEdited]);

  const disabledStyles = {
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black', // Change text color
      WebkitTextFillColor: 'black', // Override browser autofill styles
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: '#f0f0f0', // Change background
      borderColor: 'red', // Change border color
    },
  };

  if (customerId && !customer) {
    return <CircularProgress />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card className={`${styles.card} ${isEdited ? styles.card_border : ''}`} ref={formRef}>
          <CardContent className={isLoading ? styles.card_content_min_height : ''}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container alignItems='center' flexWrap='nowrap' p={1}>
                  <Grid item xs={11} container alignItems='center' gap={1}>
                    <Grid sx={{ marginBottom: '0.2rem' }} item>
                      <OrganizationIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant='h6' fontWeight='bold'>
                        {t('businessProfile.detailsCardHeader')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} container justifyContent='flex-end'>
                    {!isEdited ? (
                      <Tooltip className='flex-shrink-0' title={t('buttons.edit')} placement='top'>
                        <IconButton onClick={toggleEditing}>
                          <BrushIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Button
                        type='submit'
                        className='btn-default btn-action-basic'
                        role='button'
                        disabled={!isEdited}
                        variant='contained'
                        size='large'
                        onClick={saveChanges}
                      >
                        {t('buttons.save')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <CustomerNameInput
                          value={formData.customer_name}
                          onChange={handleChange('customer_name')}
                          disabled
                          sx={disabledStyles}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ContactNameInput
                          value={formData.contact_name}
                          onChange={handleChange('contact_name')}
                          disabled={!isEdited}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <PhoneInput
                          value={formData.contact_number}
                          onChange={handleChange('contact_number')}
                          disabled={!isEdited}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <EmailAddressInputInput
                          value={formData.email_address}
                          onChange={handleChange('email_address')}
                          disabled={!isEdited}
                        />
                      </Grid>
                      {!simplified && (
                        <Grid item xs={12}>
                          <WebsiteInput
                            value={formData.website}
                            onChange={handleChange('website')}
                            disabled={!isEdited}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <PhysicalAddressInput
                          value={formData.physical_address}
                          onChange={handleChange('physical_address')}
                          disabled={!isEdited}
                        />
                      </Grid>
                      {!simplified && (
                        <Grid item xs={12}>
                          <PhysicalCodenput
                            value={formData.physical_code}
                            onChange={handleChange('physical_code')}
                            disabled={!isEdited}
                          />
                        </Grid>
                      )}
                      {!simplified && (
                        <Grid item xs={12}>
                          <PhysicalCityInput
                            value={formData.physical_city}
                            onChange={handleChange('physical_city')}
                            disabled={!isEdited}
                          />
                        </Grid>
                      )}
                      {!simplified && (
                        <Grid item xs={12}>
                          <PhysicalCountryInput
                            value={formData.physical_country}
                            onChange={handleChange('physical_country')}
                            disabled={!isEdited}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PostalAddressInput
                          value={formData.postal_address}
                          onChange={handleChange('postal_address')}
                          disabled={!isEdited}
                        />
                      </Grid>
                      {!simplified && (
                        <Grid item xs={12}>
                          <PostalCodeInput
                            value={formData.postal_code}
                            onChange={handleChange('postal_code')}
                            disabled={!isEdited}
                          />
                        </Grid>
                      )}
                      {!simplified && (
                        <Grid item xs={12}>
                          <PostalCityInput
                            value={formData.postal_city}
                            onChange={handleChange('postal_city')}
                            disabled={!isEdited}
                          />
                        </Grid>
                      )}
                      {!simplified && (
                        <Grid item xs={12}>
                          <PostalCountryInput
                            value={formData.postal_country}
                            onChange={handleChange('postal_country')}
                            disabled={!isEdited}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <IndustryTypeInput
                          value={formData.industy_type}
                          onChange={handleChange('industy_type')}
                          disabled={!isEdited}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <PrefferredContactMethodInput
                          value={formData.preferred_contact_method}
                          onChange={handleChange('preferred_contact_method')}
                          disabled={!isEdited}
                          autocompleteRef={autocompleteRef}
                          popperRef={popperRef}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <PreferredCurrencyInput
                          value={formData.preferred_currency}
                          onChange={handleChange('preferred_currency')}
                          disabled={!isEdited}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
      <ConfirmModal open={showModal} onClose={saveChanges} onConfirm={confirmExit} />
    </Grid>
  );
}
