import React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

interface TaxRegistrationNumberInputProps {
  value?: string | null;
  onChange: (value: string | null) => void;
  disabled: boolean;
}

export default function TaxRegistrationNumberInput({
  value,
  onChange,
  disabled,
}: TaxRegistrationNumberInputProps) {
  const { t } = useTranslation();

  return (
    <TextField
      id='registration-number-input'
      label={t('formInputs.taxregistrationNumberInputLabel')}
      variant='outlined'
      fullWidth
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value ? e.target.value : null)}
      disabled={disabled}
    />
  );
}
