import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';
import {
  Grid,
  Typography,
  Stack,
  Button,
  IconButton,
  Box,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { useTranslation } from 'react-i18next';
import BasicTabs from '../../common/basic-tabs/BasicTabs';
import AllTabs from './components/AllTabls';
import DetailsTabs from './components/DetailsTab';
import ContactsTabs from './components/ContactsTabs';
import DocumentsTabs from './components/DocumentsTabs';
import NotesTab from '../../common/notes-tab/NotesTab';
import FormUpload from '../../common/form-upload/FormUpload';
import UploadButton from '../../common/upload-button/UploadButton';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import {
  fetchBusinessProfileById,
  selectBusinessProfileContacts,
  selectBusinessProfileDetails,
} from '../../slices/business-profile/businessProfileSlice';
import styles from './BusinessProfile.module.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CreateContactModal from '../../common/create-contact-modal/CreateContactModal';
import { fetchCompanies, selectAllCompanies } from '../../slices/companies/companiesSlice';

const BusinessProfile = () => {
  const dispatch = useAppDispatch();
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const company = useAppSelector(selectAllCompanies)[0];
  const businessProfileDetails = useAppSelector(selectBusinessProfileDetails);
  const businessContacts = useAppSelector(selectBusinessProfileContacts);
  const formUploadRef = useRef<HTMLDivElement>(null);
  const uploadButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (company) {
      dispatch(fetchBusinessProfileById({ id: company.id }));
    }
  }, [company, dispatch]);

  useEffect(() => {
    dispatch(switchDesign(true));
  }, [dispatch]);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleUploadClose = () => {
    setIsUploadOpen(false);
  };

  // Actually handles both open & close
  const handleUploadOpen = () => {
    setIsUploadOpen((prev) => !prev);
  };

  const toogleShowAddlModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  if (!company) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={3} sx={{ mt: '-100px' }}>
      <Grid item xs={12} sx={{ pt: '32px !important' }}>
        <Stack spacing={0} sx={{ width: '50%', marginBottom: '20px' }}>
          <Typography variant='h5' component='h1' className={styles.page_title}>
            {t('headers.businessProfile')}
          </Typography>
          <Stack direction='row' marginTop={'8px'} spacing={2}>
            <Box ref={uploadButtonRef}>
              <UploadButton onClick={handleUploadOpen} buttonText='Upload' isArrowDown={false} />
            </Box>
            {/*!isUploadOpen && (
              <Box onClick={handleUploadOpen} sx={{ display: 'flex', alignItems: 'center' }}>
                <AddIcon
                  className='add-icon'
                  style={{ cursor: 'pointer' }}
                  onClick={handleUploadOpen}
                />
              </Box>
            )*/}
            {isUploadOpen && (
              <Box className={styles.form_box} ref={formUploadRef}>
                <FormUpload
                  onCancel={handleUploadClose}
                  categoryValue='business'
                  businessContacts={businessContacts} // Pass empty array or relevant data if needed
                />
              </Box>
            )}
            <Tooltip title={t('businessProfile.addContacts')} placement='top'>
              <IconButton onClick={toogleShowAddlModal} className={styles.add_button}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Box className={styles.document_header}>
          <Typography variant='h6' component='h2'>
            {businessProfileDetails && company ? company.name : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} className={styles.grid_margin_bottom}>
        <BasicTabs
          tabs={[
            t('businessProfile.allTab'),
            t('businessProfile.detailsTab'),
            t('businessProfile.contactsTab'),
            t('businessProfile.documentsTabs'),
            t('businessProfile.notesTab'),
          ]}
          tabContents={[
            <AllTabs company={company} key='all' />,
            <DetailsTabs company={company} key='details' />,
            <ContactsTabs companyId={company?.id} key='contacts' />,
            <DocumentsTabs company={company} key='documents' />,
            <NotesTab companyId={company?.id} key='notes' />,
          ]}
        />
      </Grid>
      {isModalOpen && (
        <CreateContactModal
          companyId={company?.id}
          open={isModalOpen}
          onClose={() => toogleShowAddlModal()}
        />
      )}
    </Grid>
  );
};

export default BusinessProfile;
