import React from 'react';
import { Box, Typography, Button, IconButton, Grid } from '@mui/material';
import styles from './DocumentContent.module.scss';
import AISummaryIcon from '../../../../../static/assets/svg/list_alt_check.svg';
import FileSaveIcon from '../../../../../static/assets/svg/file_save.svg';
import EditContractIcon from '../../../../../static/assets/svg/contract_edit.svg';
import BrushIcon from '../../../../../static/assets/svg/brush.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const DocumentContent: React.FC = () => {
  return (
    <Box className={styles.container}>
      <Box className={styles.mainContent}>
        <Box className={styles.documentPreview} />
      </Box>

      <Box className={styles.topBar}>
        <Button variant='contained' className={styles.createButton}>
          Create template
        </Button>
        <IconButton className={styles.actionButton}>
          <AISummaryIcon />
        </IconButton>
        <IconButton className={styles.actionButton}>
          <FileSaveIcon />
        </IconButton>
        <IconButton className={styles.actionButton}>
          <EditContractIcon />
        </IconButton>
        <IconButton className={styles.actionButton}>
          <BrushIcon />
        </IconButton>
        <IconButton className={styles.actionButton}>
          <MoreVertIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DocumentContent;
