import { useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { fetchCategories, selectAllCategories } from '../../../slices/categories/categoriesSlice';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { ROUTES } from '../../../common/constants/routes';

export const useCategoryList = () => {
  const categories = useAppSelector(selectAllCategories);
  const { name } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location.pathname === ROUTES.CATEGORY_LIST) {
      dispatch(fetchCategories());
    }
  }, []);

  const categoriesWithSubcategories = useMemo(() => {
    return categories
      ? categories.reduce((tempCategories, parentCategory) => {
          //const isChildren = Boolean(parentCategory.parent);
          const isParent = parentCategory.sub_categories.length > 0;
          /*if (!isChildren)*/
          if (isParent) {
            if ((name && name.toLowerCase() === parentCategory.name.toLowerCase()) || !name) {
              const subcategories = categories.filter(
                (category) => category.parent === parentCategory.id
              );
              tempCategories.push({
                ...parentCategory,
                subcategories,
              });
            }
          }

          return tempCategories;
        }, [])
      : [];
  }, [categories, name]);

  return {
    categories: categoriesWithSubcategories,
    isGroup: Boolean(name),
  };
};
