import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { addCompany } from '../../slices/companies/companiesSlice';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { toast } from 'react-toastify';
import { ProgressOverlay } from '../progress-overlay/ProgressOverlay';

const CompanyCreateForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState<string>('');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState<string>('');

  const createCompany = () => {
    setLoading(true);
    const data = {
      name: businessName,
      phone_number: businessPhoneNumber,
      business_abn_acn: businessRegistrationNumber,
      //country,
    };
    dispatch(addCompany({ data }))
      .unwrap()
      .then(() => {
        toast.success(t('messages.companyCreated'));
      })
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Box className='company-create-form'>
        <Box className='message-container'>
          <p>{t('messages.finishCompanySetUp')}</p>
        </Box>
        <Box component='form' noValidate autoComplete='off'>
          <TextField
            label={t('labels.businessName')}
            onChange={(event) => setBusinessName(event.target.value)}
            variant='outlined'
            required={true}
          />
          {/*<TextField
            label={t('labels.country')}
            onChange={(event) => setCountry(event.target.value)}
            variant='outlined'
            required={true}
          />*/}
          <TextField
            label={t('labels.businessPhoneNumber')}
            onChange={(event) => setBusinessPhoneNumber(event.target.value)}
            variant='outlined'
          />
          <TextField
            label={t('labels.businessRegistrationNumber')}
            onChange={(event) => setBusinessRegistrationNumber(event.target.value)}
            variant='outlined'
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              width: '100%',
              marginTop: 'auto',
            }}
          >
            <Button
              variant='contained'
              className='btn-default btn-action-basic'
              type='button'
              onClick={() => createCompany()}
            >
              {t('buttons.submit')}
            </Button>
          </Box>
        </Box>
      </Box>
      {loading && <ProgressOverlay />}
    </>
  );
};

export default CompanyCreateForm;
