import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { TextField, Button, Box, Stack, Typography } from '@mui/material';
import { addTodo, updateTodo } from '../../../../slices/todo/todoSlice';
import { selectUser } from '../../../../slices/user/userSlice';
import { useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AddTodoFormProps {
  existingTodo?: {
    id: number;
    message: string;
    due_at: string | null;
    started_at: string | null;
    completed_at: string | null;
  };
  onClose?: () => void;
}

const TodoForm = ({ existingTodo, onClose }: AddTodoFormProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(true);
  const [message, setMessage] = useState('');
  const [dueAt, setDueAt] = useState<string | null>(null);
  const [startedAt, setStartedAt] = useState<string | null>(null);
  const [completedAt, setCompletedAt] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [messageError, setMessageError] = useState<string>('');
  const [dateError, setDateError] = useState<string>('');
  const { t } = useTranslation();
  const localTheme = createTheme({});

  useEffect(() => {
    if (existingTodo) {
      setMessage(existingTodo.message);
      setDueAt(existingTodo.due_at);
    }
  }, [existingTodo]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!message) {
      setMessageError('Please enter a task description');
    } else {
      setMessageError('');
    }

    if (!dueAt) {
      setDateError('Please select a completion date');
    } else {
      setDateError('');
    }

    if (!message || !dueAt) {
      return;
    }

    const todoData = {
      user: user.id,
      message,
      due_at: dueAt,
      started_at: new Date().toISOString(),
      completed_at: completedAt,
    };

    if (existingTodo) {
      dispatch(updateTodo({ id: existingTodo.id, data: todoData }));
    } else {
      dispatch(addTodo({ data: todoData }));
    }

    setMessage('');
    setDueAt('');
    setStartedAt(null);
    setCompletedAt(null);

    if (onClose) {
      onClose();
    }
  };
  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    document.dispatchEvent(new Event('closeModal'));
  };

  return (
    <ThemeProvider theme={localTheme}>
      <form onSubmit={handleSubmit} className='todo-form'>
        <Box className='todo-header'>
          <Typography variant='h5' component='h2'>
            To do:
          </Typography>
          <IconButton className='close-button' onClick={onClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stack spacing={3}>
          <Box>
            <TextField
              fullWidth
              label='Task *'
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                if (isSubmitted) setMessageError('');
              }}
              placeholder='Add a message'
              variant='outlined'
              size='medium'
              className='task-field'
              error={!!messageError}
            />
          </Box>

          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '100%' }}
                label='Complete by *'
                value={dueAt ? dayjs(dueAt) : null}
                onChange={(newValue) => {
                  setDueAt(newValue ? newValue.format('YYYY-MM-DD') : null);
                  if (isSubmitted) setDateError('');
                }}
                format='DD.MM.YYYY'
                className='date-field'
                slotProps={{
                  textField: {
                    error: !!dateError,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>

          <Box className='form-actions'>
            <Button variant='text' className='cancel-button' onClick={handleCancel}>
              {t('buttons.cancel')}
            </Button>
            {existingTodo && (
              <Button variant='contained' type='submit' className='submit-button btn-action-basic'>
                {t('buttons.change')}
              </Button>
            )}
            {!existingTodo && (
              <Button variant='contained' type='submit' className='submit-button btn-action-basic'>
                {t('buttons.add')}
              </Button>
            )}
          </Box>
        </Stack>
      </form>
    </ThemeProvider>
  );
};

export default TodoForm;
