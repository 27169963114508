import React, { useEffect } from 'react';
import { Grid } from '@mui/material';

import OrganizationInfoCard from '../../../common/organization-info-card/OrganizationInfoCard';
import ContactCard from '../../../common/contact-card/ContactCard';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { fetchCompanies, selectAllCompanies } from '../../../slices/companies/companiesSlice';
import {
  fetchBusinessProfileContactById,
  selectBusinessProfileContacts,
  selectBusinessProfileStatus,
} from '../../../slices/business-profile/businessProfileSlice';

interface ContactsTabsProps {
  companyId: number;
  key: string;
}

const ContactsTabs: React.FC<ContactsTabsProps> = ({ companyId }) => {
  const dispatch = useAppDispatch();
  const businessProfileStatus = useAppSelector(selectBusinessProfileStatus);
  const businessProfileContacts = useAppSelector(selectBusinessProfileContacts);

  useEffect(() => {
    if (!businessProfileContacts) {
      dispatch(fetchBusinessProfileContactById({ id: companyId }));
    }
  }, [dispatch]);

  return (
    <>
      <Grid container wrap='wrap' rowGap={2}>
        <Grid item xs={12}>
          <OrganizationInfoCard />
        </Grid>

        <Grid item xs={12} container spacing={2} direction='column'>
          {businessProfileContacts.length > 0 &&
            businessProfileContacts.map((contact) => (
              <Grid item key={contact.id}>
                <ContactCard contact={contact} companyId={companyId} />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default ContactsTabs;
