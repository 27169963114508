import React from 'react';
import { Grid } from '@mui/material';
import { SettingsMenu } from './components/SettingsMenu';
import { CustomerDetailsTab } from './components/tabs/CustomerDetailsTab';
import { CustomerContactTab } from './components/tabs/CustomerContactTab';
import { CustomerNotesTab } from './components/tabs/CustomerNotesTab';
import { CustomerDocumentsTab } from './components/tabs/CustomerDocumentsTab';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const CustomerProfilePage = () => {
  const { customerId } = useParams();
  const { t } = useTranslation();
  const company = useAppSelector(selectAllCompanies)[0];
  const customer = company?.customers?.find((el) => el.id === Number(customerId));
  const [currentTab, setCurrentTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      {company ? (
        <h1 className='mb-4'>{`${
          customer ? customer.customer_name : t('customerPage.createCustomerHeader')
        }`}</h1>
      ) : null}
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <SettingsMenu value={currentTab} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} md={9} className='inputs-editable'>
          <div className='setting-panel mb-5'>
            <CustomerDetailsTab currentTab={currentTab} />
            <CustomerContactTab currentTab={currentTab} />
            <CustomerNotesTab currentTab={currentTab} />
            <CustomerDocumentsTab currentTab={currentTab} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default CustomerProfilePage;
