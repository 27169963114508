// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.djg7GTbuHNm48cW8o3q9{display:flex;align-items:center;justify-content:center;position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.5)}.E9RGy1d8hwbqeYbbvnz9{margin-bottom:16px !important}.oGPAxZvV6FamthKclWVX{margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/app/common/confirm-modal/ConfirmModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA,CAGF,sBACE,6BAAA,CAGF,sBACE,eAAA","sourcesContent":[".modal_container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); // Fondo oscuro semi-transparente\n}\n\n.modal_text {\n  margin-bottom: 16px !important;\n}\n\n.modal_actions {\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_container": `djg7GTbuHNm48cW8o3q9`,
	"modal_text": `E9RGy1d8hwbqeYbbvnz9`,
	"modal_actions": `oGPAxZvV6FamthKclWVX`
};
export default ___CSS_LOADER_EXPORT___;
