import React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

interface CountryInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

export default function CountryInput({ value, onChange, disabled }: CountryInputProps) {
  const { t } = useTranslation();

  return (
    <TextField
      id='country-input'
      label={t('formInputs.countryInputLabel')}
      variant='outlined'
      fullWidth
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}
