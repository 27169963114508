import React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

interface BusinessPhoneNumberInputProps {
  value?: string | null;
  onChange: (value: string) => void;
  disabled: boolean;
  label?: string;
}

export default function BusinessPhoneNumberInput({
  value,
  onChange,
  disabled,
  label,
}: BusinessPhoneNumberInputProps) {
  const { t } = useTranslation();

  return (
    <TextField
      id='business-phone-input'
      label={label ? label : t('formInputs.businessPhoneNumberInputLabel')}
      variant='outlined'
      fullWidth
      type='tel'
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}
