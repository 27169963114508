import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { Supplier, SupplierSearchData } from '../../models/supplier.model';
import { RootState } from '../../store';

export const fetchSuppliers = createAsyncThunk(
  'companies/fetchSuppliers',
  async ({ company_id }: SupplierSearchData, { rejectWithValue }) => {
    if (company_id === undefined) {
      return rejectWithValue('Company ID is required');
    }

    const response = await NWClient.list('supplier_profile', { company_id });

    return response;
  }
);

export interface SuppliersState {
  suppliersList: Supplier[];
  status: string;
  error: string;
}

const suppliersArray: Supplier[] = [];
const initialState: SuppliersState = { suppliersList: suppliersArray, status: 'idle', error: '' };
const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSuppliers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSuppliers.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.suppliersList = [...action.payload];
          state.status = 'succeeded';
        }
      })
      .addCase(fetchSuppliers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default suppliersSlice.reducer;

export const selectAllSuppliers = (state: RootState) => state.suppliers.suppliersList;
export const selectSupplierById = (state: RootState, supplierId: number) =>
  state.suppliers.suppliersList.find((item) => item.id === supplierId);
