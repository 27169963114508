import React, { useState, ReactNode, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import styles from './BasicTabs.module.scss';

interface TabsProps {
  tabs: string[];
  tabContents: ReactNode[];
  initialTab?: number;
}

export default function BasicTabs({ tabs, tabContents, initialTab }: TabsProps) {
  const [value, setValue] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (initialTab) {
      setValue(initialTab);
    }
  }, []);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='dynamic tabs'
        className={styles.tab_margin}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab} className={styles.tab_border_radius} />
        ))}
      </Tabs>

      <Box>{tabContents[value]}</Box>
    </>
  );
}
