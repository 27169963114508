// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OJakXlJo1ffagxScDO8S{margin-top:16px}.rbwUZItv5bGjwkM4x6nI{display:flex;align-items:center;justify-content:center;position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.5)}.U2iHeEzi2n9g7QyWJleU svg{width:30px;height:30px}.wWgL2Xyc18HLE53Ugtlo{width:100%}.wWgL2Xyc18HLE53Ugtlo label{transform:translate(14px, -9px) scale(0.75) !important}.n42vmtgyXnCNTyaE7hnr{width:100%}`, "",{"version":3,"sources":["webpack://./src/app/common/ai-summary-dialog-v2/AISummaryDialog.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA,CAKA,0BACE,UAAA,CACA,WAAA,CAKJ,sBACE,UAAA,CAEA,4BACE,sDAAA,CAKJ,sBACE,UAAA","sourcesContent":[".checkbox_container {\n  margin-top: 16px;\n}\n\n.modal_container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); // Fondo oscuro semi-transparente\n}\n\n.title_container {\n\n  svg {\n    width: 30px;\n    height: 30px;\n  }\n\n}\n\n.textarea_container {\n  width: 100%;\n\n  label {\n    transform: translate(14px, -9px) scale(0.75) !important;\n  }\n\n}\n\n.w100 {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox_container": `OJakXlJo1ffagxScDO8S`,
	"modal_container": `rbwUZItv5bGjwkM4x6nI`,
	"title_container": `U2iHeEzi2n9g7QyWJleU`,
	"textarea_container": `wWgL2Xyc18HLE53Ugtlo`,
	"w100": `n42vmtgyXnCNTyaE7hnr`
};
export default ___CSS_LOADER_EXPORT___;
