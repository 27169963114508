import React from 'react';
import { ListItemButtonProps } from './types';
import { Button } from '@mui/material';
import AddIcon from '../../../static/assets/svg/add-icon.svg';

const ListItemButton = ({ onClick, buttonText }: ListItemButtonProps) => {
  return (
    <Button variant='outlined' className='list-item-button' onClick={onClick}>
      <span className='button-text'>{buttonText}</span>
      <span className='add-icon-wrapper d-flex justify-content-center align-items-center'>
        <AddIcon />
      </span>
    </Button>
  );
};

export default ListItemButton;
