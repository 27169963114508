import React from 'react';
import { Modal, Button, Typography, Grid, Card, CardContent, Box } from '@mui/material';
import styles from './MessageModal.module.scss';
import { MessageModalProps } from './types';

export default function MessageModal({
  open,
  onClose,
  modalMessage,
  modalTitle,
  goBackButtonText,
  actionButtonText,
  actionButtonFn,
  actionButtonAdditionalFn,
  actionButtonAdditionalText,
  modalContent,
  headerVariant,
}: MessageModalProps) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition className='action-modal'>
      <Grid container className={styles.modal_container}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
              {modalTitle && (
                <Typography variant={headerVariant ? headerVariant : 'h6'} gutterBottom>
                  {modalTitle}
                </Typography>
              )}
              {modalMessage && <Typography>{modalMessage}</Typography>}
              {modalContent && (
                <Box sx={{ maxHeight: '500px', overflow: 'auto' }}>{modalContent}</Box>
              )}
              <Grid container justifyContent='flex-end' gap={'8px'} mt={2}>
                <Button
                  variant='contained'
                  onClick={onClose}
                  className='btn-default btn-action-basic btn-cancel'
                >
                  {goBackButtonText}
                </Button>
                {actionButtonAdditionalFn && actionButtonAdditionalText && (
                  <Button
                    variant='contained'
                    onClick={actionButtonAdditionalFn}
                    className='btn-default btn-action-basic'
                  >
                    {actionButtonAdditionalText}
                  </Button>
                )}
                <Button
                  variant='contained'
                  onClick={actionButtonFn}
                  className='btn-default btn-action-basic'
                >
                  {actionButtonText}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
}
