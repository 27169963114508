import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CustomerNameInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  sx?: any;
}

export default function CustomerNameInput({
  value,
  onChange,
  disabled,
  sx,
}: CustomerNameInputProps) {
  const { t } = useTranslation();

  return (
    <TextField
      id='contact-name-input'
      label={t('formInputs.customerNameInputLabel')}
      variant='outlined'
      fullWidth
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      sx={sx}
    />
  );
}
