import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';

import BusinessNameInput from '../form-inputs/business-name-input/BusinessNameInput';
import BusinessEmailAddressInput from '../form-inputs/business-email-address-input/BusinessEmailAddressInput';
import RegisteredAddressInput from '../form-inputs/registered-address-input/RegisteredAddressInput';
import PhysicalAddressInput from '../form-inputs/physical-address-input/PhysicalAddressInput';
import BusinessPhoneNumberInput from '../form-inputs/bussiness-phone-number-input/BusinessPhoneNumberInput';
import CountryInput from '../form-inputs/country-input/CountryInput';
import PostalAddressInput from '../form-inputs/postal-address-input/PostalAddressInput';
import RegistrationNumberInput from '../form-inputs/registration-number-input/RegistrationNumberInput';
import AccountCircleIcon from '../../../static/assets/svg/account_circle.svg';
import WebIcon from '../../../static/assets/svg/captive_portal.svg';
import InstagramIcon from '../../../static/assets/svg/instagram.svg';
import BrushIcon from '../../../static/assets/svg/brush.svg';
import OrganizationIcon from '../../../static/assets/svg/organization.svg';

import {
  fetchBusinessProfileById,
  selectBusinessProfileStatus,
  selectBusinessProfileDetails,
} from '../../slices/business-profile/businessProfileSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { useDetailsCardForm } from './useDetailsCardForm';
import styles from './DetailsCard.module.scss';
import ConfirmModal from '../confirm-modal/ConfirmModal';
import TaxRegistrationNumberInput from '../form-inputs/registration-number-input/TaxRegostrationNumberInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import AddSocialsModal from '../add-socials-modal/AddSocialsModal';
// import { SocialLink } from '../social-media-form/SocialMediaForm';
import { useAppSelector } from '../hooks/useAppSelector';
import { Company } from '../../models/company.model';

type DetailsCardProps = {
  simplified?: boolean;
  company: Company;
};

export default function DetailsCard({ simplified, company }: DetailsCardProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const businessProfileDetails = useAppSelector(selectBusinessProfileDetails);
  const businessProfileStatus = useAppSelector(selectBusinessProfileStatus);
  const isLoading = businessProfileStatus === 'loading';

  const [companyName, setCompanyName] = useState(company.name);

  const LINKS = {
    web: 'www.company.com',
    instagram: 'username',
  };

  // const socialLinks: SocialLink[] = [
  //   // TODO: this will be replaced by endpoint response
  //   { key: 'website', name: 'website', url: 'https://company.com', icon: <WebIcon /> },
  //   { key: 'facebook', name: 'facebook', url: 'https://facebook.com', icon: <InstagramIcon /> },
  //   { key: 'twitter', name: 'twitter', url: 'https://twitter.com', icon: <InstagramIcon /> },
  //   { key: 'instagram', name: 'instagram', url: 'https://instagram.com', icon: <InstagramIcon /> },
  //   { key: 'linkedin', name: 'linkedin', url: 'https://linkedin.com', icon: <InstagramIcon /> },
  //   { key: 'youtube', name: 'youtube', url: 'https://youtube.com', icon: <InstagramIcon /> },
  // ];

  useEffect(() => {
    if (!businessProfileDetails) {
      dispatch(fetchBusinessProfileById({ id: company.id }));
    }
  }, [dispatch]);

  useEffect(() => {
    setCompanyName(company.name);
  }, [company.name]);
  const {
    formData,
    handleChange,
    handleCompanyNameChange,
    saveChanges,
    isEdited,
    toggleEditing,
    formRef,
    showModal,
    confirmExit,
    showAddSocialModal,
    setAddSocialShowModal,
    toogleEditingSocialModal,
    saveChangesSocialForm,
  } = useDetailsCardForm(company.id);

  const disabledStyles = {
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black', // Change text color
      WebkitTextFillColor: 'black', // Override browser autofill styles
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: '#f0f0f0', // Change background
      borderColor: 'red', // Change border color
    },
  };

  return (
    <Grid container className='inputs-editable'>
      <Grid item xs={12}>
        <Card className={`${styles.card} ${isEdited ? styles.card_border : ''}`} ref={formRef}>
          <CardContent
            className={isLoading ? styles.card_content_min_height : ''}
            sx={{ paddingTop: 0, paddingBottom: '0 !important' }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container alignItems='center' flexWrap='nowrap' p={1}>
                  <Grid item xs={11} container alignItems='center' gap={1}>
                    <Grid sx={{ marginBottom: '0.2rem' }} item>
                      <OrganizationIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant='h6' className='semibold-variant'>
                        {t('businessProfile.detailsCardHeader')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} container justifyContent='flex-end'>
                    {!isEdited ? (
                      <Tooltip className='flex-shrink-0' title={t('buttons.edit')} placement='top'>
                        <IconButton onClick={toggleEditing}>
                          <BrushIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Button
                        type='submit'
                        className='btn-default btn-action-basic'
                        role='button'
                        disabled={!isEdited}
                        variant='contained'
                        size='large'
                        onClick={saveChanges}
                      >
                        {t('buttons.save')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                {!simplified ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            value={companyName}
                            label={t('formInputs.businessNameInputLabel')}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                              handleCompanyNameChange(e.target.value);
                            }}
                            disabled={!isEdited}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <BusinessPhoneNumberInput
                            value={formData.business_phone_number}
                            onChange={handleChange('business_phone_number')}
                            disabled={!isEdited}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <BusinessEmailAddressInput
                            value={formData.business_email_address}
                            onChange={handleChange('business_email_address')}
                            disabled={!isEdited}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CountryInput
                            value={formData.business_country}
                            onChange={handleChange('business_country')}
                            disabled={!isEdited}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <RegistrationNumberInput
                                value={formData.registration_number}
                                onChange={handleChange('registration_number')}
                                disabled={!isEdited}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TaxRegistrationNumberInput
                                value={formData.registration_number_2_or_tax_number}
                                onChange={handleChange('registration_number_2_or_tax_number')}
                                disabled={!isEdited}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <RegisteredAddressInput
                            value={formData.registered_address}
                            onChange={handleChange('registered_address')}
                            disabled={!isEdited}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <PhysicalAddressInput
                            value={formData.physical_address}
                            onChange={handleChange('physical_address')}
                            disabled={!isEdited}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <PostalAddressInput
                            value={formData.postal_address}
                            onChange={handleChange('postal_address')}
                            disabled={!isEdited}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        fullWidth
                        value={companyName}
                        label={t('formInputs.businessNameInputLabel')}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                          handleCompanyNameChange(e.target.value);
                        }}
                        disabled={!isEdited}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <BusinessEmailAddressInput
                        value={formData.business_email_address}
                        onChange={handleChange('business_email_address')}
                        disabled={!isEdited}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <BusinessPhoneNumberInput
                        value={formData.business_phone_number}
                        onChange={handleChange('business_phone_number')}
                        disabled={!isEdited}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <RegistrationNumberInput
                            value={formData.registration_number}
                            onChange={handleChange('registration_number')}
                            disabled={!isEdited}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TaxRegistrationNumberInput
                            value={formData.registration_number_2_or_tax_number}
                            onChange={handleChange('registration_number_2_or_tax_number')}
                            disabled={!isEdited}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {
                  <Grid container className={styles.container_margin_top} alignItems='center'>
                    <Grid item>
                      {/* <Tooltip title={t('businessProfile.addSocials')} placement='top'>
                        <IconButton
                          onClick={toogleEditingSocialModal}
                          className={styles.add_social_button}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip> */}
                    </Grid>
                  </Grid>
                }
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
      <ConfirmModal open={showModal} onClose={saveChanges} onConfirm={confirmExit} />
      {/* <AddSocialsModal
        open={showAddSocialModal}
        onClose={saveChangesSocialForm}
        onConfirm={toogleEditingSocialModal}
      /> */}
    </Grid>
  );
}
