import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ContactNameInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  submitting?: boolean;
}

export default function ContactNameInput({
  value,
  onChange,
  disabled,
  submitting,
}: ContactNameInputProps) {
  const { t } = useTranslation();
  const [isTouched, setIsTouched] = useState(false);

  const handleBlur = () => {
    setIsTouched(true);
  };

  return (
    <TextField
      id='contact-name-input'
      error={(submitting && value === '') || (isTouched && value === '')}
      label={`${t('formInputs.contactNameInputLabel')} *`}
      variant='outlined'
      type='email'
      fullWidth
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      onBlur={handleBlur}
      disabled={disabled}
    />
  );
}
