import { useEffect, useState } from 'react';
import { NWClient } from '../../../client/NWClient';
import { Helpers } from '../../../common/helpers/helpers';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants/routes';
export const useLibraryFunctions = () => {
  const navigate = useNavigate();
  const [pendingDownload, setPendingDownload] = useState<boolean>(false);
  const [planUpgradingMessage, setPlanUpgradingMessage] = useState<boolean>(false);
  const [disclaimerMessage, setDisclaimerMessage] = useState<boolean>(false);
  const handleS3DownloadLink = (id: number, filename: string) => {
    setPendingDownload(true);
    NWClient.downloadLibraryDocument(id, filename)
      .catch((error) => {
        const errorMessage = Helpers.returnErrorText(error);
        toast.error(errorMessage.toString(), { theme: 'colored' });
      })
      .finally(() => {
        setPendingDownload(false);
      });
  };

  const navigateToTemplatePage = (category: string, id: number) => {
    navigate(`${ROUTES.DOCUMENT_LIBRARY}/${category}/${id}?templateViewEditMode=true`);
  };

  return {
    handleS3DownloadLink,
    planUpgradingMessage,
    setPlanUpgradingMessage,
    pendingDownload,
    navigateToTemplatePage,
    disclaimerMessage,
    setDisclaimerMessage,
  };
};
