import React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

interface PhysicalAddressInputProps {
  value?: string | null;
  onChange: (value: string) => void;
  disabled: boolean;
}

export default function PhysicalAddressInput({
  value,
  onChange,
  disabled,
}: PhysicalAddressInputProps) {
  const { t } = useTranslation();

  return (
    <TextField
      id='physical-address-input'
      label={t('formInputs.physicalAddressInputLabel')}
      variant='outlined'
      fullWidth
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}
