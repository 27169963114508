import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './AiSummaryDetails.module.scss';
import AISummaryIcon from '../../../../../static/assets/svg/list_alt_check.svg';
import FileSaveIcon from '../../../../../static/assets/svg/file_save.svg';
import EditContractIcon from '../../../../../static/assets/svg/contract_edit.svg';
import BrushIcon from '../../../../../static/assets/svg/brush.svg';

const AiSummaryDetails: React.FC = () => (
  <Box className={styles.rightSection}>
    <Box className={styles.aiSummaryHeader}>
      <Typography className={styles.aiSummaryTitle}>AI document summary</Typography>
      <Box className={styles.headerActions}>
        <IconButton size='small' className={styles.actionButton}>
          <AISummaryIcon />
        </IconButton>
        <IconButton size='small' className={styles.actionButton}>
          <FileSaveIcon />
        </IconButton>
        <IconButton size='small' className={styles.actionButton}>
          <EditContractIcon />
        </IconButton>
        <IconButton size='small' className={styles.actionButton}>
          <BrushIcon />
        </IconButton>
        <IconButton size='small' className={styles.actionButton}>
          <MoreVertIcon fontSize='small' />
        </IconButton>
      </Box>
    </Box>
    <Box className={styles.aiSummaryContent}>
      <Box className={styles.documentImage} />
    </Box>
  </Box>
);

export default AiSummaryDetails;
