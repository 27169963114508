import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NWClient } from '../../../../../client/NWClient';
import { Company } from '../../../../../models/company.model';
import { InviteEmployeesForm } from '../../forms/InviteEmployeesForm';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel } from '../../../../../common/tabpanel/TabPanel';
import { Helpers } from '../../../../../common/helpers/helpers';
import Button from '@mui/material/Button';
//import { CopyToClipBoardItem } from '../../../../../common/copy-to-clipboard-item';
//import InviteCSVFileUploadForm from '../../forms/InviteCSVFileUploadForm';
import { useTranslation } from 'react-i18next';

export interface EmployeesCreateModalProps {
  open: boolean;
  onClose: () => void;
}

export const EmployeesCreateModal = ({ open, onClose }: EmployeesCreateModalProps) => {
  const { t } = useTranslation();
  const [company, setCompany] = useState<Company>(null);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (open) {
      NWClient.getCurrentCompany().then((res) => {
        if (Array.isArray(res) ? res.length > 0 : Boolean(res)) {
          setCompany(Array.isArray(res) ? res[0] : res);
        }
      });
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} className='employees-create-modal'>
      <Grid container className='modal'>
        <Grid container item justifyContent='flex-end'>
          <IconButton className='modal-close' onClick={onClose}>
            Close
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container item id='modal-body' className='modal-body'>
          <Typography id='modal-title' className='modal-title' variant='h6' component='h2'>
            {t('messages.inviteEmployeeText')} {company?.name}
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label=''>
                <Tab label='Invite with email' {...Helpers.a11yProps(0, 'simple')} />
                {/*<Tab label='Invite with link' {...Helpers.a11yProps(1, 'simple')} />
                <Tab label='Upload CSV (bulk add)' {...Helpers.a11yProps(2, 'simple')} />*/}
              </Tabs>
            </Box>
            <TabPanel index={0} value={value} prefix={'simple'}>
              <div className='modal-tab-content'>
                <Typography className='modal-description' sx={{ mt: 2 }}>
                  {t('messages.inviteEmployeeEnterEmailText')}
                </Typography>
                {company && <InviteEmployeesForm company={company} />}
              </div>
            </TabPanel>
            {/* <TabPanel index={1} value={value} prefix={'simple'}>
              <div className='modal-tab-content'>
                <CopyToClipBoardItem
                  inputText={invitationLinkText}
                  inputDisabled={true}
                  buttonText='Copy link'
                />
              </div>
            </TabPanel>
            <TabPanel index={2} value={value} prefix={'simple'}>
              <div className='modal-tab-content'>
                <InviteCSVFileUploadForm />
              </div>
            </TabPanel>*/}
          </Box>
        </Grid>
        <Grid container justifyContent='flex-end' className='modal-footer'>
          <Button role='button' variant='contained' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
