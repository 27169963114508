import React, { ChangeEvent, useState } from 'react';
import AIIcon from '../../../static/assets/svg/ai-icon.svg';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import DialogItem from '../dialog-item/DialogItem';
import { useTranslation } from 'react-i18next';
import { AISummaryDialogProps } from './types';
import { NWClient } from '../../client/NWClient';
import { toast } from 'react-toastify';
import { useAppSelector } from '../hooks/useAppSelector';
import { selectUser } from '../../slices/user/userSlice';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useParams, useSearchParams } from 'react-router-dom';

const AISummaryDialog = function ({
  open,
  title,
  handleClose,
  handleAnalyzeSuccess,
  documentId,
  setAnalysisLoading,
  documentContractIdCondition,
  handleAnalyzeError,
  handlePlanLimits,
}: AISummaryDialogProps) {
  const icon = <CheckBoxOutlineBlankIcon fontSize='medium' />;
  const checkedIcon = <CheckBoxIcon fontSize='medium' />;
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const purposes = [
    {
      title: t('purpose.verification'),
      value: 'verification',
    },
    {
      title: t('purpose.analysis'),
      value: 'analysis',
    },
    {
      title: t('purpose.recordKeeping'),
      value: 'record keeping',
    },
  ];
  const currentUser = useAppSelector(selectUser);
  const contractGenerated = params.get('contractGenerated');
  const [documentType, setDocumentType] = useState('contract');
  const [purpose, setPurpose] = useState(purposes);
  const [resultFormat, setResultFormat] = useState('table');
  const [detailLevel, setDetailLevel] = useState('high level');
  const [simplifyJargon, setSimplifyJargon] = useState(true);
  const [contextualInformation, setContextualInformation] = useState('');
  const handleAnalyze = () => {
    setAnalysisLoading();
    NWClient.post(
      'ai-assist',
      {
        [documentContractIdCondition ? 'document_contract_id' : 'document_upload_id']:
          Number(documentId),
        document_type: documentType,
        result_format: resultFormat,
        detail_level: detailLevel,
        purpose: JSON.stringify(purpose.map((el) => el.value)),
        simplify_jargon: simplifyJargon,
        contextual_information: contextualInformation,
        user: currentUser.id,
      },
      true
    )
      .then(() => {
        handleAnalyzeSuccess();
      })
      .catch((error) => {
        if (
          error.response?.data?.errors?.length > 0 &&
          error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
        ) {
          handlePlanLimits();
          handleClose();
        } else {
          toast.error(error.message ? error.message : t('messages.errorOccurred'), {
            theme: 'colored',
          });
        }
        handleAnalyzeError();
      });
  };
  return (
    <DialogItem
      isErrorMessage={false}
      open={open}
      title={title}
      noDefaultActionsRow={true}
      headerIcon={<AIIcon />}
      handleClose={handleClose}
    >
      <div className='d-flex mb-3'>
        <p className='text-center flex-grow-1 ps-2 pe-2'>{t('messages.reviewSettings')}</p>
        <Tooltip
          className='flex-shrink-0'
          title={t('messages.AIFunctionalityMessage')}
          placement='top'
        >
          <InfoIcon fontSize='small' color='secondary' />
        </Tooltip>
      </div>
      <hr />
      <Grid>
        <div className='setting-panel-body ps-0 pe-0'>
          <ValidatorForm onSubmit={() => console.log('')}>
            <Grid container className='form-controls' spacing={4}>
              <Grid item xs={12} className='pt-3' md={6}>
                <InputLabel htmlFor='documentTypeSelect'>{t('labels.documentType')}</InputLabel>
                <Select
                  className='w-100'
                  id='documentTypeSelect'
                  onChange={(e: SelectChangeEvent<typeof documentType>) =>
                    setDocumentType(e.target.value)
                  }
                  name='documentType'
                  value={documentType}
                  tabIndex={-1}
                  defaultOpen={true}
                >
                  <MenuItem value='contract'>{t('documentTypes.contract')}</MenuItem>
                  <MenuItem value='insurance'>{t('documentTypes.insurance')}</MenuItem>
                  <MenuItem value='invoice'>{t('documentTypes.invoice')}</MenuItem>
                  <MenuItem value='certificate'>{t('documentTypes.certificate')}</MenuItem>
                  <MenuItem value='other'>{t('documentTypes.other')}</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} className='pt-3' md={6}>
                <Autocomplete
                  className='mt-0'
                  multiple
                  id='tags-standard'
                  openOnFocus={true}
                  options={purposes}
                  disableCloseOnSelect={true}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  getOptionLabel={(option) => option.title}
                  value={purpose}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>,
                    newValue:
                      | {
                          title: string;
                          value: string;
                        }[]
                      | null
                  ) => {
                    setPurpose(newValue);
                  }}
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li key={option.value} {...optionProps}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                        {option.title}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant='standard' label={t('labels.purpose')} />
                  )}
                />
              </Grid>
              <Grid item xs={12} className='pt-3' md={6}>
                <TextValidator
                  select
                  label={t('labels.resultFormat')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setResultFormat(e.target.value);
                  }}
                  name='resultFormat'
                  value={resultFormat}
                >
                  <MenuItem value={''}>{''}</MenuItem>
                  <MenuItem value='table'>{t('resultFormat.table')}</MenuItem>
                  <MenuItem value='bullet point'>{t('resultFormat.bulletPoint')}</MenuItem>
                  <MenuItem value='narrative format'>{t('resultFormat.narrativeFormat')}</MenuItem>
                </TextValidator>
              </Grid>
              <Grid item xs={12} className='pt-3' md={6}>
                <TextValidator
                  select
                  label={t('labels.detailLevel')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setDetailLevel(e.target.value);
                  }}
                  name='detailLevel'
                  value={detailLevel}
                >
                  <MenuItem value='high level'>{t('detailLevel.highLevel')}</MenuItem>
                  <MenuItem value='detailed'>{t('detailLevel.detailed')}</MenuItem>
                </TextValidator>
              </Grid>
              <Grid item xs={12} md={12} className='pt-3 checkbox-row d-flex align-items-start'>
                <Checkbox
                  style={{ marginLeft: -10 }}
                  id='simplifyTechnicalOrLegalJargon'
                  checked={simplifyJargon}
                  onChange={(event) => setSimplifyJargon(event.target.checked)}
                />
                <label htmlFor='simplifyTechnicalOrLegalJargon'>{t('labels.simplifyJargon')}</label>
              </Grid>
              <Grid item xs={12} className='pt-3' md={12}>
                <InputLabel htmlFor='contextualInformation'>
                  {t('labels.contextualInformation')}
                </InputLabel>
                <textarea
                  id='contextualInformation'
                  name='contextualInformation'
                  value={contextualInformation}
                  placeholder={t('messages.contextualInformationPlaceholder')}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    setContextualInformation(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                className='pt-0 buttons-row d-flex flex-wrap justify-content-end'
              >
                <hr className='w-100 mb-3' />
                <Button
                  type='button'
                  role='button'
                  variant='outlined'
                  size='medium'
                  style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                  onClick={handleClose}
                >
                  {t('buttons.goBack')}
                </Button>
                <Button
                  onClick={() => handleAnalyze()}
                  style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                  type='button'
                  role='button'
                  variant='contained'
                  size='medium'
                >
                  {t('buttons.analyze')}
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </Grid>
    </DialogItem>
  );
};

export default AISummaryDialog;
