import React from 'react';
import { List, ListItem, Typography, Divider, Box, IconButton } from '@mui/material';
import { ListItemBlockProps } from './types';
import { Link } from 'react-router-dom';
import StarIcon from '../../../static/assets/svg/kid_star.svg';
import AddIcon from '../../../static/assets/svg/add-icon.svg';
import MenuBlock from '../menu-block';
import { ProgressOverlay } from '../progress-overlay/ProgressOverlay';
import { v4 as uuidv4 } from 'uuid';

const ListItemBlock = ({
  headerText,
  withLinkButton,
  listArray,
  onListItemClick,
  linkURL,
  emptyStateMessage,
  withMenu,
  menuOptions,
  loading,
}: ListItemBlockProps) => {
  return (
    <div className='list-item-block' style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
      {(headerText || withLinkButton) && (
        <Box
          className='list-header'
          sx={{
            justifyContent: headerText ? 'space-between' : 'flex-end',
          }}
        >
          {headerText && (
            <Typography variant='h2' className='header-text'>
              {headerText}
            </Typography>
          )}
          {withLinkButton && (
            <Link
              to={linkURL}
              className='add-icon-wrapper d-flex justify-content-center align-items-center'
            >
              <AddIcon />
            </Link>
          )}
          {withMenu && <MenuBlock options={menuOptions} />}
        </Box>
      )}
      {loading ? (
        <ProgressOverlay className='position-relative flex-grow-1' />
      ) : (
        <>
          {listArray.length > 0 ? (
            <List
              className='list-content'
              sx={{
                padding: headerText || withLinkButton ? '0 16px 16px' : '16px',
              }}
            >
              {listArray.map((item) => (
                <React.Fragment key={`${item.id}-${uuidv4()}`}>
                  <ListItem
                    className='list-item'
                    onClick={item.onListItemClick}
                    sx={{
                      cursor: item.onListItemClick ? 'pointer' : 'initial',
                      '&:hover': {
                        bgcolor: '#F8F9FC',
                      },
                    }}
                  >
                    {item.isFavourite && <StarIcon />}
                    <Typography className='item-name'>{item.name}</Typography>
                    {item.labelText && (
                      <Box className='item-details'>
                        <Typography className='label-text'>{item.labelText}</Typography>
                      </Box>
                    )}
                  </ListItem>
                  <Divider className='item-divider' />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Box className='d-flex flex-column flex-grow-1 justify-content-center align-items-center empty-state-message-container '>
              <div className='empty-state-message'>{emptyStateMessage}</div>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default ListItemBlock;
