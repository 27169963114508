import React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

export const ProgressOverlay = (props: CircularProgressProps) => {
  const { className } = props;
  const { t } = useTranslation();
  return (
    <div className={`progress-wrapper ${className}`}>
      <CircularProgress color='primary' size={50} />
    </div>
  );
};
