import React, { ChangeEvent, useEffect, useState } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { CircularProgress, Divider, FormHelperText, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { TabProps } from './types';
import { NWClient } from '../../../../client/NWClient';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { selectUser, fetchUser } from '../../../../slices/user/userSlice';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import DialogItem from '../../../../common/dialog-item/DialogItem';
import PersonalDetailsIcon from '../../../../../static/assets/svg/menu/personal-details.svg';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Country } from 'country-state-city';
import MenuItem from '@mui/material/MenuItem';

export const PersonalDetailsTab = ({ currentTab }: TabProps) => {
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [employeeId, setEmployeeId] = useState<number>(0);
  const [firstName, setFirstName] = useState<string>('');
  const [middleName, setMiddleName] = useState<string>('');
  const [lastName, setLastName] = useState<string | null>('');
  const [email, setEmail] = useState<string | null>('');
  const [emailBusiness, setEmailBusiness] = useState<string | null>('');
  const [gender, setGender] = useState<string | null>('');
  const [address, setAddress] = useState<string | null>('');
  const [postalCode, setPostalCode] = useState<string | null>('');
  const [cityCounty, setCityCounty] = useState<string | null>('');
  const [phoneNumber, setPhoneNumber] = useState<string | null>('');
  const [country, setCountry] = useState<string | null>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [errorTextArray, setErrorTextArray] = useState([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const { t } = useTranslation();
  const countries = Country.getAllCountries();
  const [details, setDetails] = useState<any>(null);

  const canSubmit = Boolean(firstName) && Boolean(lastName);

  //const isEmployee = Boolean(useSelector(selectUserRoleByGroupName(GROUPS.COMPANY_EMPLOYEE)));

  const resetPasswordMessageTitle = t('passwordReset.header2');
  const resetPasswordMessage = t('passwordReset.message');

  const setUserInfo = (res: any) => {
    setFirstName(res.first_name);
    setLastName(res.last_name);
    setEmail(res.email_address);
    setMiddleName(res.middle_name);
    setAddress(res.address ? res.address : '');
  };

  const setEmployeeDetails = (res: any) => {
    setDetails(res);
    if (res) {
      setGender(res.gender);
      setPostalCode(res.postal_code);
      setCountry(res.country);
      setCityCounty(res.city);
      setEmailBusiness(res.business_email_address);
      setPhoneNumber(res.contact_number);
    }
  };

  const handleInputsPossibleErrors = (propertyName: string) => {
    if (fieldsErrors && fieldsErrors[propertyName]) {
      const obj = { ...fieldsErrors };
      delete obj[propertyName];
      setFieldsErrors(obj);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      address,
    };
    const dataDetails = {
      postal_code: postalCode,
      country,
      contact_number: phoneNumber,
      city: cityCounty,
      gender,
      business_email_address: emailBusiness,
    };
    NWClient.put('employee', employeeId, data, true, true)
      .then(() => {
        details
          ? NWClient.put('employee', employeeId, dataDetails, true, true)
              .then(() => {
                setPendingStatus(false);
                setErrorTextArray([]);
                setFieldsErrors(null);
                dispatch(fetchUser()); //to refactor
                toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
              })
              .catch((error) => {
                setFieldsErrors(error.response.data);
                setPendingStatus(false);
                toast.error(t('messages.errorOccurred'), { theme: 'colored' });
              })
          : NWClient.post('employee', dataDetails)
              .then(() => {
                setPendingStatus(false);
                setErrorTextArray([]);
                setFieldsErrors(null);
                dispatch(fetchUser()); //to refactor
                toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
              })
              .catch((error) => {
                setFieldsErrors(error.response.data);
                setPendingStatus(false);
                toast.error(t('messages.errorOccurred'), { theme: 'colored' });
              });
      })
      .catch((error) => {
        setFieldsErrors(error.response.data);
        setPendingStatus(false);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser.id)
        try {
          const res: any[] = await NWClient.getByUser('employee', currentUser.id);
          setEmployeeId(res[0].id);
          setUserInfo(res[0]);
          setEmployeeDetails(res[0]);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
    };
    fetchData();
  }, [currentUser.id, dispatch]);

  return (
    <TabPanel value={currentTab} index={0} prefix={'vertical'}>
      {loading ? (
        <Grid container justifyContent='center'>
          <ProgressOverlay className='position-relative' />
          {/*<CircularProgress/>*/}
        </Grid>
      ) : (
        <>
          <Grid>
            <Grid container item className='setting-panel-header' alignItems='center'>
              <PersonalDetailsIcon />
              <h3>{t('settings.titles.personal')}</h3>
            </Grid>
            <Divider />
            <div className='setting-panel-body'>
              <ValidatorForm onSubmit={handleSubmit}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.firstName')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                      name='firstName'
                      value={firstName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.middleName')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setMiddleName(e.target.value)}
                      name='middleName'
                      value={middleName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.lastName')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                      name='lastName'
                      value={lastName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.gender')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setGender(e.target.value)}
                      name='gender'
                      value={gender}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.email')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                      name='email'
                      value={email}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.businessEmailAddress')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setEmailBusiness(e.target.value)
                      }
                      name='emailBusiness'
                      value={emailBusiness}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.postalAddress')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setAddress(e.target.value);
                        handleInputsPossibleErrors('address');
                      }}
                      name='address'
                      value={address}
                    />
                    {Boolean(fieldsErrors?.address) && (
                      <FormHelperText error={true}>
                        {fieldsErrors?.address.join('.')}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.postalCode')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value)}
                      name='email'
                      value={postalCode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.cityCounty')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setCityCounty(e.target.value)}
                      name='cityCounty'
                      value={cityCounty}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      select
                      label={t('labels.country')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setCountry(e.target.value)}
                      name='country'
                      value={country}
                    >
                      {countries.map((option) => (
                        <MenuItem key={option.isoCode} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextValidator>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.phoneNumber')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPhoneNumber(e.target.value)
                      }
                      name='phoneNumber'
                      value={phoneNumber}
                    />
                    {Boolean(fieldsErrors?.contact_number) && (
                      <FormHelperText error={true}>
                        {fieldsErrors?.contact_number.join('.')}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid container className='buttons-row' justifyContent='flex-end'>
                  <Button
                    type='submit'
                    role='button'
                    disabled={!canSubmit}
                    variant='outlined'
                    size='large'
                  >
                    {t('buttons.update')}
                  </Button>
                </Grid>
                {pendingStatus ? <ProgressOverlay /> : ''}
              </ValidatorForm>
            </div>
          </Grid>
          <DialogItem
            isErrorMessage={false}
            open={showMessage}
            title={resetPasswordMessageTitle}
            text={resetPasswordMessage}
            handleClose={() => setShowMessage(false)}
          />
        </>
      )}
    </TabPanel>
  );
};
