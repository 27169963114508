import React, { ChangeEvent, useState, useEffect } from 'react';
import { CustomerNote } from '../../../models/customer.note.model';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { NWClient } from '../../../client/NWClient';
import { toast } from 'react-toastify';
import { CircularProgress, Divider, Grid } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { ProgressOverlay } from '../../../common/progress-overlay/ProgressOverlay';
import { fetchCompanies } from '../../../slices/companies/companiesSlice';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogItem from '../../../common/dialog-item/DialogItem';

const CustomerNoteItem = (props: {
  noteInfo?: CustomerNote;
  editMode?: boolean;
  onSave?: () => void;
}) => {
  const { id, customer_id, notes, updated } = props.noteInfo;
  const editMode = props.editMode;
  const dispatch = useAppDispatch();
  const [customerNotes, setCustomerNotes] = useState<string | null>('');
  const [noteUpdatedDate, setNoteUpdatedDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [deleteNoteMessageVisible, setDeleteNoteMessageVisible] = useState<boolean>(false);
  const [formDataChanged, setFormDataChanged] = useState<boolean>(false);
  const { t } = useTranslation();

  const setNoteInfo = () => {
    setCustomerNotes(notes ? notes : '');
    setNoteUpdatedDate(updated ? new Date(updated).toLocaleString() : '');
  };

  const handleInputsPossibleErrors = (propertyName: string) => {
    if (fieldsErrors && fieldsErrors[propertyName]) {
      const obj = { ...fieldsErrors };
      delete obj[propertyName];
      setFieldsErrors(obj);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      notes: customerNotes,
      customer_id: customer_id,
    };
    id
      ? NWClient.put('customer-notes', id, data, true, true)
          .then(() => {
            toast.success(t('messages.notesCreated'), { theme: 'colored' });
            dispatch(fetchCompanies()).then(() => {
              setFormDataChanged(false);
              setPendingStatus(false);
            });
          })
          .catch((error) => {
            setFieldsErrors(error.response.data);
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          })
      : NWClient.post('customer-notes', data, true)
          .then(() => {
            toast.success(t('messages.notesCreated'), { theme: 'colored' });
            dispatch(fetchCompanies()).then(() => {
              if (props.onSave) {
                props.onSave();
              }
              setPendingStatus(false);
              setFormDataChanged(false);
            });
          })
          .catch((error) => {
            setFieldsErrors(error.response.data);
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
  };

  const deleteNote = () => {
    setPendingStatus(true);
    NWClient.delete('customer-notes', id)
      .then(() => {
        toast.success(t('messages.notesDeleted'), { theme: 'colored' });
        dispatch(fetchCompanies())
          .unwrap()
          .then(() => {
            setPendingStatus(false);
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      })
      .catch(() => {
        setPendingStatus(false);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  useEffect(() => {
    setNoteInfo();
  }, [editMode, props.noteInfo]);

  return (
    <div className='customer-note-item'>
      {loading ? (
        <Grid container justifyContent='center'>
          <ProgressOverlay className='position-relative' />
          {/*<CircularProgress/>*/}
        </Grid>
      ) : (
        <>
          <Grid>
            <div className='setting-panel-body'>
              <ValidatorForm onSubmit={handleSubmit}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid item xs={12} md={12}>
                    {id && <div>{noteUpdatedDate}</div>}
                    <textarea
                      id='customerNotes'
                      title={t('labels.customerNotes')}
                      name='customerNotes'
                      disabled={id ? !editMode : false}
                      value={customerNotes}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        setCustomerNotes(e.target.value);
                        setFormDataChanged(true);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container className='buttons-row' justifyContent='flex-end'>
                  {id && editMode && (
                    <IconButton
                      aria-label='delete'
                      style={{ marginLeft: '0.5rem' }}
                      onClick={() => setDeleteNoteMessageVisible(true)}
                    >
                      <Tooltip title={t('messages.deleteNotes')} placement='top' arrow>
                        <DeleteOutlineOutlinedIcon fontSize='large' style={{ marginRight: 0 }} />
                      </Tooltip>
                    </IconButton>
                  )}
                  {editMode && (
                    <Button
                      style={{ marginLeft: '0.5rem' }}
                      type='submit'
                      role='button'
                      variant='contained'
                      size='large'
                      disabled={id && !formDataChanged}
                    >
                      {id ? t('buttons.updateNotes') : t('buttons.saveNotes')}
                    </Button>
                  )}
                </Grid>
                {pendingStatus ? <ProgressOverlay /> : ''}
              </ValidatorForm>
            </div>
          </Grid>
          <Divider color='#000' />
        </>
      )}
      <DialogItem
        isErrorMessage={false}
        open={deleteNoteMessageVisible}
        title={
          deleteNoteMessageVisible ? t('dialogTitles.deleteNotes') : t('dialogTitles.deleteDefault')
        }
        text={
          deleteNoteMessageVisible ? t('messages.deleteNotesDialog') : t('messages.deleteDefault')
        }
        noDefaultActionsRow={true}
        handleClose={() => {
          setDeleteNoteMessageVisible(false);
        }}
      >
        <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
          <Button
            onClick={() => deleteNote()}
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            type='button'
            role='button'
            variant='outlined'
            size='medium'
          >
            {t('buttons.delete')}
          </Button>
          <Button
            type='button'
            role='button'
            variant='contained'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => {
              setDeleteNoteMessageVisible(false);
            }}
          >
            {t('buttons.goBack')}
          </Button>
        </div>
      </DialogItem>
    </div>
  );
};

export default CustomerNoteItem;
