import React, { ChangeEvent, useState, useEffect, useId } from 'react';
import { CustomerContact } from '../../../models/customer.contact.model';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { NWClient } from '../../../client/NWClient';
import { toast } from 'react-toastify';
import { CircularProgress, Divider, FormHelperText, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { ProgressOverlay } from '../../../common/progress-overlay/ProgressOverlay';
import { fetchCompanies } from '../../../slices/companies/companiesSlice';
import Checkbox from '@mui/material/Checkbox';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogItem from '../../../common/dialog-item/DialogItem';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const CustomerContactItem = (props: {
  contactInfo?: CustomerContact;
  editMode?: boolean;
  onSave?: () => void;
}) => {
  const { id, customer_id, detail_type, detail } = props.contactInfo;
  const editMode = props.editMode;
  const checkboxId = useId();
  const dispatch = useAppDispatch();
  const [contactDetailType, setContactDetailType] = useState<string>('');
  const [contactDetail, setContactDetail] = useState<string | null>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [deleteContactMessageVisible, setDeleteContactMessageVisible] = useState<boolean>(false);
  const [formDataChanged, setFormDataChanged] = useState<boolean>(false);
  const { t } = useTranslation();
  const setContactInfo = () => {
    setContactDetailType(detail_type ? detail_type : '');
    setContactDetail(detail ? detail : '');
  };

  const handleInputsPossibleErrors = (propertyName: string) => {
    if (fieldsErrors && fieldsErrors[propertyName]) {
      const obj = { ...fieldsErrors };
      delete obj[propertyName];
      setFieldsErrors(obj);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      detail_type: contactDetailType,
      detail: contactDetail,
      customer_id: customer_id,
    };
    id
      ? NWClient.put('customer-contacts', id, data, true, true)
          .then(() => {
            toast.success(t('messages.contactDetailUpdated'), { theme: 'colored' });
            dispatch(fetchCompanies()).then(() => {
              setFormDataChanged(false);
              setPendingStatus(false);
            });
          })
          .catch((error) => {
            setFieldsErrors(error.response.data);
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          })
      : NWClient.post('customer-contacts', data, true)
          .then(() => {
            toast.success(t('messages.contactDetailCreated'), { theme: 'colored' });
            dispatch(fetchCompanies()).then(() => {
              if (props.onSave) {
                props.onSave();
              }
              setPendingStatus(false);
              setFormDataChanged(false);
            });
          })
          .catch((error) => {
            setFieldsErrors(error.response.data);
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
  };

  const deleteContact = () => {
    setPendingStatus(true);
    NWClient.delete('customer-contacts', id)
      .then(() => {
        toast.success(t('messages.contactDetailDeleted'), { theme: 'colored' });
        dispatch(fetchCompanies())
          .unwrap()
          .then(() => {
            setPendingStatus(false);
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      })
      .catch(() => {
        setPendingStatus(false);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  useEffect(() => {
    setContactInfo();
  }, [editMode]);

  return (
    <div className='customer-contact-item'>
      {loading ? (
        <Grid container justifyContent='center'>
          <ProgressOverlay className='position-relative' />
          {/*<CircularProgress/>*/}
        </Grid>
      ) : (
        <>
          <Grid>
            <div className='setting-panel-body'>
              <ValidatorForm onSubmit={handleSubmit}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      select
                      label={t('labels.detailType')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setContactDetailType(e.target.value);
                        setFormDataChanged(true);
                      }}
                      name='contactDetailType'
                      value={contactDetailType}
                      disabled={id ? !editMode : false}
                    >
                      <MenuItem value='phone_number'>{t('labels.phoneNumber')}</MenuItem>
                      <MenuItem value='email_address'>{t('labels.emailAddress')}</MenuItem>
                      <MenuItem value='social_media'>{t('labels.socialMedia')}</MenuItem>
                    </TextValidator>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      id='contactDetail'
                      name='contactDetail'
                      label={t('labels.detail')}
                      disabled={id ? !editMode : false}
                      value={contactDetail}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        setContactDetail(e.target.value);
                        setFormDataChanged(true);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container className='buttons-row' justifyContent='flex-end'>
                  {id && editMode && (
                    <IconButton
                      aria-label='delete'
                      style={{ marginLeft: '0.5rem' }}
                      onClick={() => setDeleteContactMessageVisible(true)}
                    >
                      <Tooltip title={t('messages.deleteContactDetail')} placement='top' arrow>
                        <DeleteOutlineOutlinedIcon fontSize='large' style={{ marginRight: 0 }} />
                      </Tooltip>
                    </IconButton>
                  )}
                  {editMode && (
                    <Button
                      style={{ marginLeft: '0.5rem' }}
                      type='submit'
                      role='button'
                      variant='contained'
                      size='large'
                      disabled={
                        (id && !formDataChanged) || (!id && (!contactDetail || !contactDetailType))
                      }
                    >
                      {id ? t('buttons.updateContactDetails') : t('buttons.saveContactDetails')}
                    </Button>
                  )}
                </Grid>
                {pendingStatus ? <ProgressOverlay /> : ''}
              </ValidatorForm>
            </div>
          </Grid>
          <Divider color='#000' />
        </>
      )}
      <DialogItem
        isErrorMessage={false}
        open={deleteContactMessageVisible}
        title={
          deleteContactMessageVisible
            ? t('dialogTitles.deleteContactDetails')
            : t('dialogTitles.deleteDefault')
        }
        text={
          deleteContactMessageVisible
            ? t('messages.deleteContactDetailsDialog')
            : t('messages.deleteDefault')
        }
        noDefaultActionsRow={true}
        handleClose={() => {
          setDeleteContactMessageVisible(false);
        }}
      >
        <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
          <Button
            onClick={() => deleteContact()}
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            type='button'
            role='button'
            variant='outlined'
            size='medium'
          >
            {t('buttons.delete')}
          </Button>
          <Button
            type='button'
            role='button'
            variant='contained'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => {
              setDeleteContactMessageVisible(false);
            }}
          >
            {t('buttons.goBack')}
          </Button>
        </div>
      </DialogItem>
    </div>
  );
};

export default CustomerContactItem;
