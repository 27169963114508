import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Company } from '../../models/company.model';
import CustomerDetailsCard from './components/customer-details-card/CustomerDetailsCard';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import styles from './NewCustomerProfile.module.scss';
import { NwBreadCrumbs } from '../../common/nw-breadcrumbs';

const CustomerProfileCreatePage: React.FC = () => {
  const company = useAppSelector(selectAllCompanies)[0];

  if (!company) {
    return <CircularProgress />;
  }
  return (
    <Grid container spacing={3} sx={{ mt: '-100px' }}>
      <Grid item xs={12}>
        <NwBreadCrumbs />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5' component='h1' className={styles.page_title}>
          Customer Create
        </Typography>
      </Grid>
      <Grid container className={styles.cards_container} gap={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <CustomerDetailsCard company={company} forceIsEdited={true} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerProfileCreatePage;
