import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { Employee, EmployeeSearchData, EmployeeNotes } from '../../models/employee.model';
import { RootState } from '../../store';

export const fetchEmployees = createAsyncThunk(
  'companies/fetchEmployees',
  async ({ company_id }: EmployeeSearchData, { rejectWithValue }) => {
    if (company_id === undefined) {
      return rejectWithValue('Company ID is required');
    }
    const response = await NWClient.list('employee', { company_id });
    return response;
  }
);

export const fetchEmployeeById = createAsyncThunk(
  'companies/fetchEmployeeById',
  async (employeeId: number, { rejectWithValue }) => {
    if (employeeId === undefined) {
      return rejectWithValue('Employee ID is required');
    }
    const response = await NWClient.get('employee', employeeId);
    return response;
  }
);

export const fetchEmployeeNotes = createAsyncThunk(
  'employees/fetchEmployeeNotes',
  async (employeeId: number, { rejectWithValue }) => {
    if (employeeId === undefined) {
      return rejectWithValue('Employee ID is required');
    }
    try {
      const response = (await NWClient.get('employee', employeeId)) as Employee;
      return { employeeId, notes: response.notes as EmployeeNotes[] };
    } catch (error) {
      return rejectWithValue('Failed to fetch employee notes');
    }
  }
);

export const deleteEmployeeNote = createAsyncThunk(
  'employees/deleteEmployeeNote',
  async ({ employeeId, noteId }: { employeeId: number; noteId: number }, { dispatch }) => {
    try {
      await NWClient.delete(`employee/${employeeId}/notes`, noteId);
      // After successful deletion, fetch updated notes to refresh state
      await dispatch(fetchEmployeeNotes(employeeId));
      return { success: true };
    } catch (error) {
      // Handle error appropriately
      return { success: false, error };
    }
  }
);

export interface EmployeesState {
  employeesList: Employee[];
  status: string;
  error: string;
  notes: EmployeeNotes[];
}

const employeesArray: Employee[] = [];
const initialState: EmployeesState = {
  employeesList: employeesArray,
  notes: [],
  status: 'idle',
  error: '',
};
const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.employeesList = [...action.payload];
          state.status = 'succeeded';
        }
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchEmployeeNotes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmployeeNotes.fulfilled, (state, action) => {
        const { employeeId, notes } = action.payload;
        state.notes = notes;
        state.status = 'succeeded';
      })
      .addCase(fetchEmployeeNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch employee notes';
      })
      .addCase(deleteEmployeeNote.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteEmployeeNote.fulfilled, (state, action) => {
        if (action.meta.arg && action.meta.arg.noteId) {
          const noteId = action.meta.arg.noteId;
          state.notes = state.notes.filter((note) => note.id !== noteId);
        }
        state.status = 'succeeded';
      })
      .addCase(deleteEmployeeNote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = 'Failed to delete employee note';
      });
  },
});

export default employeesSlice.reducer;

export const selectAllEmployees = (state: RootState) => state.employees.employeesList;
export const selectEmployeeById = (state: RootState, employeeId: number) =>
  state.employees.employeesList.find((item) => item.id === employeeId);
export const selectEmployeeNotes = (state: RootState) => state.employees.notes;
