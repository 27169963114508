import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { fetchCustomers, selectAllCustomers } from '../../slices/customers/customersSlice';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { Customer } from '../../models/customer.model';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import styles from './NewCustomerProfile.module.scss';
import AddCustomerCard from '../../common/add-customer-card/AddCustomerCard';
import AddIcon from '@mui/icons-material/Add';

import FileSaveIcon from '../../../static/assets/svg/file_save.svg';
import ContentCopyIcon from '../../../static/assets/svg/content_copy.svg';
import VerticalDotsIcon from '../../../static/assets/svg/more_vert.svg';
import ContractEditIcon from '../../../static/assets/svg/contract_edit.svg';
import CalendarIcon from '../../../static/assets/svg/calendar_month.svg';
import BrushIcon from '../../../static/assets/svg/brush.svg';
import { ROUTES } from '../../common/constants/routes';
import { useNavigate } from 'react-router-dom';
import UploadButton from '../../common/upload-button/UploadButton';
import FormUpload from '../../common/form-upload/FormUpload';

const NewCustomerProfiles: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectAllCompanies)[0];
  const customers = useAppSelector(selectAllCustomers);
  const navigate = useNavigate();
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const uploadButtonRef = useRef<HTMLButtonElement>(null);
  const formUploadRef = useRef<HTMLDivElement>(null);

  const customersSorted =
    customers?.length > 0
      ? [...customers].sort((a, b) => a.customer_name.localeCompare(b.customer_name))
      : [];

  const handleOnClick = () => {
    navigate(ROUTES.CUSTOMER_PROFILE_CREATE);
  };
  useEffect(() => {
    if (company?.id) {
      dispatch(fetchCustomers({ company_id: company.id }));
    }
  }, [dispatch, company?.id]);

  const handleUploadOpen = () => {
    setIsUploadOpen((prev) => !prev);
  };
  const handleUploadClose = () => {
    setIsUploadOpen(false);
  };

  return (
    <Grid container spacing={3} sx={{ mt: '-100px' }}>
      <Grid item xs={12}>
        <Stack spacing={2} mb={3}>
          <Typography variant='h5' component='h1' className={styles.page_title}>
            Customer
          </Typography>
          <Stack direction='row' spacing={2}>
            <Box ref={uploadButtonRef}>
              <UploadButton onClick={handleUploadOpen} buttonText='Upload' isArrowDown={false} />
            </Box>
            {isUploadOpen && (
              <Box className={styles.form_box} ref={formUploadRef}>
                <FormUpload categoryValue='customers' onCancel={handleUploadClose} />
              </Box>
            )}
            {/* TODO: Change this text */}
            <Tooltip title={t('businessProfile.addContacts')} placement='top'>
              <IconButton onClick={handleOnClick} className={styles.add_button}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Grid item xs={12}>
            <Typography variant='h6' fontWeight='bold'>
              {company && company.name ? company.name : ''}
            </Typography>
          </Grid>
        </Stack>
      </Grid>
      <Grid container className={styles.cards_container} gap={2}>
        {customersSorted?.map((customer) => (
          <Grid item xs={3} key={customer.id}>
            <Card className={styles.card_min_height}>
              <CardContent className={styles.full_height}>
                <Grid container direction='column' className={styles.card_min_height}>
                  <Grid item>
                    <Box>{customer.contact_name}</Box>
                  </Grid>
                  <Grid item sx={{ flexGrow: 1 }} />
                  <Grid item>
                    <Box display='flex' alignItems='center' width='100%'>
                      <Box>
                        <IconButton>
                          <FileSaveIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton>
                          <ContentCopyIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() =>
                            navigate(
                              ROUTES.CUSTOMER_PROFILE.replace(':customerId', String(customer.id))
                            )
                          }
                        >
                          <BrushIcon />
                        </IconButton>
                      </Box>
                      <Box ml='auto'>
                        <IconButton>
                          <VerticalDotsIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <AddCustomerCard />
      </Grid>
    </Grid>
  );
};

export default NewCustomerProfiles;
