import React, { ChangeEvent } from 'react';
import AIIcon from '../../../static/assets/svg/ai-summary-icon.svg';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Card, CardContent, FormControl, Grid, Modal, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { PacmanLoaderBlock } from '../pacman-loader';
import { useTranslation } from 'react-i18next';
import { AISummaryDialogProps } from './types';
import { useAISummaryDialog } from './useAISummaryDialog';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import styles from './AISummaryDialog.module.scss';

const AISummaryDialog = function ({
  open,
  onClose,
  handleAnalyzeSuccess,
  document,
  documentId,
  documentName,
  isDocumentContract,
  handleAnalyzeError,
}: AISummaryDialogProps) {
  const { t } = useTranslation();
  const {
    documentType,
    setDocumentType,
    purpose,
    setPurpose,
    resultFormat,
    setResultFormat,
    detailLevel,
    setDetailLevel,
    simplifyJargon,
    setSimplifyJargon,
    contextualInformation,
    setContextualInformation,
    purposes,
    handleAnalyze,
    setAnalysisLoading,
    analysisLoading,
  } = useAISummaryDialog();
  const icon = <CheckBoxOutlineBlankIcon fontSize='medium' />;
  const checkedIcon = <CheckBoxIcon fontSize='medium' />;

  return (
    <>
      <Modal open={open} onClose={onClose} closeAfterTransition className='action-modal'>
        <Grid container className={styles.modal_container}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <CardContent sx={{ paddingBottom: '16px !important' }}>
                <Grid
                  item
                  xs={12}
                  display={'flex'}
                  className={styles.title_container}
                  alignItems={'flex-start'}
                  gap={'16px'}
                >
                  <AIIcon />
                  <Typography variant='h6' gutterBottom>
                    {documentName}
                  </Typography>
                </Grid>
                <Grid item display='flex' gap='16px' alignItems='flex-start'>
                  <Typography>{t('messages.reviewSettings')}</Typography>
                </Grid>
                <Grid container spacing={2} marginTop={'10px'}>
                  <Grid item xs={12} md={12}>
                    <FormControl className='w-100'>
                      <InputLabel htmlFor='documentTypeSelect'>
                        {t('labels.documentType')}
                      </InputLabel>
                      <Select
                        className='w-100'
                        id='documentTypeSelect'
                        onChange={(e: SelectChangeEvent<typeof documentType>) =>
                          setDocumentType(e.target.value)
                        }
                        name='documentType'
                        value={documentType}
                        tabIndex={-1}
                        //defaultOpen={true}
                      >
                        <MenuItem value='contract'>{t('documentTypes.contract')}</MenuItem>
                        <MenuItem value='insurance'>{t('documentTypes.insurance')}</MenuItem>
                        <MenuItem value='invoice'>{t('documentTypes.invoice')}</MenuItem>
                        <MenuItem value='certificate'>{t('documentTypes.certificate')}</MenuItem>
                        <MenuItem value='other'>{t('documentTypes.other')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl className={styles.w100}>
                      <Autocomplete
                        className={styles.w100}
                        multiple
                        id='tags-standard'
                        openOnFocus={true}
                        options={purposes}
                        disableCloseOnSelect={true}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        value={purpose}
                        onChange={(
                          event: ChangeEvent<HTMLInputElement>,
                          newValue:
                            | {
                                title: string;
                                value: string;
                              }[]
                            | null
                        ) => {
                          setPurpose(newValue);
                        }}
                        renderOption={(props, option, { selected }) => {
                          const { ...optionProps } = props;
                          return (
                            <li key={option.value} {...optionProps}>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                              {option.title}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant='standard' label={t('labels.purpose')} />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl className='w-100'>
                      <InputLabel htmlFor='resultFormatSelect'>
                        {t('labels.resultFormat')}
                      </InputLabel>
                      <Select
                        className='w-100'
                        id='resultFormatSelect'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setResultFormat(e.target.value);
                        }}
                        name='resultFormat'
                        value={resultFormat}
                        tabIndex={-1}
                      >
                        <MenuItem value={''}>{''}</MenuItem>
                        <MenuItem value='table'>{t('resultFormat.table')}</MenuItem>
                        <MenuItem value='bullet point'>{t('resultFormat.bulletPoint')}</MenuItem>
                        <MenuItem value='narrative format'>
                          {t('resultFormat.narrativeFormat')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl className='w-100'>
                      <InputLabel htmlFor='detailLevelSelect'>{t('labels.detailLevel')}</InputLabel>
                      <Select
                        className='w-100'
                        id='detailLevelSelect'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setDetailLevel(e.target.value);
                        }}
                        name='detailLevel'
                        value={detailLevel}
                        tabIndex={-1}
                      >
                        <MenuItem value='high level'>{t('detailLevel.highLevel')}</MenuItem>
                        <MenuItem value='detailed'>{t('detailLevel.detailed')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    display={'flex'}
                    alignItems={'flex-start'}
                    className='checkbox-row'
                  >
                    <Checkbox
                      style={{ marginLeft: -10, paddingTop: 0, paddingBottom: 0 }}
                      id='simplifyTechnicalOrLegalJargon'
                      checked={simplifyJargon}
                      onChange={(event) => setSimplifyJargon(event.target.checked)}
                    />
                    <label style={{ marginTop: 0 }} htmlFor='simplifyTechnicalOrLegalJargon'>
                      {t('labels.simplifyJargon')}
                    </label>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl className={styles.textarea_container}>
                      <textarea
                        id='contextualInformation'
                        name='contextualInformation'
                        value={contextualInformation}
                        placeholder={t('messages.contextualInformationPlaceholder')}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                          setContextualInformation(e.target.value);
                        }}
                      />
                      <InputLabel className={'label-transitioned'} htmlFor='contextualInformation'>
                        {t('labels.contextualInformation')}
                      </InputLabel>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container justifyContent='flex-end' gap={'8px'} mt={2}>
                  <Button
                    variant='contained'
                    onClick={onClose}
                    className='btn-default btn-action-basic btn-cancel'
                  >
                    {t('buttons.goBack')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() =>
                      handleAnalyze({
                        handleAnalyzeSuccess,
                        handleAnalyzeError,
                        isDocumentContract,
                        documentId,
                      })
                    }
                    className='btn-default btn-action-basic'
                  >
                    {t('buttons.analyze')}
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Modal>
      {analysisLoading && <PacmanLoaderBlock loading={analysisLoading} />}
    </>
  );
};

export default AISummaryDialog;
