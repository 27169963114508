import React, { useState } from 'react';
import { TextField, Autocomplete, Popper } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PositionInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  submitting?: boolean;
  autocompleteRef: React.RefObject<HTMLDivElement>;
  popperRef: React.RefObject<HTMLDivElement>;
}

const predefinedPositions = [
  'Director',
  'Secretary',
  'Accountant',
  'Lawyer',
  'Manager',
  'Shareholder',
];

export default function PositionInput({
  value,
  onChange,
  disabled,
  submitting,
  autocompleteRef,
  popperRef,
}: PositionInputProps) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(value);
  const [isTouched, setIsTouched] = useState(false);

  const handleBlur = () => {
    setIsTouched(true);
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={predefinedPositions}
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        onChange(newInputValue); // Update parent with custom input
      }}
      onChange={(_, newValue) => {
        // If newValue is null or undefined, fallback to inputValue
        onChange(newValue || inputValue);
      }}
      PopperComponent={(props) => <Popper {...props} ref={popperRef} />}
      renderInput={(params) => (
        <TextField
          error={(submitting && value === '') || (isTouched && value === '')}
          onBlur={handleBlur}
          {...params}
          label={`${t('formInputs.positionInputLabel')} *`}
          variant='outlined'
          fullWidth
          disabled={disabled}
          ref={autocompleteRef}
        />
      )}
    />
  );
}
